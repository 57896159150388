import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { addItemsToCart } from "../../actions/cartAction";
import { useSelector, useDispatch } from "react-redux";
import { useAlert } from "react-alert";


const ProductCard = ({
  product,
  onAddToCart,
  showCart,
  isAuthenticated,
  setOpenPopup,
  setSendId,
}) => {
  const dispatch = useDispatch();
  const alert = useAlert();

  const { cartItems } = useSelector((state) => state.cart);

  const addToCartHandler = () => {
    if (!isAuthenticated) {
      setOpenPopup(true);
      setSendId(product._id);
    } else {
      dispatch(addItemsToCart(product._id, 1));
      alert.success("Item Added To Cart");
      onAddToCart();
    }
  };
  return (
    <Fragment>
      <div className={`productCard ${showCart ? "with-cart-productCard" : ""}`}>
        <Link className="productCardContainer" to={`/product/${product._id}`}>
          <div className="productimg-container">
          {product.image && product.image.length > 0 && Array.isArray(product.image) && (
            <img src={product.image[0]} alt={product.name} />
          )}

          </div>
          <div className="myproduct-details">
            <p className="myproduct-name">{product.name}</p>
            <p className="myproduct-brand">
              <span>BRAND: </span> {product.Brand}
            </p>
            <p className="myproduct-features">
              {product.Device && <span>{product.Device}</span>}
              {product.Size && (
                <span>
                  {product.Size.length > 15
                    ? window.innerWidth <= 768
                      ? product.Size.slice(0, 20) + "..."
                      : product.Size.slice(0, 35) + "..."
                    : product.Size}
                </span>
              )}
            </p>
          </div>
          <div id="myproduct-line"></div>
        </Link>
        <div className="rp-bg">
          <button
            className={
              cartItems.some((item) => item.product === product._id)
                ? "added-to-cart"
                : "request-price"
            }
            onClick={() => addToCartHandler()}
            disabled={cartItems.some((item) => item.product === product._id)}
          >
            {cartItems.some((item) => item.product === product._id)
              ? "Added to cart"
              : "Request Price"}
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export default ProductCard;
