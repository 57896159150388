import React from "react";
import { useHistory } from "react-router-dom";
import "./ProductDisplayBar.css"; // Make sure to create this CSS file
import { ReactComponent as Opthal } from "./../../icons/opthalmology.svg";
import { ReactComponent as Gynaec } from "./../../icons/gynaecology.svg";
import { ReactComponent as Uro } from "./../../icons/urology.svg";
import { ReactComponent as Laparo } from "./../../icons/laparoscopy.svg";
import { ReactComponent as Procto } from "./../../icons/proctology.svg";

const ProductDisplayBar = () => {
  const history = useHistory();

  function handleCategoryClick(event, path) {
    event.stopPropagation();
    history.push(path);
  }
  const products = [
    {
      id: 1,
      image:
        "https://zoplardatabase.s3.ap-southeast-2.amazonaws.com/pageimages/C1.jpg",
      name: "Product 1",
      icon: <Opthal className="med-icons" />,
      field: "OPTHALMOLOGY",
      path: "Opthalmology",
    },
    {
      id: 2,
      image:
        "https://zoplardatabase.s3.ap-southeast-2.amazonaws.com/pageimages/C2.jpg",
      name: "Product 2",
      icon: <Gynaec className="med-icons"/>,
      field: "GYNAECOLOGY",
      path: "Gynaecology",
    },
    {
      id: 3,
      image:
        "https://zoplardatabase.s3.ap-southeast-2.amazonaws.com/pageimages/C3.jpeg",
      name: "Product 3",
      icon: <Uro className="med-icons"/>,
      field: "UROLOGY",
      path: "Urology",
    },
    
    {
      id: 4,
      image:
        "https://zoplardatabase.s3.ap-southeast-2.amazonaws.com/pageimages/C5.jpg",
      name: "Product 5",
      icon: <Procto className="med-icons"/>,
      field: "PROCTOLOGY",
      path: "Proctology",
    },
    {
      id: 5,
      image:
        "https://zoplardatabase.s3.ap-southeast-2.amazonaws.com/pageimages/C4.jpg",
      name: "Product 4",
      icon: <Laparo className="med-icons"/>,
      field: "LAPAROSCOPY",
      path: "Laparoscopy",
    },

    // Add more products here as needed
  ];

  return (
    <div className="product-display-bar-box">
      <div className="category-container">
        <div className="department-categories">Top Medical Fields</div>
        <a className="view-all-button-categories" href="/categories">
          VIEW ALL
        </a>
      </div>
      <div className="product-display-bar">
        {products.map((product) => (
          <div key={product.id} className="product-card">
            <div>
              {product.icon}
              <p className="category-description">{product.field}</p>
            </div>
            <button
              className="show-product-button"
              onClick={(e) =>
                handleCategoryClick(e, `/products?categoryFromPage=${product.path}`)
              }
            >
              Show Products
            </button>
          </div>
        ))}
      </div>
      <div className="mobile-product-display-bar">
        {products.slice(0,3).map((product) => (
          <div key={product.id} className="product-card">
            <div>
              {product.icon}
              <p className="category-description">{product.field}</p>
            </div>
            <button
              
              onClick={(e) =>
                handleCategoryClick(e, `/products?categoryFromPage=${product.path}`)
              }
            >
              Show Products
            </button>
          </div>
        ))}
      </div>

    </div>
  );
};

export default ProductDisplayBar;
