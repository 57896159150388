import React, { useState,Fragment ,useEffect} from "react";
import "./Cart.css";
import CartItemCard from "./CartItemCard";
import { useSelector, useDispatch } from "react-redux";
import { addItemsToCart, removeItemsFromCart ,updateCartItemBundleType} from "../../actions/cartAction";
import { useAlert } from "react-alert";
import { createOrder,clearErrors } from "../../actions/orderAction";
import { Link } from "react-router-dom";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import { ReactComponent as DustbinIcon } from "../../icons/dustbinIcon.svg";
import { ReactComponent as PlusIcon } from "../../icons/plusIcon.svg";
import { ReactComponent as MinusIcon } from "../../icons/minusIcon.svg";
import LoginPopup from "../User/LoginPopup"; 
import MetaData from "../layout/MetaData";



const Cart = ({ history }) => {
  const dispatch = useDispatch();
  const { cartItems } = useSelector((state) => state.cart);
  const { success,error :newOrderError } = useSelector((state) => state.newOrder);

  const [openDropDown, setOpenDropDown] = useState({});
  const [selectedOption, setSelectedOption] = useState({});

  const alert = useAlert();

  const handleDropdownChange = (item,option) => {
    setSelectedOption((prevState) => ({
      ...prevState,
      [item.product]: option,
    }));
    dispatch(updateCartItemBundleType(item.product, option));
    setOpenDropDown((prevState) => ({
      ...prevState,
      [item.product]: false,
    }));
  };



  const {  isAuthenticated, redirectToRegistration } =
  useSelector((state) => state.user);

  const [openPopup, setOpenPopup] = useState(false);
  const onClose = () => {
    setOpenPopup(false);
  };

  const [checkoutClicked, setCheckoutClicked] = useState(false);



  const increaseQuantity = (id, quantity) => {
    const newQty = quantity + 1;
   dispatch(addItemsToCart(id, newQty));
  };

  const decreaseQuantity = (id, quantity) => {
    const newQty = quantity - 1;
    if (1 >= quantity) {
      return;
    }
   dispatch(addItemsToCart(id, newQty));
  };

  const deleteCartItems = (id) => {
    dispatch(removeItemsFromCart(id));
  };

  const order = {
    orderItems: cartItems
  };

  useEffect(() => {
    if (newOrderError) {
      alert.error(newOrderError);
      dispatch(clearErrors());
    }

    if (checkoutClicked && isAuthenticated ) {
      dispatch(createOrder(order))
      history.push("/success");
   
    }

 }, [dispatch, newOrderError, alert, isAuthenticated, redirectToRegistration,success]);

  const checkoutHandler = () => {
    setCheckoutClicked(true);
     if (!isAuthenticated ) {
      setOpenPopup(true);
     } 
   
      else
      {
      
        dispatch(createOrder(order));
        history.push("/success");
      }
    };
    


  return (
    <Fragment>
      <MetaData title="Get Quote - Zoplar" />
      {cartItems.length === 0 ? (
        <div className="emptyCart">
          <img src={"https://zoplardatabase.s3.ap-southeast-2.amazonaws.com/pageimages/emptyCart.jpg"} alt="empty cart"/>
          <p>No Product in the Cart</p>
          <Link to="/products">View Products</Link>
        </div>
      ) : (
        <Fragment>
          <div className="cartPage-main">
            <div className="cartHeader-main">
              <p>Products Added To Cart</p>
            </div>
            <div className="cartBox-main">
              <div className="cart-sidebar"></div>
            {cartItems &&
              cartItems.map((item) => (
                <div className="cartContainer-main" key={item.product}>
                  <CartItemCard item={item} deleteCartItems={deleteCartItems} />
                  <div className="cartInput-main">
                    <div className="cartInputBox-main">
                      <PlusIcon className="plusicon"
                        // style={{
                        //   width: "10px",
                        //   height: "10px",
                        //   cursor: "pointer",
                        //   padding: "0% 4%",
                        // }}
                        onClick={() =>
                          increaseQuantity(item.product, item.quantity)
                        }
                      />
                      <input  className= "mobile-cart-quantity" type="number" value={item.quantity} readOnly />
                      <MinusIcon className="minusicon"
                        // style={{
                        //   width: "16px",
                        //   height: "16px",
                        //   cursor: "pointer",
                        //   padding: "0% 4%",
                        // }}
                        onClick={() =>
                          decreaseQuantity(item.product, item.quantity)
                        }
                      />
                      <div className="vertical-line-main"></div>
                      <div className="dropdown-main">
                        <div>
                        <p
                          onClick={() => {
                            setOpenDropDown((prevState) => ({
                              ...prevState,
                              [item.product]: !prevState[item.product],
                            }));
                          }}
                        >
                          {selectedOption[item.product] || item.bundleType}
                        </p>
                        {openDropDown[item.product] && (
                          <ul>
                            <li onClick={() => handleDropdownChange(item, "Piece")}>
                              Piece
                            </li>
                            <li onClick={() => handleDropdownChange(item, "Box")}>
                              Box
                            </li>
                            <li onClick={() => handleDropdownChange(item, "Unit")}>
                              Unit
                            </li>
                            <li onClick={() => handleDropdownChange(item, "Packet")}>
                              Packet
                            </li>
                          </ul>
                        )}
                        </div>
                        <ArrowDropDownOutlinedIcon
                          style={{
                            color: "#BABABA",
                            fontSize: "20px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setOpenDropDown((prevState) => ({
                              ...prevState,
                              [item.product]: !prevState[item.product],
                            }));
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <span>
                    <DustbinIcon
                      style={{
                        marginTop: "30%",
                        marginRight: "10%",
                        marginLeft: "20%",
                        width: "20px",
                        height: "20px",
                      }}
                      onClick={() => {
                        deleteCartItems(item.product);
                      }}
                    />
                  </span>
                </div>
              ))}
            </div>
            <div className="cartGrossProfit">
              <div></div>

              <div className="checkOutBtn-main">
                <button onClick={checkoutHandler}>Get Quote</button>
              </div>
            </div>
          </div>
        {openPopup && <LoginPopup
            openPopup={openPopup} // Custom prop
            setOpenPopup={setOpenPopup} // Custom prop
            onClose={onClose} // Pass the onClose function
          />}
            
        </Fragment>
      )}
    </Fragment>
  );
};

//Dont get confuse with the variable name GrossProfit

export default Cart;
