import React , { useState, useEffect,Fragment} from 'react'
import "./Referral.css";
import { useHistory  } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom/cjs/react-router-dom';
import refer from '../../images/referpage.png'
import referbg from '../../images/referbg.png'
import rbanner from '../../images/referralbannermobile.png'
import { createReferral, clearErrors } from "../../actions/formAction";
import { NEW_REFERRAL_RESET } from "../../constants/formsConstants";
import MetaData from "../layout/MetaData";
import Loader from "../layout/Loader/Loader";

const Referral = () =>{
    const [ReferralFormData, setReferralFormData] = useState({
        nameReferral: "",
        fnameReferral:"",
        mobileNumberReferral: "",
        fmobileNumberReferral: "", 
        finfo: "",     
      });

      const [isReferralFormValid, setIsReferralFormValid] = useState(false);
      const [phoneError , setPhoneError] = useState(false);
      const dispatch = useDispatch();
      const history = useHistory();
    
    
    const { loading, error, success } = useSelector((state) => state.referral);
    
      // Function to update form data
      const handleReferralInputChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        const pattern = /^[6-9][0-9]{0,20}$/;
    
        if ((name === "mobileNumberReferral" || name==="fmobileNumberReferral") && value && !pattern.test(value)) {
          // If the input value does not match the pattern, do not update the state
          e.target.value = "";
          setPhoneError(true);
        } else {
          // If the input value matches the pattern, update the state
          e.target.value = value;
          setPhoneError(false);
        }
        if ((name === "mobileNumberReferral" || name==="fmobileNumberReferral") && value.length >= 10) {
          const truncatedValue = value.length <= 10 ? value : value.slice(0, 10);
          e.target.value = truncatedValue;
          setReferralFormData((prevData) => ({
            ...prevData,
            [name]: truncatedValue,
          }));
        } else {
          setReferralFormData((prevData) => ({
            ...prevData,
            [name]: value,
          }));
        }
        
      };

      const validateReferralForm = () => {
        const { nameReferral, mobileNumberReferral, fnameReferral, fmobileNumberReferral } = ReferralFormData;
        const requiredFieldsreferral = [nameReferral, mobileNumberReferral, fnameReferral, fmobileNumberReferral];
       
      
        return requiredFieldsreferral.every((field) => field.trim() !== "") && (mobileNumberReferral.length === 10 && fmobileNumberReferral.length === 10);
      };
      

      useEffect(() => {
        if (error) {
          dispatch(clearErrors());
        }
    
        if (success) {
          dispatch({ type: NEW_REFERRAL_RESET });
        }
        setIsReferralFormValid(validateReferralForm());
      }, [ReferralFormData]);

      const handleReferralSubmit = (e) => {
        e.preventDefault();
        if (validateReferralForm) {
          const myForm = new FormData();
    
          myForm.set("nameReferer", ReferralFormData.nameReferral);
          myForm.set("phoneReferer", ReferralFormData.mobileNumberReferral);
          myForm.set("nameReferee", ReferralFormData.fnameReferral);
          myForm.set("phoneReferee", ReferralFormData.fmobileNumberReferral);
          myForm.set("info",ReferralFormData.finfo);
          
    
          dispatch(createReferral(myForm)).then(() => {
            history.push("/referralsubmit");
          });
        } else {
          alert("Form not valid");
          
        }
      };

    return(
      <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <MetaData title="Earn 1L per month - Zoplar" />
        <div className="referral-container">
            <div className='referral-left'>
                <h1>Refer & Earn up to INR 1,00,000<br></br>per month.</h1>
                <h2>5% off for your friends</h2>    
                <p className='refer-form-head'>Exclusively for Zoplar Clients/Partners only</p>
                <form onSubmit={handleReferralSubmit}>
                  <div className="basic-form-Referral">
                    <div>
                      <input
                        type="text"
                        className="form-name-Referral"
                        name="nameReferral"
                        onChange={handleReferralInputChange}
                        required
                      ></input>
                      <span className="placeholder-name">
                        Your Name
                      </span>
                    </div>

                    <div>
                      <input
                        className="mobile-number-Referral"
                        type="number"
                        name="mobileNumberReferral"
                        // placeholder="Enter your mobile Number"
                        onChange={handleReferralInputChange}
                        required
                      ></input>
                      <span className="placeholder-mobile">
                        Your Phone Number
                      </span>
                    </div>
                    {phoneError && (
                        <p className="phone-error-refer">
                          *Number should start from 6,7,8 or 9
                        </p>
                      )}

                    <div>
                      <input
                        type="text"
                        className="form-friendname-Referral"
                        name="fnameReferral"
                        onChange={handleReferralInputChange}
                        required
                      ></input>
                      <span className="placeholder-name">
                        Your Friend's Name
                      </span>
                    </div>
                    <div>
                      <input
                        className="mobile-friendnumber-Referral"
                        type="number"
                        name="fmobileNumberReferral"
                        onChange={handleReferralInputChange}
                        required
                      ></input>
                      <span className="placeholder-mobile">
                        Your Friend's Phone Number
                      </span>
                    </div>
                    {phoneError && (
                        <p className="phone-error-refer">
                          *Number should start from 6,7,8 or 9
                        </p>
                      )}
                    <div>
                      <input
                        type="text"
                        className="form-info-Referral"
                        name="finfo"
                        onChange={handleReferralInputChange}
                      ></input>
                      <span className="placeholder-name-withoutstar">
                        Remarks
                      </span>
                    </div>  
                       
                        <button
                            type="submit"
                            disabled={!isReferralFormValid}
                            className={
                            isReferralFormValid
                                ? "active-Referral"
                                : "disabled-Referral"
                            }
                        >
                            Refer
                      </button>
                        
                      
                    
                  </div>
                </form>
                <p className='referral-tnc'>*Applicable for minimum order value of INR 1 Lakh. T&C Apply</p>

            </div>
            <div className='referral-right'>
                <div style={{ position: 'relative' }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="729" height="446" viewBox="0 0 729 446" fill="none">
                    <path d="M13.0846 346.948C62.7995 483.348 511.076 449.777 729 415.941V0C670.393 42.691 509.729 119.905 335.929 87.2321C118.678 46.3913 -49.0591 176.447 13.0846 346.948Z" fill="#9FB4E7" fillOpacity="0.1"/>
                </svg>
                        <img className='referimg' src={refer} style={{ position: 'absolute', top: -8, left: 0, zIndex: 1 }} />
                        <img src={referbg} style={{ position: 'absolute', top: 80, left: 50, zIndex: 1 }}/>
                </div>


                <ul>
                    <li>Earn INR 5,000 per referral</li>
                    <li>Your friend gets 5% Off on 1st order*</li>
                    <li>Earn up to INR 1,00,000 per month</li>
                    <li>Instant Money transfer as your <br></br>friend pays in full</li>
                </ul>              
            </div>

            <div className='referral-container-mobile'>
              <img className='referral-banner-mobile' src={rbanner} />   

              <div className='referral-cards-container'>
                <div className='referral-card'>
                  <p className='rcard-text'>Earn INR 5,000 per referral</p>
                  <div className='rbottom-bar'></div>

                </div>
                <div className='referral-card'>
                  <p className='rcard-text'>Your friend gets 5% Off on 1st order*</p>
                  <div className='rbottom-bar'></div>

                </div>
                <div className='referral-card'>
                  <p className='rcard-text'>Earn up to INR 1,00,000 per  month</p>
                  <div className='rbottom-bar'></div>

                </div>
                <div className='referral-card'>
                  <p className='rcard-text'>Instant Money transfer, As your friend pays in full</p>
                  <div className='rbottom-bar'></div>
                </div>
              </div>

              <form onSubmit={handleReferralSubmit}>
                  <div className="basic-form-Referral">
                    <div>
                      <input
                        type="text"
                        className="form-name-Referral"
                        name="nameReferral"
                        onChange={handleReferralInputChange}
                        required
                      ></input>
                      <span className="placeholder-name">
                      Your Name
                      </span>
                    </div>

                    <div>
                      <input
                        className="mobile-number-Referral"
                        type="number"
                        name="mobileNumberReferral"
                        // placeholder="Enter your mobile Number"
                        onChange={handleReferralInputChange}
                        required
                      ></input>
                      <span className="placeholder-mobile">
                        Your Mobile Number
                      </span>
                    </div>
                    {phoneError && (
                        <p className="phone-error-refer">
                          *Number should start from 6,7,8 or 9
                        </p>
                      )}

                    <div>
                      <input
                        type="text"
                        className="form-friendname-Referral"
                        name="fnameReferral"
                        onChange={handleReferralInputChange}
                        required
                      ></input>
                      <span className="placeholder-name">
                        Your Friend's Name
                      </span>
                    </div>
                    <div>
                      <input
                        className="mobile-friendnumber-Referral"
                        type="number"
                        name="fmobileNumberReferral"
                        onChange={handleReferralInputChange}
                        required
                      ></input>
                      <span className="placeholder-mobile">
                        Your Friend's Phone Number
                      </span>
                    </div>
                    {phoneError && (
                        <p className="phone-error-refer">
                          *Number should start from 6,7,8 or 9
                        </p>
                      )}
                        <div>
                      <input
                        type="text"
                        className="form-info-Referral"
                        name="finfo"
                        onChange={handleReferralInputChange}
                      ></input>
                      <span className="placeholder-name-withoutstar">
                        Remarks
                      </span>
                    </div> 
                        
                        <button
                            type="submit"
                            disabled={!isReferralFormValid}
                            className={
                            isReferralFormValid
                                ? "active-Referral"
                                : "disabled-Referral"
                            }
                        >
                            Refer
                      </button>
                      
                        <p className='referral-tnc'>*Applicable for minimum order value of INR 1 Lakh. T&C Apply</p>            
                  </div>
                </form>
                

            </div>
        </div>
        </Fragment>
      )}
    </Fragment>
  );
};
export default Referral;