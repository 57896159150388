import React, { useState, useEffect, Fragment } from "react";
import "./Infobar.css";
import "./ListProducts.css";
import { useHistory } from "react-router-dom";
import { ReactComponent as Bar1 } from "../../icons/bar1.svg";
import { ReactComponent as Bar2 } from "../../icons/bar2.svg";
import { ReactComponent as Bar3 } from "../../icons/bar3.svg";
import { ReactComponent as Bar4 } from "../../icons/bar4.svg";
import LYPImg from "../../images/LYP.jpg";
import { useDispatch, useSelector } from "react-redux";
import { createListForm, clearErrors } from "../../actions/formAction";
import { NEW_LISTFORM_RESET } from "../../constants/formsConstants";
import Loader from "../layout/Loader/Loader";
import { ReactComponent as DropdownIcon } from "../../icons/dropdownMenuIcon.svg";
import MetaData from "../layout/MetaData";

const ListProducts = () => {
  const [lypFormData, setLYPFormData] = useState({
    fnameLYP: "",
    mobileNumberLYP: "",
    emailLYP: "",
    companyNameLYP: "",
    formCityLYP: "",
    isManufacturer: "",
    LYPInfo: "",
    // Add more form fields as needed
  });
  const Text = () => {
    return (
      <span style={{ color: "#868686" }} className="LYP-dropdown-mobile">
        Are you a Manufacturer?
      </span>
    );
  };

  // style={{ color: "#868686"  }}
  const [openIdentifyDropDown, setOpenIdentifyDropDown] = useState(false);
  const [selectedOptionIdentify, setSelectedOptionIdentify] = useState(
    <Text />
  );

  const [isLYPFormValid, setIsLYPFormValid] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const pattern = /^[6-9][0-9]{0,20}$/;
  const dispatch = useDispatch();
  const history = useHistory();

  const { loading, error, success } = useSelector((state) => state.listProduct);

  // Function to update form data
  const handleLYPInputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    if (name === "mobileNumberLYP" && value && !pattern.test(value)) {
      // If the input value does not match the pattern, do not update the state
      e.target.value = "";
      setPhoneError(true);
    } else if (name === "mobileNumberLYP" && value && pattern.test(value)) {
      // If the input value matches the pattern, update the state
      e.target.value = value;
      setPhoneError(false);
    }
    if (name === "mobileNumberLYP" && value.length >= 10) {
      const truncatedValueL = value.slice(0, 10);
      e.target.value = truncatedValueL;
      setLYPFormData((prevData) => ({
        ...prevData,
        [name]: truncatedValueL,
      }));
    } else {
      setLYPFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  const selectIdentify = (value) => {
    setSelectedOptionIdentify(value);
    setOpenIdentifyDropDown(false);
    setLYPFormData((prevData) => ({
      ...prevData,
      isManufacturer: value,
    }));
  };
  // Validation function
  const validateLYPForm = () => {
    const { fnameLYP, mobileNumberLYP, formCityLYP } = lypFormData;
    const requiredFieldsLYP = [fnameLYP, mobileNumberLYP, formCityLYP];

    return (
      requiredFieldsLYP.every((field) => field.trim() !== "") &&
      mobileNumberLYP.length === 10 &&
      !phoneError &&
      pattern.test(lypFormData.mobileNumberLYP)
    );
  };

  // Update form validity whenever the form data changes
  useEffect(() => {
    if (error) {
      dispatch(clearErrors());
    }

    if (success) {
      dispatch({ type: NEW_LISTFORM_RESET });
    }

    setIsLYPFormValid(validateLYPForm());
  }, [lypFormData]);

  const handleLYPSubmit = (e) => {
    e.preventDefault();
    if (validateLYPForm) {
      const myForm = new FormData();

      myForm.set("name", lypFormData.fnameLYP);
      myForm.set("phone", lypFormData.mobileNumberLYP);
      myForm.set("email", lypFormData.emailLYP);
      myForm.set("company", lypFormData.companyNameLYP);
      myForm.set("city", lypFormData.formCityLYP);
      myForm.set("info", lypFormData.LYPInfo);
      myForm.set("isManufacturer", lypFormData.isManufacturer);

      // Perform form submission logic
      // alert("Form submitted");
      // console.log("Form submitted:", lypFormData);
      dispatch(createListForm(myForm)).then(() => {
        history.push("/formSubmit");
      });
    } else {
      alert("Form not valid");
    }
  };

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <MetaData title="List Your Products - Zoplar" />
          <div className="LYP">
            <div className="LYP-img">
              <h3>One-stop platform to sell your product</h3>
              <p>
                Congratulations you have found us! Now you can sell medical
                devices with us. Get wider audience and make double profit.
                <br />
                We provide you with proper targeting of audience, attracting
                audience from our own platform.
              </p>
              <p>
                Don’t just listen , test it out yourself. Fill up the form and
                get a quote.
              </p>
              <div>
                <img src={LYPImg} alt="some img" />
                {/* <p>
                  List your medical product name that you want to sell through
                  us!
                </p> */}
              </div>
            </div>
            <div className="LYP-text">
              <div className="infobar">
                <div className="infobar-section">
                  {/* <Bar1 style={{ marginLeft: "4%" }} className="bar-component"/> */}
                  <Bar1 className="bar-component" />
                  <p>
                    Fill the <br /> form below
                  </p>
                </div>
                <div className="infobar-section">
                  <Bar2 className="bar-component" />
                  <p>
                    Our Team will <br />
                    contact you
                  </p>
                </div>
                <div className="infobar-section">
                  <Bar3 className="bar-component" />
                  <p>
                    Hassle-Free <br /> Onboarding
                  </p>
                </div>
                <div className="infobar-section">
                  <Bar4 className="bar-component" />
                  <p>
                    Start selling <br /> with us
                  </p>
                </div>
              </div>

              <div className="LYP-form">
                <h3>One stop platform to sell your products</h3>
                <form onSubmit={handleLYPSubmit}>
                  <div className="basic-form-LYP">
                    <div className="input-container name-container">
                      <input
                        className="form-name"
                        type="text"
                        name="fnameLYP"
                        onChange={handleLYPInputChange}
                        style={{
                          width: "92%",
                          padding: "1.75% 2.9%",
                        }}
                        required
                      ></input>
                      <div className="placeholder">
                        Name<span>*</span>
                      </div>
                    </div>
                    <div className="form-grid">
                      <div className="input-container">
                        <input
                          className="mobile-number"
                          type="number"
                          name="mobileNumberLYP"
                          // placeholder="Enter your Phone number"
                          onChange={handleLYPInputChange}
                          // style={{ width: "90%" }}
                          required
                        ></input>
                        <div className="placeholder">
                          Phone Number<span>*</span>
                        </div>
                      </div>
                      {phoneError && (
                        <p className="phone-error" style={{ top: "30.5%" }}>
                          *Number should start from 6,7,8 or 9
                        </p>
                      )}
                      <div className="input-container">
                        <input
                          className="form-name"
                          type="text"
                          name="emailLYP"
                          placeholder="Email ID"
                          onChange={handleLYPInputChange}
                        ></input>
                        {/* <div className="placeholder">
                          Enter your Email ID<span>*</span>
                        </div> */}
                      </div>

                      <div className="input-container">
                        {/* <p>City</p> */}
                        <input
                          className="form-name"
                          type="text"
                          name="formCityLYP"
                          // placeholder="Enter your City Name"
                          onChange={handleLYPInputChange}
                          required
                        ></input>
                        <div className="placeholder">
                          City Name<span>*</span>
                        </div>
                      </div>
                      <div className="input-container">
                        {/* <p>Company Name</p> */}
                        <input
                          className="form-name"
                          type="text"
                          name="companyNameLYP"
                          placeholder="Establishment Name"
                          onChange={handleLYPInputChange}
                        ></input>
                        {/* <div className="placeholder">
                          Enter your Company Name<span>*</span>
                        </div> */}
                      </div>
                      <div
                        className="dropdown-type"
                        onClick={() => {
                          setOpenIdentifyDropDown(!openIdentifyDropDown);
                        }}
                      >
                        <div>
                          <p>{selectedOptionIdentify}</p>
                          {openIdentifyDropDown && (
                            <ul>
                              <li
                                onClick={() => {
                                  selectIdentify("Yes");
                                }}
                              >
                                Yes
                              </li>
                              <li
                                onClick={() => {
                                  selectIdentify("No");
                                }}
                              >
                                No
                              </li>
                            </ul>
                          )}
                          <DropdownIcon
                            className="dropdown-icon"
                            onClick={() =>
                              setOpenIdentifyDropDown(!openIdentifyDropDown)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <textarea
                    className="LYP-info"
                    type="text"
                    name="LYPInfo"
                    placeholder="List medical product names that you want to sell"
                    rows="5"
                    onChange={handleLYPInputChange}
                  ></textarea>
                  <button
                    type="submit"
                    className={isLYPFormValid ? "active-LYP" : "disabled-LYP"}
                    disabled={!isLYPFormValid}
                  >
                    Submit
                  </button>
                </form>
              </div>

              <div className="phone-LYP-form">
                <h4>Sell your products to us!</h4>
                <form onSubmit={handleLYPSubmit}>
                  <div className="basic-form-LYP-mobile">
                    <div>
                      <input
                        type="text"
                        className="form-name-LYP"
                        // placeholder="Enter your full Name"
                        name="fnameLYP"
                        onChange={handleLYPInputChange}
                        required
                      ></input>
                      <span className="mobile-placeholder-name-LYP">Name</span>
                    </div>

                    <div>
                      <input
                        className="mobile-number-LYP"
                        type="number"
                        name="mobileNumberLYP"
                        // placeholder="Enter your mobile Number"
                        onChange={handleLYPInputChange}
                        required
                      ></input>
                      <span className="mobile-placeholder-mobile-LYP">
                        Mobile Number
                      </span>
                    </div>
                    {phoneError && (
                      <p
                        className="phone-error-mob"
                        style={{ marginTop: "-0.5%" }}
                      >
                        *Number should start from 6,7,8 or 9
                      </p>
                    )}
                    <div>
                      <input
                        type="text"
                        className="form-city-LYP"
                        name="formCityLYP"
                        // placeholder="Enter Your City Name"
                        onChange={handleLYPInputChange}
                        required
                      ></input>
                      <span className="mobile-placeholder-city-LYP">
                        City Name
                      </span>
                    </div>
                    <div>
                      <input
                        className="form-email-LYP"
                        type="text"
                        name="emailLYP"
                        placeholder="Enter your Email ID"
                        onChange={handleLYPInputChange}
                      ></input>
                    </div>
                    <div>
                      <input
                        className="form-company-LYP"
                        type="text"
                        name="companyNameLYP"
                        placeholder="Enter your Company Name"
                        onChange={handleLYPInputChange}
                      ></input>
                    </div>
                    <div
                      style={{
                        gridColumnStart: "1",
                        gridColumnEnd: "2",
                        height: "25px",
                        marginTop: "3%",
                        marginBottom: "4%",
                      }}
                      className="dropdown-type"
                      onClick={() => {
                        setOpenIdentifyDropDown(!openIdentifyDropDown);
                      }}
                    >
                      <div style={{ height: "16px", padding: "3% 3% 3.5% 3%" }}>
                        <p>{selectedOptionIdentify}</p>
                        {openIdentifyDropDown && (
                          <ul>
                            <li
                              onClick={() => {
                                selectIdentify("Yes");
                              }}
                            >
                              Yes
                            </li>
                            <li
                              onClick={() => {
                                selectIdentify("No");
                              }}
                            >
                              No
                            </li>
                          </ul>
                        )}
                        <DropdownIcon
                          className="dropdown-icon-LYP"
                          onClick={() =>
                            setOpenIdentifyDropDown(!openIdentifyDropDown)
                          }
                        />
                      </div>
                    </div>

                    <textarea
                      className="LYP-info"
                      type="text"
                      name="LYPInfo"
                      placeholder="What Medical Product do you want to sell through us?"
                      rows="5"
                      onChange={handleLYPInputChange}
                    ></textarea>

                    <div>
                      <button
                        type="submit"
                        disabled={!isLYPFormValid}
                        className={
                          isLYPFormValid
                            ? "active-LYP-mobile"
                            : "disabled-LYP-mobile"
                        }
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default ListProducts;
