import React from "react";
import "./EnquiryRecd.css";
// import EnquiryCardImg from "../../images/EnquiryCardImg.png";
import { Link } from 'react-router-dom';
import { useDispatch } from "react-redux";
import {
  emptyCart
} from "../../actions/cartAction";
import { ReactComponent as GreenTick } from "../../icons/greenTick.svg";



const EnquiryRecd = () => {

  const dispatch = useDispatch();

  dispatch(emptyCart());

  

//   const { order } = useSelector((state) => state.newOrder);
// //  const orderID = JSON.stringify(order._id);
// //  console.log(order._id)
// // Check if order and order._id exist before accessing them
// const orderID = order && order._id ? JSON.stringify(order._id) : null;
// const location = useLocation();
//   const orderData = location.state?.orderData;
//   console.log(orderData)
const EnquiryCardImg = "https://zoplardatabase.s3.ap-southeast-2.amazonaws.com/pageimages/EnquiryCardImg.png"

  return (
    <div className="wrapper-enquiryCard">
    <div className="enquiry-card">
      <img src={EnquiryCardImg} alt="enquiry received"/>
      <h3>Congratulations your enquiry is received!</h3>
      <p>
        You will receive a call from our expert team &<br></br> we will provide you the
        best prices.
      </p>
      {/* <p className="trackingID">Your tracking ID is: <span>{orderData}</span></p> */}
      {/* {orderID && (
          <p className="trackingID">
            Your tracking ID is: <span>{orderID}</span>
          </p>
        )} */}
      <Link to="/orders">
      <button className="track">Track Enquiry</button>
    </Link>
    </div>

    <div className="mobile-enquiry-form">
      <GreenTick/>
      <p> Congratulations your enquiry is received!<br/> Our team will get back to you shortly.</p>
      <Link to="/orders">
      <button className="track-button-mobile">Track Enquiry</button>
    </Link>


    </div>
    </div>
  );
};

export default EnquiryRecd;
