import React, { useRef } from "react";
import "./Privacy.css";

const Privacy = () => {
  const currentDate = new Date().toLocaleDateString("en-GB", {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  });

  const whatWeCollectRef = useRef(null);
  const whatWeDoRef = useRef(null);
  const whatWeDis = useRef(null);
  const opting = useRef(null);
  const security = useRef(null);
  const contact = useRef(null);
  const updates = useRef(null);
  const record = useRef(null);
  // Add refs for other sections as needed

  const scrollToSection = (ref) => {
    if (ref && ref.current) {
      const rect = ref.current.getBoundingClientRect();
      const offset = rect.top + window.scrollY; // Calculate the absolute position

      const adjustedOffset = offset - 80;

      window.scrollTo({
        top: adjustedOffset,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="privacypage">
      <div className="privacybanner">
        <h1>Privacy Policy</h1>
      </div>
      <div className="privacyterms">
        <h7 className="privacyintro">
          Welcome to Zoplar, your trusted partner in the world of medical
          supplies. This Privacy Policy is designed to inform you about how we
          collect, use, and protect your personal information when you interact
          with our website. By using our services, you agree to the practices
          outlined in this policy.
        </h7>

        <div ref={whatWeCollectRef}>
          <h5> 1. What Personal Information We Collect:</h5>
          <p>
            At Zoplar, we collect specific information to enhance your
            experience and provide tailored services. This includes your full
            name, phone number, and email address during the registration
            process. When you submit a quotation, we may gather details about
            your product preferences and the specifics of your quotation.
            Additionally, communication records, such as call logs and messages,
            are recorded for quality check purposes.{" "}
          </p>
        </div>

        <div ref={whatWeDoRef}>
          <h5>2. What We Do With the Personal Information We Collect:</h5>
          <p>
            {" "}
            <span className="privacyinfo">Personal Information:</span>To access
            the services provided by Zoplar, users must provide essential
            personal information during the registration process. This
            information may include their name, email address, phone number,
            account number, and, in some cases, their contact address for
            specific services or utilities. The provided information enables
            Zoplar to deliver services, enhance the user experience, and improve
            overall service quality. Zoplar assures users that their data will
            not be shared with third parties, except as outlined in the privacy
            policy or as required by applicable law.
          </p>
          <p>
            Zoplar may occasionally collect additional information to improve
            services but will not disclose this data to third parties. The use
            of the information is service-dependent, intended for providing
            services, maintaining, protecting, and enhancing Zoplar, as well as
            for developing new services. In the regular course of business,
            Zoplar may use users' email addresses and phone numbers for
            marketing, non-marketing, or administrative purposes, such as
            notifying them of significant changes, providing customer service,
            or sharing information about service updates.
          </p>
          <p>
            Information that is freely available or accessible in the public
            domain is not considered personal information under the applicable
            law. User-generated content in public sections of the Zoplar website
            is also exempt from this policy. Users are informed that choosing
            not to submit certain information may result in Zoplar being unable
            to provide specific services, and the platform will make reasonable
            efforts to notify users in such cases. Zoplar is not liable for the
            denial of services due to the non-receipt of necessary information.
            Upon registration, Zoplar may contact users periodically for
            information updates at its discretion.
          </p>
          <p>
            <span className="privacyinfo">Non Personal Information:</span>Zoplar
            automatically collects non-personal information, such as the type of
            internet browser, the domain name of the website from which you
            arrived, the number of visits, average time spent on the site/mobile
            application, and pages viewed. This information is gathered
            seamlessly during your interaction with the website through
            technologies like Web Beacons{" "}
          </p>
          <p>
            Additionally, when you visit our website, we collect your device's
            connection details to the internet, including your IP address.
            Information like your device's name, location, to enhance the
            responsiveness of Zoplar's website for each user, we employ
            electronic tools that assign a unique, random number as a user
            identification (User ID) to understand individual interests based on
            the identified computer. Your continued use of the website implies
            your agreement with the use of these technologies for the purposes
            outlined in this policy. If you prefer not to have such data
            collected, you may choose not to use our services or adjust your
            browsing and third-party cookie settings accordingly.
          </p>
        </div>

        <div ref={whatWeDis}>
          <h5>3. When We Disclose Personal Information:</h5>
          <p>
            Your personal information is treated with the utmost
            confidentiality. It is shared internally with authorized Zoplar
            personnel for necessary business/ service purposes. Additionally, we
            comply with legal obligations, sharing information when required by
            law or law enforcement.
          </p>
        </div>

        <div ref={security}>
          <h5>4.Security:</h5>
          <p>
            Zoplar employs robust security measures to protect your personal
            information. Industry-standard encryption safeguards data during
            transmission, and our servers and databases are secure, with access
            restricted to authorized personnel. Ongoing employee training
            ensures that our staff is well-versed in data security and privacy
            practices. We retain information only for the necessary duration
            required for business operations.
          </p>
        </div>

        <div ref={opting}>
          <h5>5.Opting Out</h5>
          <p>
            You have the option to opt out of receiving promotional emails from
            Zoplar by following the instructions provided in those emails. If
            you choose to opt out, please be aware that Zoplar may still send
            you non-promotional email/ Ads, such as those related to your
            account.
          </p>
          <p>
            Furthermore, you retain the right to withdraw the consent you
            provided at any time, as per the terms outlined in this policy. It's
            important to note that the withdrawal of consent will not apply
            retroactively. If you wish to have your provided information
            deleted, please contact our grievance redressal email at
            support@zoplar.com, and we will respond within a reasonable
            timeframe. Please be aware that Zoplar is not responsible for taking
            down, removing, or editing any of your public activities or
            submissions resulting from those activities. It's essential to
            understand that withdrawing consent does not affect our right to
            continue collecting, using, and disclosing personal information in
            cases where such actions are permitted or required under applicable
            laws.
          </p>
        </div>

        <div ref={record}>
          <h5>Record Keeping and Quality Assurance:</h5>
          <p>
            All calls and messages initiated by Zoplar are recorded for internal
            quality assurance and training purposes. This information is
            strictly used within the organization and is not shared with
            external parties.
          </p>
        </div>

        <div ref={updates}>
          <h5>Updates to the Privacy Policy:</h5>
          <p>
            Zoplar reserves the right to update this Privacy Policy as
            necessary. Users will be notified of any material changes.
          </p>
        </div>

        <div ref={contact}>
          <h5>Contact Information:</h5>
          <p>
            For any questions, concerns, or requests related to privacy, please
            contact our Privacy Officer at{" "}
            <a className="privacysupport" href="mailto:support@zoplar.com">
              support@zoplar.com
            </a>
            .
          </p>
          <p>
            By using the Zoplar website, you acknowledge that you have read and
            understood this Privacy Policy and agree to its terms.
          </p>
          <h7>
            Effective Date:<span className="privacydate">{currentDate}</span>{" "}
          </h7>
        </div>
      </div>

      <div className="privacypolicy">
        <h5>Privacy Policy</h5>
        <h6>
          Effective Date : <span> {currentDate}</span>
        </h6>
        <div className="privacybottomline"></div>
        <div className="privacytable">
          <h5>Table of Contents</h5>
          <p>
            <span onClick={() => scrollToSection(whatWeCollectRef)}>
              1. What Personal Information We Collect:
            </span>
          </p>
          <p>
            <span onClick={() => scrollToSection(whatWeDoRef)}>
              2. What We Do With the Personal Information We Collect:
            </span>
          </p>
          <p>
            {" "}
            <span onClick={() => scrollToSection(whatWeDis)}>
              3. When We Disclose Personal Information:
            </span>
          </p>
          <p>
            <span onClick={() => scrollToSection(security)}>4. Security:</span>
          </p>
          <p>
            <span onClick={() => scrollToSection(opting)}>5. Opting Out</span>
          </p>
          <p>
            <span onClick={() => scrollToSection(record)}>
              Record Keeping and Quality Assurance:
            </span>
          </p>
          <p>
            <span onClick={() => scrollToSection(updates)}>
              Updates to the Privacy Policy:
            </span>
          </p>
          <p>
            <span onClick={() => scrollToSection(contact)}>
              Contact Information:
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
