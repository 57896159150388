import React, { Fragment, useEffect, useState } from "react";
import "./CategoryList.css";
import { useSelector, useDispatch } from "react-redux";
import { clearErrors, getCategory } from "../../actions/categoryAction";
import Loader from "../layout/Loader/Loader";
import { useAlert } from "react-alert";
import MetaData from "../layout/MetaData";
import CategoryCard from "./CategoryCard";
import { ReactComponent as SearchIcon } from "../../icons/searchIcon.svg";

const CategoryList = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const [searchCategory, setSearchCategory] = useState("");
  const [filteredCategories, setFilteredCategories] = useState([]);

  const { categories, loading, error } = useSelector(
    (state) => state.allCategory
  );
  const handleSearchCategoryChange = (value) => {
    setSearchCategory(value);
    const filtered = categories.filter((item) =>
      item.name.split(" ").some((word) => word.toLowerCase().startsWith(value.toLowerCase()))
    );
    setFilteredCategories(filtered);
  };

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    dispatch(getCategory());
  }, [dispatch, alert, error]);

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <MetaData title="Product Categories - Zoplar" />
          <div className="category-page">
            <div className="category-searchbar">
              <h2 className="category-Heading">{window.innerWidth <= 768 ? "Categories" : "Our Categories"}</h2>
              <div className="search-category">
                <SearchIcon />
                <input
                  className="search-category-items"
                  type="text"
                  placeholder="Search Category"
                  value={searchCategory}
                  onChange={(e) => {
                    handleSearchCategoryChange(e.target.value);
                  }}
                  // onKeyUp={(e) => {
                  //   handleEnterValue(searchProduct, e);
                  // }}
                />
              </div>
            </div>
            <div className="categories">
              {searchCategory
                ? filteredCategories &&
                  filteredCategories.map((category) => (
                    <CategoryCard key={category._id} category={category} />
                  ))
                : categories &&
                  categories.map((category) => (
                    <CategoryCard key={category._id} category={category} />
                  ))}
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default CategoryList;
