import {
    ADD_TO_CART,
    REMOVE_CART_ITEM,
    UPDATE_CART_ITEM_QUANTITY,
    UPDATE_CART_ITEM_BUNDLE_TYPE,
    EMPTY_CART,
  } from "../constants/cartConstants";
  import axios from "axios";
  


  // Add to Cart
export const addItemsToCart = (id, quantity) => async (dispatch, getState) => {
  const { cartItems } = getState().cart;
  
  // Check if the product with the given id is already in the cart
  const existingCartItem = cartItems.find((item) => item.product === id);

  if (!existingCartItem || (existingCartItem && existingCartItem.quantity < 1)) {
    // Only make the API call if the item is not in the cart or has a quantity less than one
    const { data } = await axios.get(`/api/v1/product/${id}`);

    dispatch({
      type: ADD_TO_CART,
      payload: {
        product: data.product._id,
        name: data.product.name,
        image: data.product.image[0],
        Brand: data.product.Brand,
        Size: data.product.Size,
        bundleType:"Piece",
        Sku: data.product.Sku,
        quantity,
        
      },
    });

    // Update local storage
    sessionStorage.setItem("cartItems", JSON.stringify([...cartItems, {
      product: data.product._id,
      name: data.product.name,
      image: data.product.image[0],
      Brand: data.product.Brand,
      Size: data.product.Size,
      bundleType:"Piece",
      Sku: data.product.Sku,
      quantity,
    }]));
  } else {
    // If the item is already in the cart or has a quantity greater than one, just update the quantity
    dispatch({
      type: UPDATE_CART_ITEM_QUANTITY,
      payload: {
        productId: id,
        quantity:  quantity,
      },
    });

    // Update local storage
    const updatedCartItems = cartItems.map((item) => {
      if (item.product === id) {
        return {
          ...item,
          quantity: quantity,
        };
      }
      return item;
    });

    sessionStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
  }
};

export const updateCartItemBundleType = (productId, bundleType) =>  async (dispatch, getState) =>{
  const { cartItems } = getState().cart;
  dispatch( {
    type: UPDATE_CART_ITEM_BUNDLE_TYPE,
    payload: {
      productId:productId,
      bundleType:bundleType,
    },
  });

  const updatedCartItems = cartItems.map((item) => {
    if (item.product === productId) {
      return {
        ...item,
        bundleType: bundleType,
      };
    }
    return item;
  });

  sessionStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
};

  
  // REMOVE FROM CART
  export const removeItemsFromCart = (id) => async (dispatch, getState) => {
    dispatch({
      type: REMOVE_CART_ITEM,
      payload: id,
    });
  
    sessionStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems));
  };


  export const emptyCart = () => async (dispatch) => {
    dispatch({
      type: EMPTY_CART,
    });
  
    // Clear the cart in the local storage as well
    sessionStorage.setItem("cartItems", JSON.stringify([]));
  };


  
  