import {
    NEW_SELLFORM_REQUEST,
    NEW_SELLFORM_SUCCESS,
    NEW_SELLFORM_FAIL,
    NEW_SELLFORM_RESET,
    NEW_LISTFORM_REQUEST,
    NEW_LISTFORM_SUCCESS,
    NEW_LISTFORM_FAIL,
    NEW_LISTFORM_RESET,
    NEW_FINANCEFORM_REQUEST,
    NEW_FINANCEFORM_SUCCESS,
    NEW_FINANCEFORM_FAIL,
    NEW_FINANCEFORM_RESET,
    NEW_SERVICEFORM_REQUEST,
    NEW_SERVICEFORM_SUCCESS,
    NEW_SERVICEFORM_FAIL,
    NEW_SERVICEFORM_RESET,
    NEW_CONTACTUS_REQUEST,
    NEW_CONTACTUS_SUCCESS,
    NEW_CONTACTUS_FAIL,
    NEW_CONTACTUS_RESET,
    NEW_REFERRAL_REQUEST,
    NEW_REFERRAL_SUCCESS,
    NEW_REFERRAL_FAIL,
    NEW_REFERRAL_RESET,
    CLEAR_ERRORS,
  } from "../constants/formsConstants";


  export const sellFormReducer = (state = { sell: {} }, action) => {
    switch (action.type) {
      case NEW_SELLFORM_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case NEW_SELLFORM_SUCCESS:
        return {
          loading: false,
          success: action.payload.success,
         // sell: action.payload.sell,
        };
      case NEW_SELLFORM_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case NEW_SELLFORM_RESET:
        return {
          ...state,
          success: false,
        };
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
      default:
        return state;
    }
  };

  export const listFormReducer = (state = { listProduct: {} }, action) => {
    switch (action.type) {
      case NEW_LISTFORM_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case NEW_LISTFORM_SUCCESS:
        return {
          loading: false,
          success: action.payload.success,
        //  listForm: action.payload.listForm,
        };
      case NEW_LISTFORM_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case NEW_LISTFORM_RESET:
        return {
          ...state,
          success: false,
        };
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
      default:
        return state;
    }
  };


  export const financeFormReducer = (state = { finance: {} }, action) => {
    switch (action.type) {
      case NEW_FINANCEFORM_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case NEW_FINANCEFORM_SUCCESS:
        return {
          loading: false,
          success: action.payload.success,
         // finance: action.payload.finance,
        };
      case NEW_FINANCEFORM_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case NEW_FINANCEFORM_RESET:
        return {
          ...state,
          success: false,
        };
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
      default:
        return state;
    }
  };


  export const serviceFormReducer = (state = { service: {} }, action) => {
    switch (action.type) {
      case NEW_SERVICEFORM_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case NEW_SERVICEFORM_SUCCESS:
        return {
          loading: false,
          success: action.payload.success,
         // service: action.payload.service,
        };
      case NEW_SERVICEFORM_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case NEW_SERVICEFORM_RESET:
        return {
          ...state,
          success: false,
        };
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
      default:
        return state;
    }
  };


  export const contactusFormReducer = (state = { contactus: {} }, action) => {
    switch (action.type) {
      case NEW_CONTACTUS_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case NEW_CONTACTUS_SUCCESS:
        return {
          loading: false,
          success: action.payload.success,
        };
      case NEW_CONTACTUS_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case NEW_CONTACTUS_RESET:
        return {
          ...state,
          success: false,
        };
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
      default:
        return state;
    }
  };

  export const referralReducer = (state = { referral: {} }, action) => {
    switch (action.type) {
      case NEW_REFERRAL_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case NEW_REFERRAL_SUCCESS:
        return {
          loading: false,
          success: action.payload.success,
        };
      case NEW_REFERRAL_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case NEW_REFERRAL_RESET:
        return {
          ...state,
          success: false,
        };
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
      default:
        return state;
    }
  };