import React, { useState, useEffect, Fragment } from "react";
import "./Infobar.css";
import "./Sell.css";
import { useHistory } from "react-router-dom";
import { ReactComponent as Bar1 } from "../../icons/bar1.svg";
import { ReactComponent as Bar2 } from "../../icons/bar2.svg";
import { ReactComponent as Bar8 } from "../../icons/bar8.svg";
import { ReactComponent as Bar9 } from "../../icons/bar9.svg";
import SellWithUs from "../../images/SellWithUs.jpg";
import { useDispatch, useSelector } from "react-redux";
import { createSellForm, clearErrors } from "../../actions/formAction";
import { NEW_SELLFORM_RESET } from "../../constants/formsConstants";
import Loader from "../layout/Loader/Loader";
import MetaData from "../layout/MetaData";

const Sell = () => {
  const [sellFormData, setSellFormData] = useState({
    fname: "",
    mobileNumber: "",
    email: "",
    companyName: "",
    formCity: "",
    sellInfo: "",
    // Add more form fields as needed
  });
  const [isSellFormValid, setIsSellFormValid] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const { loading, error, success } = useSelector((state) => state.sell);
  const pattern = /^[6-9][0-9]{0,20}$/;

  // Function to update form data
  const handleSellInputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    if (name === "mobileNumber" && value && !pattern.test(value)) {
      // If the input value does not match the pattern, do not update the state
      e.target.value = "";
      setPhoneError(true);
    } else if (name === "mobileNumber" && value && pattern.test(value)) {
      // If the input value matches the pattern, update the state
      e.target.value = value;
      setPhoneError(false);
    }

    if (
      (name === "mobileNumber" || name === "mobileNumbersell") &&
      value.length >= 10
    ) {
      const truncatedValue = value.slice(0, 10);
      e.target.value = truncatedValue;

      setSellFormData((prevData) => ({
        ...prevData,
        [name]: truncatedValue,
      }));
    } else {
      setSellFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  // Validation function
  const validateSellForm = () => {
    const { fname, mobileNumber, formCity } = sellFormData;
    const requiredFields = [fname, mobileNumber, formCity];

    return (
      requiredFields.every((field) => field.trim() !== "") &&
      mobileNumber.length === 10 &&
      !phoneError &&
      pattern.test(sellFormData.mobileNumber)
    );
  };

  // Update form validity whenever the form data changes
  useEffect(() => {
    if (error) {
      dispatch(clearErrors());
    }

    if (success) {
      dispatch({ type: NEW_SELLFORM_RESET });
    }

    setIsSellFormValid(validateSellForm());
  }, [sellFormData]);

  const handleSellSubmit = (e) => {
    e.preventDefault();
    if (validateSellForm) {
      const myForm = new FormData();

      myForm.set("name", sellFormData.fname);
      myForm.set("phone", sellFormData.mobileNumber);
      myForm.set("email", sellFormData.email);
      myForm.set("company", sellFormData.companyName);
      myForm.set("city", sellFormData.formCity);
      myForm.set("info", sellFormData.sellInfo);

      //console.log(myForm)
      // Perform form submission logic
      // alert("Form submitted");
      //console.log("Form submitted:", sellFormData);
      dispatch(createSellForm(myForm)).then(() => {
        history.push("/formSubmit");
      });
    } else {
      //console.log("Form is not valid");
      alert("Form not valid");
    }
  };

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <MetaData title="Sell your machine - Zoplar" />
          <div className="Sell">
            <div className="sell-img">
              <h3>One-stop platform to sell Old Medical Device!</h3>
              <p>
                Eureka! you have now found the best platform to sell your
                pre-owned medical products. Selling pre-owned Medical products
                and getting the best rates can be really difficult.
              </p>
              <p>
                Here at Zoplar, we have extended our hands to help you out. We
                are willing to buy your pre-owned Devices and offer genuine
                price for it.
              </p>
              <p>
                Don’t just listen , test it out yourself. Fill up the form and
                get a quote.
              </p>
              <div>
                <img src={SellWithUs} alt="some img" />
              </div>
            </div>
            <div className="sell-text">
              <div className="infobar">
                <div className="infobar-section">
                  {/* <Bar1 style={{ marginLeft: "4%" }} className="bar-component"/> */}
                  <Bar1 className="bar-component" />
                  <p>
                    Fill the <br /> form below
                  </p>
                </div>
                <div className="infobar-section">
                  <Bar2 className="bar-component" />
                  <p>
                    Our Team will <br />
                    contact you
                  </p>
                </div>
                <div className="infobar-section">
                  <Bar8 className="bar-component" />
                  <p>
                    Quality Inspection <br />
                    by our Bio Med Engineer
                  </p>
                </div>
                <div className="infobar-section">
                  <Bar9 className="bar-component" />
                  <p>
                    Get Instant
                    <br />
                    Payment
                  </p>
                </div>
              </div>
              <div className="sell-form">
                <h3>Sell your Pre-Owned machines to us!</h3>
                <form onSubmit={handleSellSubmit}>
                  <div className="basic-form-sell">
                    <div className="input-container name-container">
                      <input
                        className="form-name"
                        type="text"
                        name="fname"
                        //placeholder="Enter your name"
                        onChange={handleSellInputChange}
                        style={{
                          width: "92%",
                          padding: "1.75% 2.9%",
                        }}
                        required
                      ></input>
                      <div className="placeholder">
                        Name<span>*</span>
                      </div>
                    </div>
                    <div className="form-grid">
                      <div className="input-container">
                        <input
                          className="mobile-number"
                          type="number"
                          name="mobileNumber"
                          // placeholder="Enter your Phone number"
                          onChange={handleSellInputChange}
                          // style={{ width: "90%" }}
                          required
                        ></input>
                        <div className="placeholder">
                          Phone Number<span>*</span>
                        </div>
                      </div>
                      {phoneError && (
                        <p className="phone-error">
                          *Number should start from 6,7,8 or 9
                        </p>
                      )}
                      <div className="input-container">
                        <input
                          className="form-name"
                          type="text"
                          name="email"
                          placeholder="Email ID"
                          onChange={handleSellInputChange}
                        ></input>
                        <div className="placeholder">Email ID</div>
                      </div>

                      <div className="input-container">
                        {/* <p>City</p> */}
                        <input
                          className="form-name"
                          type="text"
                          name="formCity"
                          // placeholder="Enter your City Name"
                          onChange={handleSellInputChange}
                          required
                        ></input>
                        <div className="placeholder">
                          City Name<span>*</span>
                        </div>
                      </div>
                      <div className="input-container">
                        <input
                          className="form-name"
                          type="text"
                          name="companyName"
                          placeholder="Establishment Name"
                          onChange={handleSellInputChange}
                        ></input>
                        <div className="placeholder">
                          Enter your Company Name
                        </div>
                      </div>
                    </div>
                  </div>
                  <textarea
                    className="sell-info"
                    type="text"
                    name="sellInfo"
                    placeholder="What product do you want to sell to us?"
                    rows="5"
                    onChange={handleSellInputChange}
                  ></textarea>
                  <button
                    type="submit"
                    className={
                      isSellFormValid ? "active-sell" : "disabled-sell"
                    }
                    disabled={!isSellFormValid}
                  >
                    Submit
                  </button>
                </form>
              </div>

              <div className="phone-sell-form">
                <h4>Sell your Pre-Owned machines to us!</h4>
                <form onSubmit={handleSellSubmit}>
                  <div className="basic-form-sell-mobile">
                    <div>
                      <input
                        type="text"
                        className="form-name-sell"
                        // placeholder="Enter your full Name"
                        name="fname"
                        onChange={handleSellInputChange}
                        required
                      ></input>
                      <span className="mobile-placeholder-name">Name</span>
                    </div>

                    <div>
                      <input
                        className="mobile-number-sell"
                        type="number"
                        name="mobileNumber"
                        // placeholder="Enter your mobile Number"
                        onChange={handleSellInputChange}
                        required
                      ></input>
                      <span className="mobile-placeholder-mobile">
                        Mobile Number
                      </span>
                    </div>
                    {phoneError && (
                      <p className="phone-error-mob">
                        *Number should start from 6,7,8 or 9
                      </p>
                    )}
                    <div>
                      <input
                        type="text"
                        className="form-city-sell"
                        name="formCity"
                        // placeholder="Enter Your City Name"
                        onChange={handleSellInputChange}
                        required
                      ></input>
                      <span className="mobile-placeholder-city">City Name</span>
                    </div>
                    <div>
                      <input
                        className="form-email-sell"
                        type="text"
                        name="email"
                        placeholder="Enter your Email ID"
                        onChange={handleSellInputChange}
                      ></input>
                    </div>
                    <div>
                      <input
                        className="form-company-sell"
                        type="text"
                        name="companyName"
                        placeholder="Establishment Name"
                        onChange={handleSellInputChange}
                      ></input>
                    </div>
                    <textarea
                      className="sell-info"
                      type="text"
                      name="sellInfo"
                      placeholder="What Medical Product do you want to sell through us?"
                      rows="5"
                      onChange={handleSellInputChange}
                    ></textarea>

                    <div>
                      <button
                        type="submit"
                        disabled={!isSellFormValid}
                        className={
                          isSellFormValid
                            ? "active-sell-mobile"
                            : "disabled-sell-mobile"
                        }
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div></div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default Sell;
