import Dialog from "@mui/material/Dialog";
import { DialogContent } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { Fragment, useState, useEffect } from "react";
import OtpInput from "react-otp-input";
import Register from "./Register.js";
import "./LoginPopup.css";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { sendOTP, verifyOTP, clearErrors } from "../../actions/userAction";
import { PhoneNumberUtil } from "google-libphonenumber";
import LoginImg from "../../images/loginImg.jpg";
import { addItemsToCart } from "../../actions/cartAction";
import { ReactComponent as InfoIcon } from "../../icons/infoIcon.svg";



const phoneUtil = PhoneNumberUtil.getInstance();
const countries = phoneUtil.getSupportedRegions();

const LoginPopup = (props) => {
  const {
    error: errorLogin,
    isAuthenticated,
    redirectToRegistration,
  } = useSelector((state) => state.user);

  // const LoginImg =
  //   "https://zoplardatabase.s3.ap-southeast-2.amazonaws.com/pageimages/login_img.jpg";

  const { openPopup, setOpenPopup, onClose, sendId, onAddToCart, sendId2 } =
    props;
  const [inputValue, setInputValue] = useState("");
  const [otp, setOTP] = useState("");
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [showSection, setShowSection] = useState(false);
  const [openPopup2, setOpenPopup2] = useState(false);
  const onCloseReg = () => {
    setOpenPopup2(false);
    setOpenPopup(false);
  };
  const [selectedCountry, setSelectedCountry] = useState("+91"); // Default to India
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [resendDisabled, setResendDisabled] = useState(false);
  const [countdown, setCountdown] = useState(90);

  const location = useLocation();
  const { pathname } = location;

  const dispatch = useDispatch();
  const alert = useAlert();

  useEffect(() => {
    if (errorLogin) {
      dispatch(clearErrors());
    }

    if (errorLogin === "Invalid OTP") {
      setErrorMessage("Kindly enter correct OTP*");
      setOTP("");
    }

    if (isAuthenticated && pathname !== "/cart") {
      setOpenPopup(false);
    }

    if (isAuthenticated && sendId2) {
      dispatch(addItemsToCart(sendId2, 1));
      setOpenPopup(false);
    }

    if (isAuthenticated && sendId) {
      dispatch(addItemsToCart(sendId, 1));
      setOpenPopup(false);
      onAddToCart();
    }

    if (redirectToRegistration) {
      setOpenPopup2(true);
    }
  }, [dispatch, errorLogin, alert, isAuthenticated, redirectToRegistration]);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const selectCountry = (countryCode) => {
    const countryInfo = phoneUtil.getCountryCodeForRegion(countryCode);
    setSelectedCountry(`+${countryInfo}`);
    setIsDropdownOpen(false);
  };

  const renderCountryList = () => {
    return countries.map((countryCode) => {
      const countryInfo = phoneUtil.getCountryCodeForRegion(countryCode);
      return (
        <li key={countryCode} onClick={() => selectCountry(countryCode)}>
          <span>{countryCode}</span>
          <span>+{countryInfo}</span>
        </li>
      );
    });
  };

  const handleToggleSection = () => {
    setShowSection(true);
    dispatch(sendOTP(inputValue));

    setResendDisabled(true);
    setCountdown(60);

    const timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    setTimeout(() => {
      setResendDisabled(false);
      clearInterval(timer);
    }, 60000); // 90 seconds in milliseconds
  };
  const handleResendOTP = () => {
    if (!resendDisabled) {
      // Disable the button
      setResendDisabled(true);

      dispatch(sendOTP(inputValue));

      // Set the countdown to the desired time (in seconds)
      setCountdown(60);

      const timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
      
      // After 90 seconds, re-enable the button
      setTimeout(() => {
        setResendDisabled(false);
        clearInterval(timer);
      }, 60000); // 90 seconds in milliseconds
    }
  };

  const formattedCountdown = `${Math.floor(countdown / 60)
  .toString()
  .padStart(2, '0')}:${(countdown % 60).toString().padStart(2, '0')}`;


  const handleOTPChange = (newOTP) => {
    setOTP(newOTP);
    setIsButtonEnabled(newOTP.length === 6);
  };

  const handleInputChange = (event) => {
    event.preventDefault();
    const newValue = event.target.value;
    if (newValue.length <= 10) {
      setInputValue(newValue);
    }
  };

  const handleVerifyOTP = (e) => {
    e.preventDefault();
    // dispatch(loginWithOtp(inputValue, otp));
    dispatch(verifyOTP(inputValue, otp));
  };

  
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const Logo =
  "https://zoplardatabase.s3.ap-southeast-2.amazonaws.com/pageimages/logo.png";

  // const [isPopupVisible, setPopupVisibility] = useState(false);



  // const handleTogglePopup = () => {
  //   setPopupVisibility(!isPopupVisible);
  // };

  return (
    <Fragment>

      {isDesktop &&(<Dialog open={openPopup} maxWidth="md">
        <DialogContent sx={{ padding: 0 }}>
          <div className="login-signup">
            <div className="login-img">
              <img src={LoginImg} alt="some img" />
            </div>
            <div className="loginpage-container">
              <button className="close-button" onClick={onClose}>
                <CloseIcon style={{ color: "#FFFFFF" }} />
              </button>
              <div className="login-text">
                <h1>Login/Sign Up</h1>
                <p>Mobile Number</p>
                <div className="mobile-field">
                  <div className="custom-select">
                    <button
                      className="custom-select-button"
                      onClick={toggleDropdown}
                    >
                      {selectedCountry}
                    </button>
                    {isDropdownOpen && (
                      <ul className="custom-select-dropdown">
                        {renderCountryList()}
                      </ul>
                    )}
                  </div>

                  <input
                    className="mobile-no"
                    type="number"
                    value={inputValue}
                    onChange={handleInputChange}
                    required
                  ></input>
                </div>
                {!showSection ? (
                  <button
                    className={
                      inputValue.length === 10
                        ? "l-active-button"
                        : "l-disabled-button"
                    }
                    disabled={inputValue.length !== 10}
                    onClick={handleToggleSection}
                    style={{ marginTop: "10px" }}
                  >
                    Send OTP
                  </button>
                ) : (
                  <div className="whatsapp-alerts">
                    <label className="custom-checkbox">
                      <input type="checkbox" defaultChecked />
                      <span className="checkmark"></span>
                    </label>
                    <p>Also get Alerts on WhatsApp</p>
                  </div>
                )}
                {showSection && (
                  <div>
                    <div className="otp-text">
                      <p>Enter OTP</p>
                      {errorMessage && (
                        <span className="invalid-otp">{errorMessage}</span>
                      )}
                      <OtpInput
                        value={otp}
                        onChange={handleOTPChange}
                        numInputs={6}
                        renderSeparator={<span> </span>}
                        renderInput={(props) => <input {...props} />}
                        inputStyle="otp-input-field"
                        // containerStyle="otp-field"
                        containerStyle={`otp-field ${
                          errorMessage ? "invalid-box" : ""
                        }`}
                        className="custom-otp-input"
                        shouldAutoFocus={otp.length === 0}
                      />
                      <span
                        className="resend-otp"
                        onClick={() => handleResendOTP()}
                        style={{ color: resendDisabled ? 'grey' : '#0081df' }}
                      >
                        Resend OTP {resendDisabled ? ` in ${formattedCountdown}` : ''}
                      </span>
                      
                    </div>
                    <button
                      className={
                        isButtonEnabled
                          ? "l-active-button"
                          : "l-disabled-button"
                      }
                      disabled={!isButtonEnabled}
                      onClick={handleVerifyOTP}
                      style={{
                        padding: "10px 25px",
                        marginTop: errorMessage ? "10px" : "10px",
                      }}
                    >
                      Submit
                    </button>
                  </div>
                )}
              </div>
              <p className="privacy-policy">
                <InfoIcon
                  style={{ height: "14px", width: "14px", marginTop: "0px" }}
                />{" "}
                <span>
                  By submitting your contact information, you have granted
                  Zoplar explicit permission to contact you in the future.
                </span>
              </p>
            </div>
          </div>
          {openPopup2 && (
            <Register
              openPopup2={openPopup2} // Custom prop
              setOpenPopup2={setOpenPopup2} // Custom prop
              onCloseReg={onCloseReg} // Pass the onClose function
            />
          )}
        </DialogContent>
      </Dialog>)}

      <div className="full-screen-popup open-popup ">
        {!isDesktop &&(

        <div className="login-popup">

            {/* <div className="login-img">
                <img src={LoginImg} alt="some img" />
            </div> */}
            <div className="loginpage-container">
            <button className="close-button" onClick={onClose}>
              <CloseIcon style={{ color: "#FFFFFF" }} />
            </button>
            <div className="login-text">
              <img src={Logo} alt="Zoplar Logo"/>              
              <h1>Login/Sign Up</h1>
              {/* <p>Mobile Number</p> */}
              <div className="mobile-field">
                <div className="custom-select">
                  <button
                    className="custom-select-button"
                    onClick={toggleDropdown}
                  >
                    {selectedCountry}
                  </button>
                  {isDropdownOpen && (
                    <ul className="custom-select-dropdown">
                      {renderCountryList()}
                    </ul>
                  )}
                </div>

                <input
                  className="mobile-no"
                  type="number"
                  value={inputValue}
                  onChange={handleInputChange}
                  placeholder="Mobile Number"
                  required
                ></input>
              </div>
              {!showSection ? (
                <button
                  className={
                    inputValue.length === 10
                      ? "l-active-button"
                      : "l-disabled-button"
                  }
                  disabled={inputValue.length !== 10}
                  onClick={handleToggleSection}
                  style={{ marginTop: "10px" }}
                >
                  Send OTP
                </button>
              ) : (
                <div className="whatsapp-alerts">
                  <label className="custom-checkbox">
                    <input type="checkbox" defaultChecked />
                    <span className="checkmark"></span>
                  </label>
                  <p>Also get Alerts on WhatsApp</p>
                </div>
              )}
              {showSection && (
                <div>
                  <div className="otp-text">
                    <p>Enter OTP</p>
                    {errorMessage && (
                        <span className="invalid-otp">{errorMessage}</span>
                      )}
                    <OtpInput
                      value={otp}
                      onChange={handleOTPChange}
                      numInputs={6}
                      renderSeparator={<span> </span>}
                      renderInput={(props) => <input {...props} />}
                      inputStyle="otp-input-field"
                      containerStyle={`otp-field ${
                        errorMessage ? "invalid-box" : ""
                      }`}
                      className="custom-otp-input"
                      shouldAutoFocus
                    />
                    <span
                        className="resend-otp"
                        onClick={() => handleResendOTP()}
                        style={{ color: resendDisabled ? 'grey' : '#0081df' }}
                      >
                        Resend OTP {resendDisabled ? ` in ${formattedCountdown}` : ''}
                      </span>
                  </div>
                  <button
                    className={
                      isButtonEnabled
                        ? "l-active-button"
                        : "l-disabled-button"
                    }
                    disabled={!isButtonEnabled}
                    onClick={handleVerifyOTP}
                    style={{
                      padding: "10px 25px",
                      marginTop: errorMessage ? "10px" : "10px",
                    }}
                  >
                    Submit
                  </button>
                </div>
              )}
            </div>
            <p className="privacy-policy">
              <InfoIcon
                style={{ height: "14px", width: "14px", marginTop: "0px" }}
              />{" "}
              <span>
                By submitting your contact information, you have granted
                Zoplar explicit permission to contact you in the future.
              </span>
            </p>
            </div>

          </div>
        )}

            {openPopup2 && (
              <Register
                openPopup2={openPopup2} // Custom prop
                setOpenPopup2={setOpenPopup2} // Custom prop
                onCloseReg={onCloseReg} // Pass the onClose function
              />
            )}
      </div>
      
    </Fragment>
    //   )}
    // </Fragment>
  );
};

export default LoginPopup;
