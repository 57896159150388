import React, { Fragment } from "react";
import "./Home.css";
import ImageCarousel from "./ImageCarousel";
import ProductDisplayBar from "./ProductDisplayBar";
import Testimonials from "./Testimonials";
import Refurb from "../Product/AdforRefurb/Refurb";
import CardSlider from "./CardSlider";
import MetaData from "../layout/MetaData";

const Home = () => {
  return (
    <Fragment>
      <MetaData title="Zoplar - Medical Supply Simplified" />
      <ImageCarousel></ImageCarousel>
      <ProductDisplayBar></ProductDisplayBar>

      <CardSlider></CardSlider>
      <Testimonials></Testimonials>
      <Refurb></Refurb>
    </Fragment>
  );
};

export default Home;
