import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store";
import { ReactComponent as CloseIcon } from "../src/icons/closeIcon2.svg";
import "./index.css"

import { positions, transitions, Provider as AlertProvider } from "react-alert";
//import AlertTemplate from "react-alert-template-basic";

const options = {
  timeout: 3000,
  position: positions.TOP_RIGHT,
  transition: transitions.SCALE,
};

const style = {
  zIndex: "1000",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "15px",
  backgroundColor: "white",
  border: "1.5px solid #6DBC61",
  borderLeftWidth: "7px",
  padding: "6px 10px",
  borderRadius: "7px 0px 0px 7px",
  color: "#6F6F6F",
  fontWeight: "700",
  fontSize: "13px",
  marginTop: "38%",
  width: "180px",
};
const AlertTemplate = ({ options, message, close }) => (
  <div
    // style={{
    //   zIndex: "1000",
    //   display: "flex",
    //   justifyContent: "center",
    //   alignItems: "center",
    //   gap: "15px",
    //   backgroundColor: "white",
    //   border: "1.5px solid #6DBC61",
    //   borderLeftWidth: "7px",
    //   padding: "6px 10px",
    //   borderRadius: "7px 0px 0px 7px",
    //   color: "#6F6F6F",
    //   fontWeight: "700",
    //   fontSize: "13px",
    //   marginTop: "38%",
    //   width: "180px",
    //   cursor: "pointer",
    // }}
    style={style}
  >
    {options.type === "info" && "!"}
    {options.type === "success"}
    {options.type === "error" && ":("}
    {message}
    <CloseIcon style={{ zIndex:"1000" , cursor: "pointer" }} onClick={() => close()} />
  </div>
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <AlertProvider template={AlertTemplate} {...options} >
      <App />
    </AlertProvider>
  </Provider>
);
