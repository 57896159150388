import React from "react";
import { ReactComponent as Profile1Icon } from "../../icons/profile1Icon.svg";
import { ReactComponent as HomeIcon } from "../../icons/homeIcon.svg";
import { ReactComponent as TrackingIcon } from "../../icons/trackingIcon.svg";
import { ReactComponent as ContactUsIcon } from "../../icons/contactusIcon.svg";
import { ReactComponent as LogoutIcon } from "../../icons/logoutIcon.svg";
import { Link } from "react-router-dom";
import { logout } from "../../actions/userAction";
import { useAlert } from "react-alert";
import { useDispatch,useSelector } from "react-redux";
import {
  emptyCart
} from "../../actions/cartAction";

const ProfileBox = () => {
  const alert = useAlert();
  const dispatch = useDispatch();

  const { user} = useSelector(
    (state) => state.user
  );

  

  function logoutUser() {
    dispatch(logout());
    dispatch(emptyCart());
    alert.success("Logout Successfully");
  }
  return (
    <>
      {" "}
      <div className="profileBox">
        <div className="profile">
          <span className="profIcon">
            <Profile1Icon style={{marginTop:"1%"}} />
          </span>{" "}
          <span className="profile-name">{user.name}</span>
        </div>
        <div className="f-line"></div>
        <div className="profileBox-menu">
          <ul>
          <Link to="/">
            <li style={{ paddingTop: "15px" }}>
              <span className="sidebar1"></span>
              <span className="box-icons">
               <HomeIcon/>
              </span>
              <span>Home</span>
            </li>
            </Link>
            <li className="default-selected-item">
              <span className="sidebar1"></span>
              <span className="box-icons">
                <TrackingIcon />
              </span>
              <span>Quotation Tracking</span>
            </li>
            <Link to="/ContactUs">
            <li style={{ paddingBottom: "10px" }}>
              <span className="sidebar1"></span>
              <span className="box-icons">
                <ContactUsIcon />
              </span>
              <span>Contact Us</span>
            </li>
            </Link>
          </ul>
        </div>
      </div>
      <button className="logout-btn" onClick={logoutUser}>
        <span>
          <LogoutIcon />
        </span>
        <span className="logout-txt">Logout</span>
      </button>
    </>
  );
};

export default ProfileBox;
