import React, { Fragment } from "react";
import { useState } from "react";
import MetaData from "../layout/MetaData";
import "./AboutUs.css";
import titan from "../../images/titan.png";
import beenext from "../../images/beenext.png";
import saison from "../../images/saison.png";
import park from "../../images/park.png";
import mamaearth from "../../images/mamaearth.png";
import milkbasket from "../../images/milkbasket.png";
import mpl from "../../images/mpl.png";
import bharatpe from "../../images/bharatpe.png";
import urbancompany from "../../images/urbancompany.png";
import housing from "../../images/housing.png";
import ola from "../../images/ola.png";

const AboutUs = () => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleCardClick = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <Fragment>
      <MetaData title={"About Us - Zoplar"} />
      <div className="aboutus-container">
        <div className="about-us-img">
          <h3>About us!</h3>
          <p>
            Zoplar simplifies medical supply procurement with a one-stop
            platform for all your hospital requirements. Access a wide range of
            products, eliminate pricing and inventory complexity, and enjoy
            excellent delivery and after-sales experiences. We aim to make
            medical supply procurement more convenient and cost-effective.
          </p>
          <p style={{ fontSize: window.innerWidth > 768 ? '0.8vw' : '8px', paddingLeft: window.innerWidth > 768 ? '' : '5px', paddingRight: window.innerWidth > 768 ? '' : '5px' }}>
              <br></br>
     <strong>Zoplar</strong>  is a registered trademark operated by <strong>Savdo Technologies Pvt. Ltd.</strong>  (U51100HR2022PTC104168)
    </p>
        </div>
        <div className="about-us-cards">
          <div className="about-card-one">
            <h3>Our Mission</h3>
            <p>
              Transform the global supply chain of medical supplies with
              technology and be the one stop solution for all medical supplies
            </p>
          </div>
          <div className="about-card-two">
            <h3>Our Vision</h3>
            <p>
              To create a world where hospitals don't struggle to get good
              quality, affordable and reliable medical supplies
            </p>
          </div>
        </div>

        <div
          className={`flip-container ${isFlipped ? "flip" : ""}`}
          onClick={handleCardClick}
        >
          <div className="flipper">
            <div className="front">
              <h3>Our Mission</h3>
              <p>
                Transform the global supply chain of medical supplies with
                technology and be the one-stop solution for all medical supplies
              </p>
              <div className="dots-container">
                <div className={`dot ${!isFlipped ? "active" : ""}`}></div>
                <div className={`dot ${isFlipped ? "active" : ""}`}></div>
              </div>
            </div>
            <div className="back">
              <h3>Our Vision</h3>
              <p>
                To create a world where hospitals don't struggle to get good
                quality, affordable and reliable medical supplies
              </p>
              <div className="dots-container">
                <div className={`dot ${!isFlipped ? "active" : ""}`}></div>
                <div className={`dot ${isFlipped ? "active" : ""}`}></div>
              </div>
            </div>
          </div>
        </div>

        <div className="about-us-customers">
          <div>
            <h3>Our valued Investors</h3>
          </div>
          <div className="investors-logo">
            <a
              href="https://www.saisoncapital.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="saison" alt="saison" src={saison} />
            </a>
            <a
              href="https://www.beenext.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="beenext" alt="beenext" src={beenext} />
            </a>
            <a
              href="https://www.titancapital.vc/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="titan" alt="titan" src={titan} />
            </a>
          </div>
          <div className="portfolio">
            <div className="portfolio-heading">
              <p>Some Notable investments by our Investors</p>
            </div>

            <div className="portfolio-images">
              <img src={mamaearth} alt="mamaearth" className="mamaearth" />
              <img src={mpl} alt="mpl" className="mpl" />
              <img
                src={urbancompany}
                alt="urbancompany"
                className="urbancompany"
              />
              <img src={park} alt="park" className="park" />
              <img src={milkbasket} alt="milkbasket" className="milkbasket" />
              <img src={bharatpe} alt="bharatpe" className="bharatpe" />
              <img src={housing} alt="housing" className="housing" />
              <img src={ola} alt="ola" className="ola" />
            </div>
          </div>

        </div>
      </div>
    </Fragment>
  );
};

export default AboutUs;
