import React, { useState, useEffect } from "react";
import { ReactComponent as WhatsappIcon } from "../../../icons/whatsapplogo.svg";
import { ReactComponent as WhatsappOutlinedIcon } from "../../../icons/whatsappOutlined.svg";
import { ReactComponent as WhatsappCloseIcon } from "../../../icons/whatsappClose.svg";
import { useLocation } from "react-router-dom";
import SendIcon from "@mui/icons-material/Send";
import "./Whatsapp.css";

const Whatsapp = () => {
  const [openWhatsappChat, setOpenWhatsappChat] = useState(false);
  const [hasAnimationOccurred, setHasAnimationOccurred] = useState(false);
  const [showNotif, setShowNotif] = useState(false);

  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    // Enable the icon animation after the initial delay
    const timer = setTimeout(() => {
      setHasAnimationOccurred(true);
    }, 5000);

    const notifTimer = setTimeout(() => {
      setShowNotif(true);
    }, 4000);

    return () => {
      clearTimeout(timer);
      clearTimeout(notifTimer);
    };
  }, []);

  return (
    <>
      {openWhatsappChat ? (
        <div className="chat-container">
          <div className="chat-header">
            <WhatsappOutlinedIcon className="whatsapp-outlined" />
            <span>WhatsApp</span>
            <WhatsappCloseIcon
              className="chat-close"
              onClick={() => setOpenWhatsappChat(false)}
            />
          </div>
          <div className="chat-body">
            <p>
              Hi there! <br />
              We are here to help you 24x7. Just drop a message below and get
              immediate reply!
            </p>

            <a
              style={{ textDecoration: "none" }}
              // href="https://web.whatsapp.com/send?phone=919871225779"
              href="https://wa.me/919871225779"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="chat-button">
                Open Chat{" "}
                <SendIcon
                  className="send-icon"
                  style={{
                    width: window.innerWidth <= 768 ? "14px" : "",
                    height: window.innerWidth <= 768 ? "14px" : "",
                  }}
                />
              </button>
            </a>
          </div>
        </div>
      ) : hasAnimationOccurred ? (
        <div className="whatsapp-container">
          <WhatsappIcon
            className={`whatsapp-icon ${
              pathname === "/products" ? "on-plp" : ""
            }`}
            onClick={() => {
              setOpenWhatsappChat(true);
            }}
          />
          {/* {showNotif && ( */}
          <div
            className={`whatsapp-notif ${
              pathname === "/products" ? "on-plp-notif" : ""
            }`}
          >
            1
          </div>
          {/* )} */}
        </div>
      ) : (
        <div className="whatsapp-container">
          <WhatsappIcon
            className={`whatsapp-icon appear ${
              pathname === "/products" ? "on-plp" : ""
            }`}
            onClick={() => {
              setOpenWhatsappChat(true);
            }}
          />
          {showNotif && (
            <div
              className={`whatsapp-notif appear2${
                pathname === "/products" ? "on-plp-notif" : ""
              }`}
            >
              1
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Whatsapp;
