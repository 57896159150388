import React from "react";
import "./BlogCard.css";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

const BlogCard = ({ blog }) => {
  const location = useLocation();
  const { pathname } = location;

  return (
    <Link to={`/blogs/${blog.id}`}>
      <div
        className={`blog-card ${
          pathname.includes("/blogs/") ? "rec-card" : ""
        }`}
      >
        <div className="blog-card-img-container">
          <img
            src={blog.blogImageLP}
            alt="Blog Post"
            className="blog-card-img"
          />
        </div>
        <div className="blog-card-text">
          <div className="blog-card-s1">
            <p>{blog.blogDate}</p>
            <span className="readTime">{blog.blogReadTime} min read</span>
          </div>
          <h2>
            {window.innerWidth <= 768
              ? blog.blogHeadingLP.slice(0, 42) + "..."
              : blog.blogHeadingLP.slice(0, 50) + "..."}
          </h2>
          <p className="blog-content">
            {window.innerWidth <= 768
              ? blog.blogIntro.slice(0, 60) + "..."
              : blog.blogIntro.slice(0, 140) + "....."}
          </p>
          <div className="blog-card-s2">
            <button className="read-more">Read more</button>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default BlogCard;
