import React from "react";
import "./CartItemCardPLP.css";
import { Link } from "react-router-dom";

const CartItemCardPLP = ({ item }) => {
  return (
    <Link to={`/product/${item.product}`}>
      <div className="CartItemCard-plp">
        <img src={item.image} alt="ssa" />
        <p>{item.name}</p>
      </div>
    </Link>
  );
};

export default CartItemCardPLP;
