export const NEW_SELLFORM_REQUEST = "NEW_SELLFORM_REQUEST";
export const NEW_SELLFORM_SUCCESS = "NEW_SELLFORM_SUCCESS";
export const NEW_SELLFORM_RESET = "NEW_SELLFORM_RESET";
export const NEW_SELLFORM_FAIL = "NEW_SELLFORM_FAIL";

export const NEW_LISTFORM_REQUEST = "NEW_LISTFORM_REQUEST";
export const NEW_LISTFORM_SUCCESS = "NEW_LISTFORM_SUCCESS";
export const NEW_LISTFORM_RESET = "NEW_LISTFORM_RESET";
export const NEW_LISTFORM_FAIL = "NEW_LISTFORM_FAIL";

export const NEW_FINANCEFORM_REQUEST = "NEW_FINANCEFORM_REQUEST";
export const NEW_FINANCEFORM_SUCCESS = "NEW_FINANCEFORM_SUCCESS";
export const NEW_FINANCEFORM_RESET = "NEW_FINANCEFORM_RESET";
export const NEW_FINANCEFORM_FAIL = "NEW_FINANCEFORM_FAIL";

export const NEW_SERVICEFORM_REQUEST = "NEW_SERVICEFORM_REQUEST";
export const NEW_SERVICEFORM_SUCCESS = "NEW_SERVICEFORM_SUCCESS";
export const NEW_SERVICEFORM_RESET = "NEW_SERVICEFORM_RESET";
export const NEW_SERVICEFORM_FAIL = "NEW_SERVICEFORM_FAIL";

export const NEW_CONTACTUS_REQUEST = "NEW_CONTACTUS_REQUEST";
export const NEW_CONTACTUS_SUCCESS = "NEW_CONTACTUS_SUCCESS";
export const NEW_CONTACTUS_RESET = "NEW_CONTACTUS_RESET";
export const NEW_CONTACTUS_FAIL = "NEW_CONTACTUS_FAIL";

export const NEW_REFERRAL_REQUEST = "NEW_REFERRAL_REQUEST";
export const NEW_REFERRAL_SUCCESS = "NEW_REFERRAL_SUCCESS";
export const NEW_REFERRAL_RESET = "NEW_REFERRAL_RESET";
export const NEW_REFERRAL_FAIL = "NEW_REFERRAL_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";