import React, { Fragment, useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createContactUsForm, clearErrors } from "../../actions/formAction";
import { NEW_CONTACTUS_RESET } from "../../constants/formsConstants";
import { useHistory } from "react-router-dom";
import "./BlogDetailPage.css";
import Loader from "../layout/Loader/Loader";
import MetaData from "../layout/MetaData";
import Dialog from "@mui/material/Dialog";
import { DialogContent } from "@mui/material";
import Drawer from "@material-ui/core/Drawer";
import { makeStyles } from "@mui/styles";
import { ReactComponent as SearchIcon } from "../../icons/searchIcon.svg";
import { ReactComponent as DropdownArrow } from "../../icons/dropdownBlogs.svg";
import { ReactComponent as InstaIcon } from "../../icons/instaBlog.svg";
import { ReactComponent as ShareIcon } from "../../icons/shareIcon.svg";
import { ReactComponent as LikeIcon } from "../../icons/likeIcon.svg";
import { ReactComponent as LikeFilledIcon } from "../../icons/likeColorIcon.svg";
import { ReactComponent as SwipeLeft } from "../../icons/swipeleftIcon.svg";
import { ReactComponent as SwipeRight } from "../../icons/swiperightIcon.svg";
import { ReactComponent as CrossIcon } from "../../icons/cross.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BlogCategoryList from "./BlogCategoryList.json";
import BlogData from "./BlogCardData.json";
import BlogCard from "./BlogCard";

const useStyles = makeStyles((theme) => ({
  paper: {
    top: "30vw !important",
    borderRadius: "5px 0px 0px 5px",
    width: "80vw",
  },
}));

const BlogDetailPage = ({ match }) => {
  const classes = useStyles();
  const [isFirstSlide, setIsFirstSlide] = useState(true);
  const [isLastSlide, setIsLastSlide] = useState(false);
  const [openCategoriesDialog, setOpenCategoriesDialog] = useState(false);
  const [openCategoriesDrawer, setOpenCategoriesDrawer] = useState(false);
  const [selectedCatsBlog, setSelectedCatsBlog] = useState([]);
  const [isLiked, setIsLiked] = useState(false);

  const id = match.params.id;

  const blog = BlogData.find((blogdata) => blogdata.id == id);
  console.log(blog);

  const CustomNextArrow = (props) => (
    <button
      {...props}
      className="custom-arrow-blog next-arrow-blogs"
      style={{ right: window.innerWidth <= 768 ? "-5.4vw" : "-1vw" }}
    >
      <SwipeRight className="swipe-icons-blogs" style={{ width: "26px" }} />
    </button>
  );

  const CustomPrevArrow = (props) => (
    <button
      {...props}
      className="custom-arrow-blog prev-arrow-blogs"
      style={{ left: window.innerWidth <= 768 ? "-6vw" : "-2.8vw" }}
    >
      <SwipeLeft className="swipe-icons-blogs" style={{ width: "26px" }} />
    </button>
  );

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: window.innerWidth <= 768 ? 2 : 3,
    slidesToScroll: 1,
    initialSlide: 0,
    beforeChange: (current, next) => {
      setIsFirstSlide(next === 0);
      setIsLastSlide(
        next ===
          (window.innerWidth <= 768 ? BlogData.length - 3 : BlogData.length - 4)
      );
    },
    nextArrow: isLastSlide ? null : <CustomNextArrow />,
    prevArrow: isFirstSlide ? null : <CustomPrevArrow />,
  };
  const handleCheckboxChange = (catName) => {
    if (selectedCatsBlog.includes(catName)) {
      setSelectedCatsBlog(selectedCatsBlog.filter((cat) => cat !== catName));
    } else {
      setSelectedCatsBlog([...selectedCatsBlog, catName]);
    }
  };

  const handleCategories = () => {
    if (window.innerWidth <= 768)
      setOpenCategoriesDrawer(!openCategoriesDrawer);
    else setOpenCategoriesDialog(!openCategoriesDialog);
  };

  const handleLike = () => {
    setIsLiked(!isLiked);
  };
  const [contactUsFormData, setContactUsFormData] = useState({
    fnameContactUs: "",
    mobileNumberContactUs: "",
    emailContactUs: "",
    cityContactUs: "",
    ContactUsInfo: "",
    // Add more form fields as needed
  });

  const [isContactFormValid, setIsContactFormValid] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const { loading, error, success } = useSelector((state) => state.contact);

  const handleContactUsInputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const pattern = /^[6-9][0-9]{0,20}$/;

    if (name === "mobileNumberContactUs" && value && !pattern.test(value)) {
      // If the input value does not match the pattern, do not update the state
      e.target.value = "";
      // setPhoneError(true);
    } else {
      // If the input value matches the pattern, update the state
      e.target.value = value;
      // setPhoneError(false);
    }
    if (name === "mobileNumberContactUs" && value.length >= 10) {
      const truncatedValueF = value.slice(0, 10);
      e.target.value = truncatedValueF;
      setContactUsFormData((prevData) => ({
        ...prevData,
        [name]: truncatedValueF,
      }));
    } else {
      setContactUsFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const validateContactForm = useCallback(() => {
    const { fnameContactUs, mobileNumberContactUs } = contactUsFormData;
    const requiredFields = [fnameContactUs, mobileNumberContactUs];

    return (
      requiredFields.every((field) => field.trim() !== "") &&
      mobileNumberContactUs.length === 10
    );
  }, [contactUsFormData]);

  // Update form validity whenever the form data changes
  useEffect(() => {
    if (error) {
      dispatch(clearErrors());
    }

    if (success) {
      dispatch({ type: NEW_CONTACTUS_RESET });
    }
    setIsContactFormValid(validateContactForm());
  }, [contactUsFormData, dispatch, error, success, validateContactForm]);

  const handleContactUsSubmit = (e) => {
    e.preventDefault();

    if (validateContactForm) {
      const myForm = new FormData();

      myForm.set("name", contactUsFormData.fnameContactUs);
      myForm.set("phone", contactUsFormData.mobileNumberContactUs);
      myForm.set("email", contactUsFormData.emailContactUs);
      myForm.set("city", contactUsFormData.cityContactUs);
      myForm.set("info", contactUsFormData.ContactUsInfo);

      dispatch(createContactUsForm(myForm)).then(() => {
        history.push("/formSubmit");
      });
    } else {
      alert("Form not valid");
    }
  };

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <MetaData title={`Blogs - Zoplar`} />
          <div className="blog-details-page">
            <div className="browse-section-blogs">
              <div className="search-bar-blogs">
                <SearchIcon />
                <input
                  className="search-blogs"
                  type="text"
                  placeholder="Search Blogs"
                  //   value={searchProduct}
                  //   onChange={(e) => {
                  //     handleValueChange(e.target.value);
                  //   }}
                  //   onKeyUp={(e) => {
                  //     handleEnterValue(searchProduct, e);
                  //   }}
                />
              </div>
              <button onClick={handleCategories} className="category-blogs">
                <span>Category</span>
                <DropdownArrow className="dropdown-blogs-icon" />
              </button>
              <Dialog
                open={openCategoriesDialog}
                onClose={handleCategories}
                sx={{ top: "-230px", left: "57%", cursor: "pointer" }}
              >
                <DialogContent sx={{ width: "23vw", padding: " 0px 0px" }}>
                  <div>
                    <ul className="category-list-blogs">
                      {BlogCategoryList.map((item) => (
                        <li
                          key={item.id}
                          onClick={() =>
                            handleCheckboxChange(item.categoryName)
                          }
                        >
                          <input
                            type="checkbox"
                            style={{ marginRight: "13px", borderRadius: "3px" }}
                            checked={selectedCatsBlog.includes(
                              item.categoryName
                            )}
                            //onClick={() => handleCheckboxChange(item.categoryName)}
                            readOnly
                          />
                          {item.categoryName}
                        </li>
                      ))}
                    </ul>
                  </div>
                </DialogContent>
              </Dialog>
              <Drawer
                open={openCategoriesDrawer}
                anchor="right"
                onClose={handleCategories}
                classes={{ paper: classes.paper }}
              >
                <div className="categories-drawer">
                  <h5>Categories Filter</h5>
                  <button
                    className="close-filter-blog"
                    onClick={handleCategories}
                  >
                    <CrossIcon />
                  </button>
                  <div className="f-line-blogs"></div>
                  <ul className="category-list-blogs">
                    {BlogCategoryList.map((item) => (
                      <li
                        key={item.id}
                        onClick={() => handleCheckboxChange(item.categoryName)}
                      >
                        <input
                          type="checkbox"
                          style={{ marginRight: "13px" }}
                          checked={selectedCatsBlog.includes(item.categoryName)}
                          //onClick={() => handleCheckboxChange(item.categoryName)}
                          readOnly
                        />
                        {item.categoryName}
                      </li>
                    ))}
                  </ul>
                </div>
              </Drawer>
            </div>
            <div className="blog-box">
              <div className="blog-heading">
                <h1>{blog.detailPageHeading}</h1>
              </div>
              <div className="blog-socials">
                <a
                  href="https://www.instagram.com/_zoplar_/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <InstaIcon className="blog-icons" />
                </a>
                <ShareIcon className="blog-icons share-icon" />
                <span className="socials-text">Found this helpful</span>
                {isLiked ? (
                  <LikeFilledIcon
                    onClick={handleLike}
                    className="blog-icons like-icon"
                  />
                ) : (
                  <LikeIcon
                    onClick={handleLike}
                    className="blog-icons like-icon"
                  />
                )}
                {/* <span className="like-count">{blog.blogLikeCount}</span> */}
              </div>
              <div className="blog-post-img-container">
                <img
                  src={blog.bannerImage.blogImageDP}
                  alt="blog-post"
                  className="blog-post-img"
                />
                <span>{blog.bannerImage.dpi_desc}</span>
              </div>
              <div className="blog-content-main">
                <div className="blog-intro">{blog.blogIntro}</div>
                <div className="blog-subsections">
                  {blog.blogSubSections.map((item, index) => {
                    return (
                      <div key={item.id}>
                        <h2>
                          {index + 1}. {item.blogSubHeading} :
                        </h2>
                        <p>{item.blogSubContent}</p>
                        {/* {item.blogSubImages && item.blogSubImages.map((img) => {
                          return (
                            <div key={img.id} className="blog-post-img-container">
                              <img
                                className="blog-post-img"
                                src={img.subImageurl}
                                alt="subsection"
                              />
                            </div>
                          );
                        })} */}
                      </div>
                    );
                  })}
                  <h2>Conclusion:</h2>
                  <p>{blog.blogConcl}</p>
                </div>
              </div>
            </div>
            <div className="form-blog">
              <h1>Know More</h1>
              <form onSubmit={handleContactUsSubmit}>
                <div className="basic-form-blog">
                  <input
                    type="text"
                    className="form-name-blog"
                    name="fnameContactUs"
                    placeholder="Full Name"
                    onChange={handleContactUsInputChange}
                    required
                  ></input>

                  <input
                    className="mobile-number2-blog"
                    type="number"
                    name="mobileNumberContactUs"
                    placeholder="Mobile number"
                    onChange={handleContactUsInputChange}
                    required
                  ></input>

                  <input
                    className="form-email-blog"
                    type="text"
                    name="emailContactUs"
                    placeholder="Email"
                    onChange={handleContactUsInputChange}
                  ></input>

                  <input
                    type="text"
                    className="form-city-blog"
                    name="cityContactUs"
                    placeholder="Enter Your City"
                    onChange={handleContactUsInputChange}
                  ></input>

                  <textarea
                    className="blog-info"
                    type="text"
                    name="ContactUsInfo"
                    placeholder="Write your query/ question here"
                    rows="4"
                    onChange={handleContactUsInputChange}
                  ></textarea>
                  <div>
                    <button
                      type="submit"
                      disabled={!isContactFormValid}
                      className={
                        isContactFormValid
                          ? "blog-active-sell"
                          : "blog-disabled-sell"
                      }
                    >
                      {" "}
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
            {BlogData &&
              BlogData.map((blog) => {
                return (
                  blog.id != id && (
                    <div className="blog-recs">
                      <h1>Recommended for you</h1>
                      {window.innerWidth <= 768 ? (
                        <div
                          className="f-line-blogs"
                          style={{ marginTop: "0%", marginBottom: "4%" }}
                        ></div>
                      ) : (
                        ""
                      )}
                      <div className="rec-panel-cont">
                        <Slider {...settings} className="rec-panel">
                          {BlogData &&
                            BlogData.map((blog) => {
                              return (
                                blog.id != id && (
                                  <BlogCard key={blog.id} blog={blog} />
                                )
                              );
                            })}
                        </Slider>
                      </div>
                    </div>
                  )
                );
              })}

            <div className="disclaimer">
              <p>
                <span>Disclaimer : </span>The content and details presented on
                this website and its blogs are derived from publicly available
                secondary sources. It is presented "as is," without any
                assurance of completeness, accuracy, or timeliness. Zoplar
                disclaims responsibility for any errors or omissions in the
                information provided and is not liable for any outcomes
                resulting from its use. Readers are advised to independently
                verify all necessary information.
              </p>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default BlogDetailPage;
