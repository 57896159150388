import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useAlert } from "react-alert";
import "./PHeader.css";
import { ReactComponent as ProfileIcon } from "../../../icons/phoneprofile.svg";
import { ReactComponent as ProfileBubblePhone } from "../../../icons/profileBubblePhone.svg";
import { ReactComponent as CatLink } from "../../../icons/categoryLinkIcon.svg";
import { ReactComponent as HamburgerMenu } from "../../../icons/hamburgerMenuIcon.svg";
import { ReactComponent as CHamburgerMenu } from "../../../icons/coloredHamburgerMenu.svg";
import LoginPopup from "../../User/LoginPopup";
import { logout } from "../../../actions/userAction";
import { emptyCart } from "../../../actions/cartAction";

const PHeader = () => {
  const { isAuthenticated } = useSelector((state) => state.user);
  const { user } = useSelector((state) => state.user);
  const dropdownRef = useRef(null);
  const history = useHistory();
  const alert = useAlert();
  const dispatch = useDispatch();

  const Logo =
    "https://zoplardatabase.s3.ap-southeast-2.amazonaws.com/pageimages/logo.png";

  const [openPopup, setOpenPopup] = useState(false);
  const [openProfileBubble, setOpenProfileBubble] = useState(false);
  const onClose = () => {
    setOpenPopup(false);
  };

  function handleProfileClick() {
    if (isAuthenticated) {
      // history.push("/orders");
      setOpenProfileBubble(!openProfileBubble);
    } else {
      setOpenPopup(true);
    }
  }
  function logoutUser() {
    dispatch(logout());
    dispatch(emptyCart());
    alert.success("Logout Successfully");
  }

  function handleTrackClick() {
    if (isAuthenticated) {
      history.push("/orders");
    } else {
      setOpenPopup(true);
    }
  }

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  const closeDropdown = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        isOpen &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !event.target.classList.contains("mobile-header")
      ) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="pheader">
      <div className="mobile-header-logo">
        <a href="/">
          {" "}
          <img src={Logo} alt="zoplarLogo" />{" "}
        </a>
      </div>

      <button
        className={`login-button ${isAuthenticated ? "name-visible" : ""}`}
        onClick={() => handleProfileClick()}
      >
        <ProfileIcon style={{ height: "13px", width: "13px" }} />
        {isAuthenticated
          ? user.name.length > 8
            ? user.name.indexOf(" ") > 8
              ? user.name.slice(0, 8) + "..."
              : user.name.slice(0, user.name.indexOf(" "))
            : user.name
          : "Login"}
        {openProfileBubble && (
          <div className="profile-bubble-phone">
            <ProfileBubblePhone className="bubble-phone" />
            <div>
              <p className="pb-name-phone">
                {user.name.length > 16
                  ? user.name.slice(0, 11) + ".."
                  : user.name}
              </p>
              <p className="pb-num-phone">{user.phoneNumber}</p>
              <div className="pb-buttons-phone">
                <button className="pb-logout-phone" onClick={logoutUser}>
                  Logout
                </button>
              </div>
            </div>
          </div>
        )}
      </button>

      <div className="mobile-header" ref={dropdownRef}>
        {isOpen ? (
          <CHamburgerMenu className="menu-button" onClick={toggleDropdown} />
        ) : (
          <HamburgerMenu className="menu-button" onClick={toggleDropdown} />
        )}

        {isOpen && (
          <div className="dropdown-header">
            <ul>
              <li
                onClick={() => {
                  closeDropdown();
                  handleTrackClick();
                }}
              >
                Track Quotation
                <CatLink className="phonecatlink" />
              </li>
              <li
                onClick={() => {
                  closeDropdown();
                  history.push("/finance");
                }}
              >
                Finance Options
                <CatLink className="phonecatlink" />
              </li>
              <li
                onClick={() => {
                  closeDropdown();
                  history.push("/service");
                }}
              >
                Service
                <CatLink className="phonecatlink" />
              </li>
              <li
                onClick={() => {
                  closeDropdown();
                  history.push("/listProducts");
                }}
              >
                List your Products
                <CatLink className="phonecatlink" />
              </li>
              <li
                onClick={() => {
                  closeDropdown();
                  history.push("/contactus");
                }}
              >
                Contact Us
                <CatLink className="phonecatlink" />
              </li>
              <li
                onClick={() => {
                  closeDropdown();
                  history.push("/referral");
                }}
              >
                Refer & Earn
                <CatLink className="phonecatlink" />
              </li>
              <li
                onClick={() => {
                  closeDropdown();
                  history.push("/aboutus");
                }}
              >
                About Us
                <CatLink className="phonecatlink" />
              </li>
              <li
                onClick={() => {
                  closeDropdown();
                  history.push("/blogs");
                }}
              >
                Blogs
                <CatLink className="phonecatlink" />
              </li>
              <li
                onClick={() => {
                  closeDropdown();
                  history.push("/privacy");
                }}
              >
                Privacy Policy
                <CatLink className="phonecatlink" />
              </li>
              <li
                onClick={() => {
                  closeDropdown();
                  history.push("/TnC");
                }}
              >
                Terms & Conditions
                <CatLink className="phonecatlink" />
              </li>
            </ul>
            {/* </div> */}
          </div>
        )}
      </div>
      {openPopup && (
        <LoginPopup
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          onClose={onClose}
        />
      )}
    </div>
  );
};

export default PHeader;
