import React, { Fragment, useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import { DialogContent } from "@mui/material";
import LoginImg from "../../images/loginImg.jpg";
import "./LoginPopup.css";
import Loader from "../layout/Loader/Loader";
import { registerWithOtp } from "../../actions/userAction";
//import { useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { ReactComponent as DropdownIcon } from "../../icons/occupDropdown.svg";
import CloseIcon from "@mui/icons-material/Close";

const Register = (props) => {
  const { openPopup2, setOpenPopup2, onCloseReg } = props;

  //const LoginImg = "https://zoplardatabase.s3.ap-southeast-2.amazonaws.com/pageimages/login_img.jpg"

  const { loading, isAuthenticated, phoneNumber } = useSelector(
    (state) => state.user
  );

  const dispatch = useDispatch();
  const alert = useAlert();
  const Text = () => {
    return <span style={{ color: window.innerWidth <= 768 ? "#A59F9F":"#919191" }}>Doctor</span>;
  };
  const [openOccDropDown, setOpenOccDropDown] = useState(false);
  const [selectedOptionOcc, setSelectedOptionOcc] = useState(<Text/>);

  const [formData, setFormData] = useState({
    name: "",
    emailRegister: "",
    selectedOption: "Doctor",
    city: "",
    phoneNumber: phoneNumber,
  });

  
  const selectOcc = (value) => {
    setSelectedOptionOcc(value);
    setOpenOccDropDown(false);
    setFormData((prevData) => ({
      ...prevData,
      selectedOption: value,
    }));
  };

  // useEffect(() => {

  //   if (location.pathname === '/registerOtp') {

  //     setOpenPopup2(true);

  //   } else {

  //     setOpenPopup2(false);
  //   }
  // }, [location.pathname, setOpenPopup2]);

  const [isFormValid, setIsFormValid] = useState(false);

  // Function to update form data
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Validation function
  const validateForm = () => {
    const { phoneNumber, name } = formData;
    return (
      phoneNumber &&
      name.trim() !== "" 
    );
  };

  // Update form validity whenever the form data changes
  useEffect(() => {

    if(isAuthenticated)
    {
      setOpenPopup2(false);
    }
    setIsFormValid(validateForm());
  }, [formData,isAuthenticated,setOpenPopup2]);

  // Form submission handler
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm) {
      // Perform form submission logic
      //console.log("Form submitted:", formData);

      dispatch(registerWithOtp(formData));
      // onCloseReg();
    } else {
      alert.error("Fill all details");
    }
  };

  const Logo =
  "https://zoplardatabase.s3.ap-southeast-2.amazonaws.com/pageimages/logo.png";

  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>

          {isDesktop && (
            <Dialog open={openPopup2} maxWidth="md">
            <DialogContent sx={{ padding: 0 }}>
              <div className="login-signup">
                <div className="login-img" >
                  <img src={LoginImg} alt="some img" />
                </div>
                <div className="register-container">
                <button className="close-button" onClick={onCloseReg}>
                <CloseIcon style={{ color: "#FFFFFF" }} />
              </button>
                <form onSubmit={handleSubmit}>
                  <div className="register-text">
                 
                    <h1>Register</h1>
                    <p>Name<span style={{ color: "#D20000" }}>*</span></p>
                    <div className="name-field">
                      <input
                        className="name-register"
                        type="text"
                        placeholder="Enter Name"
                        name="name"
                        onChange={handleInputChange}
                        required
                      ></input>
                    </div>
                    <div className="second-field">
                      {/* <div>
                        <p>Occupation</p>
                        <select
                          name="selectedOption"
                          value={formData.selectedOption}
                          onChange={handleInputChange}
                        >
                          <option value="Doctor">Doctor</option>
                          <option value="Vendor">Vendor</option>
                          <option value="Distributor">Distributor</option>
                          <option value="Others">Others</option>
                          
                        </select>
                      </div> */}
                      <div>
                        <p>Occupation</p>
                        <div
                          className="dropdown-occ"
                          onClick={() => {
                            setOpenOccDropDown(!openOccDropDown);
                          }}
                        >
                          <div>
                            <p style={{ color: "black" }}>
                              {selectedOptionOcc}
                            </p>
                            {openOccDropDown && (
                              <ul>
                                <li
                                  onClick={() => {
                                    selectOcc("Doctor");
                                  }}
                                >
                                  Doctor
                                </li>
                                <li
                                  onClick={() => {
                                    selectOcc("Hospital");
                                  }}
                                >
                                  Hospital
                                </li>
                                <li
                                  onClick={() => {
                                    selectOcc("Distributor");
                                  }}
                                >
                                  Distributor
                                </li>
                                <li
                                  onClick={() => {
                                    selectOcc("Others");
                                  }}
                                >
                                  Others
                                </li>
                              </ul>
                            )}
                            <DropdownIcon
                              className="dropdown-icon-occ"
                              onClick={() =>
                                setOpenOccDropDown(!openOccDropDown)
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div>
                        <p>Email ID</p>
                        <input
                          className="name"
                          style={{ width: "100%" }}
                          type="text"
                          name="emailRegister"
                          placeholder="Enter Email"
                          onChange={handleInputChange}
                          
                        ></input>
                      </div>
                    </div>
                    <p>
                      City<span style={{ color: "#D20000" }}>*</span>
                    </p>
                    <input
                      className="name"
                      type="text"
                      name="city"
                      onChange={handleInputChange}
                      required
                    ></input>
                    <button
                      type="submit"
                      className={
                        isFormValid ? "r-active-button" : "r-disabled-button"
                      }
                      disabled={!isFormValid}
                    >
                      Submit
                    </button>
                  </div>
                </form>
                </div>
              </div>
            </DialogContent>
          </Dialog>

          )}

      <div className="full-screen-popup">
        {!isDesktop &&(

          <div className="login-signup">
          {/* <div className="login-img">
            <img src={LoginImg} alt="some img" />
          </div> */}
          <div className="register-container">
          <button className="close-button" onClick={onCloseReg}>
          <CloseIcon style={{ color: "#FFFFFF" }} />
          </button>
          <form onSubmit={handleSubmit}>
            <div className="register-text">
              <img src={Logo} alt="Zoplar logo"/>
              <h1>Register</h1>
              {/* <p>Name<span style={{ color: "#D20000" }}>*</span></p> */}
              <div className="name-field">
                <input
                  className="name-register"
                  type="text"
                  placeholder="Enter Your Name"
                  name="name"
                  onChange={handleInputChange}
                  required
                ></input>
              </div>
              <div className="second-field">
                {/* <div>
                  <p>Occupation</p>
                  <select
                    name="selectedOption"
                    value={formData.selectedOption}
                    onChange={handleInputChange}
                  >
                    <option value="Doctor">Doctor</option>
                    <option value="Vendor">Vendor</option>
                    <option value="Distributor">Distributor</option>
                    <option value="Others">Others</option>
                    
                  </select>
                </div> */}
                <div>
                  {/* <p>Occupation</p> */}
                  <div
                    className="dropdown-occ"
                    onClick={() => {
                      setOpenOccDropDown(!openOccDropDown);
                    }}
                  >
                    <div>
                      <p style={{ color: "black" , marginTop:"3px"}}>
                        {selectedOptionOcc}
                      </p>
                      {openOccDropDown && (
                        <ul>
                          <li
                            onClick={() => {
                              selectOcc("Doctor");
                            }}
                          >
                            Doctor
                          </li>
                          <li
                            onClick={() => {
                              selectOcc("Hospital");
                            }}
                          >
                            Hospital
                          </li>
                          <li
                            onClick={() => {
                              selectOcc("Distributor");
                            }}
                          >
                            Distributor
                          </li>
                          <li
                            onClick={() => {
                              selectOcc("Others");
                            }}
                          >
                            Others
                          </li>
                        </ul>
                      )}
                      <DropdownIcon
                        className="dropdown-icon-occ"
                        onClick={() =>
                          setOpenOccDropDown(!openOccDropDown)
                        }
                      />
                    </div>
                  </div>
                </div>
                <div>
                  {/* <p>Email ID</p> */}
                  <input
                    className="name"
                    // style={{ width: "100%" }}
                    type="text"
                    name="emailRegister"
                    placeholder="Email"
                    onChange={handleInputChange}
                    
                  ></input>
                </div>
              </div>
              {/* <p>
                City<span style={{ color: "#D20000" }}>*</span>
              </p> */}
              <input
                className="name"
                type="text"
                name="city"
                onChange={handleInputChange}
                required
                placeholder="Enter Your City Name"
              ></input>
              <button
                type="submit"
                className={
                  isFormValid ? "r-active-button" : "r-disabled-button"
                }
                disabled={!isFormValid}
              >
                Submit
              </button>
            </div>
          </form>
          </div>
          </div>

        )}

            {/* {openPopup2 && (
              <Register
                openPopup2={openPopup2}
                setOpenPopup2={setOpenPopup2} 
                onCloseReg={onCloseReg} 
              />
            )} */}

      </div>


          
        </Fragment>
      )}
    </Fragment>
  );
};
export default Register;
