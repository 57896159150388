import React, { Fragment, useState, useEffect } from "react";
import "./CartPLP.css";
import CartItemCardPLP from "./CartItemCardPLP";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  addItemsToCart,
  removeItemsFromCart,
  updateCartItemBundleType,
} from "../../actions/cartAction";
import { createOrder, clearErrors } from "../../actions/orderAction";
import { ReactComponent as MinimizeIcon } from "../../icons/minimizeIcon.svg";
import { ReactComponent as MaximizeIcon } from "../../icons/maximizeIcon.svg";
import { ReactComponent as DustbinIcon } from "../../icons/dustbinIcon.svg";
import { ReactComponent as ArrowDownIcon } from "../../icons/arrowdownIcon.svg";
import { ReactComponent as PlusIcon } from "../../icons/plusIcon.svg";
import { ReactComponent as MinusIcon } from "../../icons/minusIcon.svg";
import LoginPopup from "../User/LoginPopup";

const CartPLP = ({ setShowCart }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { cartItems } = useSelector((state) => state.cart);
  const { success, error: newOrderError } = useSelector(
    (state) => state.newOrder
  );

  const [openDropDown, setOpenDropDown] = useState({});
  const [selectedOption, setSelectedOption] = useState({});
  const [checkoutClicked, setCheckoutClicked] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const onClose = () => {
    setOpenPopup(false);
  };

  const order = {
    orderItems: cartItems,
  };

  const { isAuthenticated, redirectToRegistration } =
    useSelector((state) => state.user);

  const increaseQuantity = (id, quantity) => {
    const newQty = quantity + 1;
    dispatch(addItemsToCart(id, newQty));
  };

  const decreaseQuantity = (id, quantity) => {
    const newQty = quantity - 1;
    if (1 >= quantity) {
      return;
    }
    dispatch(addItemsToCart(id, newQty));
  };

  const deleteCartItems = (id) => {
    dispatch(removeItemsFromCart(id));
    if (cartItems.length === 1) {
      setShowCart(false);
      
    }
  };

  const checkoutHandler = () => {
    setCheckoutClicked(true);
    if (!isAuthenticated) {
      setOpenPopup(true);
    } else {
    dispatch(createOrder(order));
    history.push("/success");
    }
  };

  const handleDropdownChange = (item, option) => {
    setSelectedOption((prevState) => ({
      ...prevState,
      [item.product]: option,
    }));
    dispatch(updateCartItemBundleType(item.product, option));
    setOpenDropDown((prevState) => ({
      ...prevState,
      [item.product]: false,
    }));
  };
  

  useEffect(() => {
    if (newOrderError) {
      alert.error(newOrderError);
      dispatch(clearErrors());
    }

    if (checkoutClicked && isAuthenticated) {
      dispatch(createOrder(order));
      history.push("/success");
    }
  }, [
    dispatch,
    newOrderError,
    alert,
    isAuthenticated,
    redirectToRegistration,
    success,
  ]);

  return (
    <Fragment>
      {cartItems.length === 0 ? (
        <div></div>
      ) : (
        <Fragment>
          <div className="cartPage-container">
            <div className="cartPage">
              <div className="cartHeader">
                <p>Products Added</p>
                <div className="icons-cart">
                  <MinimizeIcon
                    className="minimize-icon"
                    style={{ padding: "1vmax" }}
                    onClick={() => setShowCart(false)}
                  />
                  <MaximizeIcon
                    style={{ height: "15px", width: "15px" }}
                    onClick={() => {history.push("/cart")}}
                  />
                </div>
              </div>
              <div className="cart-line"></div>
              <div className="cart-scroll">
                {cartItems &&
                  cartItems.map((item) => (
                    <div className="cartContainer" key={item.product}>
                      <CartItemCardPLP
                        item={item}
                        deleteCartItems={deleteCartItems}
                      />
                      <div className="cartInput">
                        <div className="cartInputBox">
                          <PlusIcon
                            style={{
                              width: "9px",
                              height: "9px",
                              cursor: "pointer",
                              padding: "0% 4%",
                            }}
                            onClick={() =>
                              increaseQuantity(item.product, item.quantity)
                            }
                          />
                          <input type="number" value={item.quantity} readOnly />
                          <MinusIcon
                            style={{
                              width: "9px",
                              height: "9px",
                              cursor: "pointer",
                              padding: "0% 4%",
                            }}
                            onClick={() =>
                              decreaseQuantity(item.product, item.quantity)
                            }
                          />
                          <div className="vertical-line"></div>
                          <div className="dropdown">
                            <div>
                              <p
                                onClick={() => {
                                  setOpenDropDown((prevState) => ({
                                    ...prevState,
                                    [item.product]: !prevState[item.product],
                                  }));
                                }}
                              >
                                {selectedOption[item.product] ||
                                  item.bundleType}
                              </p>
                              {openDropDown[item.product] && (
                                <ul>
                                  <li
                                    onClick={() =>
                                      handleDropdownChange(item, "Piece")
                                    }
                                  >
                                    Piece
                                  </li>
                                  <li
                                    onClick={() =>
                                      handleDropdownChange(item, "Box")
                                    }
                                  >
                                    Box
                                  </li>
                                  <li
                                    onClick={() =>
                                      handleDropdownChange(item, "Unit")
                                    }
                                  >
                                    Unit
                                  </li>
                                  <li
                                    onClick={() =>
                                      handleDropdownChange(item, "Packet")
                                    }
                                  >
                                    Packet
                                  </li>
                                </ul>
                              )}
                            </div>
                          </div>
                          <ArrowDownIcon
                            className="arrow-down"
                            style={{
                              height: "9px",
                              width: "8px",
                              marginTop: "0",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setOpenDropDown((prevState) => ({
                                ...prevState,
                                [item.product]: !prevState[item.product],
                              }));
                            }}
                          />
                        </div>
                      </div>
                      <span>
                        <DustbinIcon
                          style={{
                            marginTop: "80%",
                            marginRight: "10%",
                            marginLeft: "20%",
                            width: "20px",
                            height: "20px",
                          }}
                          onClick={() => {
                            deleteCartItems(item.product);
                            // handleProductDeletion(item.product);
                          }}
                        />
                      </span>
                    </div>
                  ))}
                <div className="checkOutBtn">
                  <button onClick={checkoutHandler}>Get Quote</button>
                </div>
              </div>
            </div>
          </div>
          {openPopup && (
            <LoginPopup
              openPopup={openPopup} // Custom prop
              setOpenPopup={setOpenPopup} // Custom prop
              onClose={onClose} // Pass the onClose function
            />
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

//Dont get confuse with the variable name GrossProfit

export default CartPLP;
