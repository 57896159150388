import React, { Fragment, useEffect, useState } from "react";
import "./ProductDetails.css";
import { useSelector, useDispatch } from "react-redux";
import {
  clearErrors,
  getProductDetails,
} from "../../actions/productAction";
import Loader from "../layout/Loader/Loader";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import MetaData from "../layout/MetaData";
import { addItemsToCart } from "../../actions/cartAction";
import Refurb from "./AdforRefurb/Refurb";
import Data from "./AboutProd.json";
import CartPLP from "../Cart/CartPLP";
import "./About.css";
import "./License.css";
import "./TermsConditions.css";
import LoginPopup from "../User/LoginPopup";
import "./ProductCard.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DynamicImage from "./DynamicImage";

const ProductDetails = ({ match }) => {
  // const [showLicense, setShowLicense] = useState(false);
  // const [showTC, setShowTC] = useState(false);
  const [showCart, setShowCart] = useState(false);

  const dispatch = useDispatch();
  const alert = useAlert();

  const { product, loading, error, recommendations } = useSelector(
    (state) => state.productDetails
  );

  const { isAuthenticated } = useSelector((state) => state.user);

  const { cartItems } = useSelector((state) => state.cart);
  const history = useHistory();

  //const [quantity, setQuantity] = useState(1);

  const [openPopup, setOpenPopup] = useState(false);
  const onClose = () => {
    setOpenPopup(false);
  };

  const addToCartHandler = () => {
    if (!cartItems.some((item) => item.product === match.params.id)) {
      setShowCart(true);
      if (isAuthenticated) {
        dispatch(addItemsToCart(match.params.id, 1));
        alert.success("Item Added To Cart");
      } else {
        setOpenPopup(true);
      }
    }
  };

  // const handleLicenseClick = () => {
  //   setShowLicense(!showLicense);
  // };
  // const handleTCClick = () => {
  //   setShowTC(!showTC);
  // };
  useEffect(() => {
    if (error) {
      dispatch(clearErrors());
    }

    if (cartItems) {
      setShowCart(true);
    }

    dispatch(getProductDetails(match.params.id));
  }, [dispatch, match.params.id, error, alert]);

  const SampleNextArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="control-btn" onClick={onClick}>
        <button className="next">
          <ChevronRightIcon />
        </button>
      </div>
    );
  };
  const SamplePrevArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="control-btn" onClick={onClick}>
        <button className="prev">
          <ChevronLeftIcon />
        </button>
      </div>
    );
  };
  const defaultSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const mobileSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const settings = {
    ...defaultSettings,

    responsive: [
      {
        breakpoint: 768,
        settings: mobileSettings,
      },
    ],
  };

  const handleRecommendClick = (idex) => {
    history.push(`/product/${idex}`);
  };
  const onMinimize = () => {
    setShowCart(false);
  };
  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <MetaData title={`${product.name} - Zoplar`} />
          <div className="wrapper-pdp">
            <div
              className={`products-details-section ${
                showCart && cartItems.length !== 0 ? "with-cart-pdp" : ""
              }`}
            >
              <div className="ProductDetails">
                <div className="ProductDetails1">
                  <DynamicImage imgs={product.image} />
                </div>
                <div className="product_data">
                  <h2 className="product-heading">{product.name}</h2>
                  <div className="main-details">
                    <p>
                      {product.Brand} <span> </span>
                    </p>
                  </div>
                  <div className="features">
                    <span>{product.Size}</span>
                    <span>{product.Device}</span>
                    <span>Manual</span>
                    <br />
                    <br />
                    <span>{product.category}</span>
                  </div>
                  <div className="line"></div>
                  <div className="detailsBlock-3">
                    <div className="detailsBlock-3-1">
                      <button
                        className={`btn ${
                          cartItems &&
                          cartItems.some(
                            (item) => item.product === match.params.id
                          )
                            ? "btn-added"
                            : "btn-notadded"
                        }`}
                        onClick={addToCartHandler}
                        disabled={
                          cartItems &&
                          cartItems.some(
                            (item) => item.product === match.params.id
                          )
                        }
                      >
                        {cartItems &&
                        cartItems.some(
                          (item) => item.product === match.params.id
                        )
                          ? "Added to cart"
                          : "Request Price"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cart-section-pdp">
                {showCart && (
                  <CartPLP onMinimize={onMinimize} setShowCart={setShowCart} />
                )}
              </div>
            </div>

            <div className="x">
              {Data.map((prodData, index) => (
                <div className="wrapper-about">
                  <div className="prodinfo-container">
                    <p className="prodinfo-heading">About the product: </p>
                    {/* <div className="about-line"></div> */}
                    <div className="prodinfo-content" key={prodData.id}></div>
                    <div className="product-specs">
                      {product.remaining_entries &&
                        product.remaining_entries.map((prodDesc, index) => (
                          <div className="spec" key={index}>
                            <span className="s-name">{prodDesc.value} </span>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* <License /> */}
            {/* add commented code here */}
            {/* <ProductCard /> */}
            <div className="wrapper-carousel-pdp">
              <div className="recommendations">Recommendations</div>
              <Slider {...settings}>
                {recommendations &&
                  recommendations.map((value, index) => (
                    <div>
                      <div className="imageslider-container">
                        <div
                          className="carousel-card"
                          key={value._id}
                          onClick={() => handleRecommendClick(value._id)}
                        >
                          <div className="image-layer">
                            <img
                              className="pdp-product-image"
                              src={value.image[0]}
                              alt={value.name}
                            />
                          </div>
                          <h2 id="pdp-product-name">{value.name}</h2>

                          <h2 id="pdp-product-name-mobile">
                            {value.name.slice(0, 15) +
                              (value.name.length > 8 ? " .." : "")}
                          </h2>
                          <p id="pdp-product-model">
                            Category: {value.category}
                          </p>
                          <div className="addToCart">
                            <button>Add to Cart</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </Slider>
            </div>

            {showCart && cartItems.length > 0 && (
              <div className="mobile-cart-section">
                <div>
                  <p>
                    <span>{cartItems.length}</span>{" "}
                    {cartItems.length === 1 ? "Product" : "Products"} added to
                    cart
                  </p>
                  <button
                    onClick={() => {
                      history.push("/cart");
                    }}
                  >
                    {" "}
                    Show Cart
                  </button>
                </div>
              </div>
            )}

            {openPopup && (
              <LoginPopup
                openPopup={openPopup} // Custom prop
                setOpenPopup={setOpenPopup} // Custom prop
                onClose={onClose}
                sendId2={match.params.id} // Pass the onClose function
              />
            )}
            {/* <ImageCard /> */}
          </div>
          <Refurb />
        </Fragment>
      )}
    </Fragment>
  );
};

export default ProductDetails;
