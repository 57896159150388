import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "./BlogLanding.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Dialog from "@mui/material/Dialog";
import { DialogContent } from "@mui/material";
import Drawer from "@material-ui/core/Drawer";
import { ReactComponent as SearchIcon } from "../../icons/searchIcon.svg";
import { ReactComponent as DropdownArrow } from "../../icons/dropdownBlogs.svg";
import { ReactComponent as SwipeLeft } from "../../icons/swipeleftIcon.svg";
import { ReactComponent as SwipeRight } from "../../icons/swiperightIcon.svg";
import { ReactComponent as CrossIcon } from "../../icons/cross.svg";
import blogCarousel1 from "../../images/blog-carousel.jpeg";
import BlogData from "./BlogCardData.json";
import BlogCategoryList from "./BlogCategoryList.json";
import BlogCard from "./BlogCard";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    top: "30vw !important",
    borderRadius: "5px 0px 0px 5px",
    width: "80vw",
  },
}));

const BlogLanding = () => {
  const classes = useStyles();

  const [isFirstSlide, setIsFirstSlide] = useState(true);
  const [isLastSlide, setIsLastSlide] = useState(false);
  const [openCategoriesDialog, setOpenCategoriesDialog] = useState(false);
  const [openCategoriesDrawer, setOpenCategoriesDrawer] = useState(false);
  const [selectedCatsBlog, setSelectedCatsBlog] = useState([]);

  const handleCheckboxChange = (catName) => {
    if (selectedCatsBlog.includes(catName)) {
      setSelectedCatsBlog(selectedCatsBlog.filter((cat) => cat !== catName));
    } else {
      setSelectedCatsBlog([...selectedCatsBlog, catName]);
    }

    // const categoryToScroll = "X - Ray Machine"; // Replace with your category name
    // if (catName === categoryToScroll) {
    //   // Scroll to the blog cards section
    //   const element = document.getElementById("blog-cards-section");
    //   var headerOffset = 150;
    //   var elementPosition = element.getBoundingClientRect().top;
    //   var offsetPosition = elementPosition - headerOffset;
    //   if (element) {
    //     window.scrollTo({
    //       top: offsetPosition,
    //       behavior: "smooth"
    //  });
    //   }
    // }
  };

  const handleCategories = () => {
    if (window.innerWidth <= 768)
      setOpenCategoriesDrawer(!openCategoriesDrawer);
    else setOpenCategoriesDialog(!openCategoriesDialog);
  };
  const history = useHistory();

  const blogCarouselImgs = [
    {
      id: 1,
      blogimageUrl: blogCarousel1,
      carouselHeading:
        "How Portable X-Ray Machines Are Transforming Healthcare for the Better",
      carouselContent:
        "In the ever-evolving landscape of healthcare, advancements in technology continue to reshape the way we diagnose and treat medical conditions. Portable X-ray machines have emerged as game-changers in this regard. Their ability to provide convenient, high-quality imaging on the go has transformed healthcare delivery, offering numerous benefits to patients and medical professionals alike. In this blog, we will delve into the advantages of portable X-ray machines in healthcare.",
      blogLink: "/blogs/1",
    },
    // {
    //   id: 2,
    //   blogimageUrl: "https://www.gstatic.com/webp/gallery/5.jpg",
    //   carouselHeading: "Lorem ipsum dolor sit amet consectetur adipiscing.",
    //   carouselContent:
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus.",
    //   // blogLink: ""
    // },
    // {
    //   id: 3,
    //   blogimageUrl: "https://www.gstatic.com/webp/gallery/1.jpg",
    //   carouselHeading: "Lorem ipsum dolor sit amet consectetur adipiscing.",
    //   carouselContent:
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, mattis ligula consectetur, ultrices mauris. Maecenas vitae mattis tellus.",
    //   // blogLink: ""
    // },
  ];

  const CustomNextArrow = (props) => (
    <button {...props} className="custom-arrow-blog next-arrow-blogs">
      <SwipeRight className="swipe-icons-blogs" />
    </button>
  );

  const CustomPrevArrow = (props) => (
    <button {...props} className="custom-arrow-blog prev-arrow-blogs">
      <SwipeLeft className="swipe-icons-blogs" />
    </button>
  );

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    beforeChange: (current, next) => {
      setIsFirstSlide(next === 0);
      setIsLastSlide(next === blogCarouselImgs.length - 1);
    },
    nextArrow: isLastSlide ? null : <CustomNextArrow />,
    prevArrow: isFirstSlide ? null : <CustomPrevArrow />,
  };

  return (
    <div className="blog-landing-page">
      <div className="browse-section-blogs">
        <div className="search-bar-blogs">
          <SearchIcon />
          <input
            className="search-blogs"
            type="text"
            placeholder="Search Blogs"
            //   value={searchProduct}
            //   onChange={(e) => {
            //     handleValueChange(e.target.value);
            //   }}
            //   onKeyUp={(e) => {
            //     handleEnterValue(searchProduct, e);
            //   }}
          />
        </div>
        <button onClick={handleCategories} className="category-blogs">
          <span>Category</span>
          <DropdownArrow className="dropdown-blogs-icon" />
        </button>
        <Dialog
          open={openCategoriesDialog}
          onClose={handleCategories}
          sx={{
            top: "-240px",
            left: "57%",
            cursor: "pointer",
          }}
        >
          <DialogContent
            sx={{
              width: "23vw",
              padding: " 0px 0px",
            }}
          >
            <div>
              <ul className="category-list-blogs">
                {BlogCategoryList.map((item) => (
                  <li
                    key={item.id}
                    onClick={() => handleCheckboxChange(item.categoryName)}
                  >
                    <input
                      type="checkbox"
                      style={{ marginRight: "13px", borderRadius: "3px" }}
                      checked={selectedCatsBlog.includes(item.categoryName)}
                      //onClick={() => handleCheckboxChange(item.categoryName)}
                      readOnly
                    />
                    {item.categoryName}
                  </li>
                ))}
              </ul>
            </div>
          </DialogContent>
        </Dialog>
        <Drawer
          open={openCategoriesDrawer}
          anchor="right"
          onClose={handleCategories}
          classes={{ paper: classes.paper }}
        >
          <div className="categories-drawer">
            <h5>Category Filter</h5>
            <button className="close-filter-blog" onClick={handleCategories}>
              <CrossIcon />
            </button>
            <div className="f-line-blogs"></div>
            <ul className="category-list-blogs">
              {BlogCategoryList.map((item) => (
                <li
                  key={item.id}
                  onClick={() => handleCheckboxChange(item.categoryName)}
                >
                  <input
                    type="checkbox"
                    style={{ marginRight: "13px" }}
                    checked={selectedCatsBlog.includes(item.categoryName)}
                    //onClick={() => handleCheckboxChange(item.categoryName)}
                    readOnly
                  />
                  {item.categoryName}
                </li>
              ))}
            </ul>
          </div>
        </Drawer>
      </div>

      <Slider {...settings} className="blog-carousel">
        {blogCarouselImgs.map((image, index) => (
          <div
            key={index}
            className="blog-carousel-image gradient-overlay-blog"
            onClick={() => {
              if (image.blogLink) {
                history.push(image.blogLink);
              }
            }}
          >
            <img
              //   className={image.blogLink ? "image-clickable" : ""}
              src={image.blogimageUrl}
              alt={`Slide ${index + 1}`}
            />
            <div className="blog-carousel-text">
              <h1>{image.carouselHeading}</h1>
              <p>{window.innerWidth <= 768 ? image.carouselContent.slice(0, 120) + "......" : image.carouselContent.slice(0, 200) + "......"}</p>
              <button>Read more</button>
            </div>
          </div>
        ))}
      </Slider>
      <h1 className="latest-blogs">Latest Blog Posts</h1>
      <div id="blog-cards-section" className="blog-cards-display">
        {BlogData &&
          BlogData.map((blog) => {
            return <BlogCard key={blog.id} blog={blog} />;
          })}
      </div>
      {/* <button className="loadmore">Load More</button> */}
    </div>
  );
};

export default BlogLanding;
