import React, { Fragment, useEffect, useState } from "react";
import "./Products.css";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  clearErrors,
  getProduct,
  getAllProdCat,
} from "../../actions/productAction";
import { useLocation } from "react-router-dom";
//import { getCategory } from "../../actions/categoryAction";
import Loader from "../layout/Loader/Loader";
import ProductCard from "../Home/ProductCard";
import { useAlert } from "react-alert";
import MetaData from "../layout/MetaData";
import CloseIcon from "@mui/icons-material/Close";
import CartPLP from "../Cart/CartPLP";
import test from "./test.json";
import { ReactComponent as FilterIcon } from "../../icons/filterIcon.svg";
import { ReactComponent as SearchIcon } from "../../icons/searchIcon.svg";
import { ReactComponent as CrossIcon } from "../../icons/cross.svg";
import LoginPopup from "../User/LoginPopup";
import "intersection-observer";
import { Typewriter } from "react-simple-typewriter";
import Drawer from "@material-ui/core/Drawer";

const Products = ({ match }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const alert = useAlert();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  // const keywordParam = queryParams.get("keyword");
  // const keyword = keywordParam || "";
  const keyword = match.params.keyword;
  const categoryParam = queryParams.get("categoryFromPage");
  const categoryParam2 = queryParams.get("ProductcategoryFromPage");
  const initialCategory = [categoryParam] ? [categoryParam] : [];
  const initialCategory2 = [categoryParam2] ? [categoryParam2] : [];

  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCategories, setSelectedCategories] = useState(initialCategory); // Track selected categories
  const [selectedProdCats, setSelectedProdCats] = useState(initialCategory2); // Track selected product categories
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchProduct, setSearchProduct] = useState("");
  const [searchProdCat, setSearchProdCat] = useState("");
  const [searchBrand, setSearchBrand] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [filteredProdCat, setFilteredProdCat] = useState([]);
  const [filteredBrand, setFilteredBrand] = useState([]);
  const [showProductList, setShowProductList] = useState(true);
  const [showTags, setShowTags] = useState([categoryParam, categoryParam2]);
  const [showCart, setShowCart] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [sendId, setSendId] = useState({});

  const onClose = () => {
    setOpenPopup(false);
  };

  // console.log(categoryParam);
  // console.log(categoryParam2);
  // console.log(initialCategory);
  // console.log(initialCategory2);
  // console.log(showTags);

  const { isAuthenticated } = useSelector((state) => state.user);

  const { products, loading, error, totalPages, filteredProductsCount } =
    useSelector((state) => state.products);

  //console.log(filteredProductsCount)

  // const { categories } = useSelector((state) => state.allCategory);

  const {
    loading: loading2,
    prodcats,
    categories,
    brands,
  } = useSelector((state) => state.allprodCats);

  //const {brands} = useSelector((state) => state.allBrands);

  const { cartItems } = useSelector((state) => state.cart);

  // function showCartClick(path) {
  //   history.push(path);
  // }

  const handleCategoryCheckboxChange = (categoryName) => {
    if (selectedCategories.includes(categoryName)) {
      setSelectedCategories(
        selectedCategories.filter((cat) => cat !== categoryName)
      );
      setCurrentPage(1);
      setShowTags(showTags.filter((tag) => tag !== categoryName));
      setSearchProduct("");
    } else {
      setSelectedCategories([...selectedCategories, categoryName]);
      setShowTags([...showTags, categoryName]);
      setSearchProduct("");
      setCurrentPage(1);
    }
  };

  const handleProdCatCheckboxChange = (prodCatName) => {
    if (selectedProdCats.includes(prodCatName)) {
      setSelectedProdCats(
        selectedProdCats.filter((cat) => cat !== prodCatName)
      );
      setCurrentPage(1);
      setShowTags(showTags.filter((tag) => tag !== prodCatName));
      setSearchProduct("");
    } else {
      setSelectedProdCats([...selectedProdCats, prodCatName]);
      setShowTags([...showTags, prodCatName]);
      setSearchProduct("");
      setCurrentPage(1);
    }
  };

  const handleBrandCheckboxChange = (brandName) => {
    if (selectedBrands.includes(brandName)) {
      setSelectedBrands(selectedBrands.filter((cat) => cat !== brandName));
      setCurrentPage(1);
      setShowTags(showTags.filter((tag) => tag !== brandName));
      setSearchProduct("");
    } else {
      setSelectedBrands([...selectedBrands, brandName]);
      setShowTags([...showTags, brandName]);
      setSearchProduct("");
      setCurrentPage(1);
    }
  };

  const handleStatusCheckboxChange = (status) => {
    if (selectedStatus.includes(status)) {
      setSelectedStatus(selectedStatus.filter((stat) => stat !== status));
      setCurrentPage(1);
      setShowTags(showTags.filter((tag) => tag !== status));
      setSearchProduct("");
    } else {
      setSelectedStatus([...selectedStatus, status]);
      setShowTags([...showTags, status]);
      setCurrentPage(1);
      setSearchProduct("");
    }
  };

  //const [isLoadingMore, setIsLoadingMore] = useState(false);

  const handleScroll = async () => {
    if (window.innerHeight + window.scrollY + 1 >= document.body.offsetHeight) {
      // Set a timeout to change the state of currentPage after 3 seconds
      // setTimeout(() => {
      //   setCurrentPage((prev) => prev + 1);
      // }, 100); // 3000 milliseconds delay
      setCurrentPage((prev) => prev + 1);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (error) {
      dispatch(clearErrors());
    }

    // if (selectedCategories[0] === null) {

    //   selectedCategories.shift();

    // }

    // if (selectedProdCats[0] === null) {

    //   selectedProdCats.shift();
    // }

    if (!totalPages || currentPage <= totalPages) {
      dispatch(
        getProduct(
          keyword,
          currentPage,
          selectedCategories,
          selectedProdCats,
          selectedBrands,
          selectedStatus
        )
      );
    }

    // Set the loading flag back to false when loading is complete
  }, [
    dispatch,
    keyword,
    currentPage,
    selectedCategories,
    selectedProdCats,
    selectedBrands,
    selectedStatus,
    alert,
    error,
  ]);

  useEffect(() => {
    // if (categoryParam !== null) {
    //  // setShowTags(categoryParam);
    //  handleCategoryCheckboxChange(categoryParam)
    // }
    // if (categoryParam2 !==null) {
    //   //setShowTags(categoryParam2);
    //   handleProdCatCheckboxChange(categoryParam2)
    // }

    //dispatch(getCategory());
    dispatch(getAllProdCat());
    // dispatch(getAllBrands());
  }, []);

  const handleResetFilters = () => {
    setSelectedCategories([]); // Reset selected categories
    setSelectedProdCats([]); // Reset selected product categories
    setSelectedBrands([]);
    setSelectedStatus([]);
    setSearchTerm(""); // Reset the search term (if needed)
    setShowTags([]);
    dispatch(getProduct("", 1, [], [], [], [])); // Dispatch the action to get products with no filters
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const removeTag = (indexToRemove, tagName) => {
    setShowTags(showTags.filter((_, index) => index !== indexToRemove));
    if (selectedCategories.includes(tagName)) {
      handleCategoryCheckboxChange(tagName);
    } else if (selectedProdCats.includes(tagName)) {
      handleProdCatCheckboxChange(tagName);
    } else if (selectedBrands.includes(tagName)) {
      handleBrandCheckboxChange(tagName);
    } else if (selectedStatus.includes(tagName)) {
      handleStatusCheckboxChange(tagName);
    }
  };

  const handleValueChange = (value) => {
    setSearchProduct(value);
    const filtered = test.filter((item) =>
      item.name
        .split(" ")
        .some((word) => word.toLowerCase().startsWith(value.toLowerCase()))
    );
    setFilteredProducts(filtered.slice(0, 10));
    setShowProductList(true);
  };

  const handleEnterValue = (value, event) => {
    if (event.key === "Enter") {
      dispatch(getProduct(value, 1, [], [], [], []));
      setShowProductList(false);
    }
  };
  const handleProductClick = (univalue) => {
    dispatch(getProduct(univalue, 1, [], [], [], []));
    setShowProductList(false);
  };

  const handleShowCart = () => {
    setShowCart(true);
  };
  const handleViewCart = () => {
    if (cartItems.length !== 0) setShowCart(true);
  };

  const [isExpanded, setIsExpanded] = useState(false);
  const toggleDiv = () => {
    setIsExpanded(!isExpanded);
  };

  console.log(products)
  return (
    <Fragment>
      {loading || loading2 ? (
        <Loader />
      ) : (
        <Fragment>
          <MetaData title="Products - Zoplar" />
          <div className="wrapper-plp">
            <Drawer open={isExpanded} anchor="left" onClose={toggleDiv}>
              <div className="filterBox-mobile">
                <div className="filters">
                  <FilterIcon />
                  <span className="filter-text">Filters</span>
                  <button className="close-filter-btn" onClick={toggleDiv}>
                    <CrossIcon />
                  </button>
                </div>
                <div className="f-line-prods"></div>
                {/* ----PRODUCT CATEGORIES-------*/}
                <div className="medfield" style={{ paddingBottom: "0" }}>
                  <h2
                    style={{ marginTop: "15px" }}
                    className="medfield-heading"
                  >
                    Product Categories
                  </h2>
                  <form onSubmit={handleSubmit}>
                    <div className="search-field">
                      <SearchIcon />
                      <input
                        className="search-bar"
                        type="text"
                        placeholder="Search product"
                        value={searchProdCat}
                        onChange={(e) => {
                          let term = e.target.value;
                          setSearchProdCat(term);
                          const filtered = prodcats.filter((item) =>
                            item.name
                              .split(" ")
                              .some((word) =>
                                word
                                  .toLowerCase()
                                  .startsWith(term.toLowerCase())
                              )
                          );
                          setFilteredProdCat(filtered);
                        }}
                      />
                    </div>
                    <ul className="categoryBox">
                      {searchProdCat
                        ? filteredProdCat.map((prodcat) => (
                            <li
                              className="category-link"
                              key={prodcat._id}
                              onClick={() =>
                                handleProdCatCheckboxChange(prodcat.name)
                              }
                            >
                              <input
                                type="checkbox"
                                checked={selectedProdCats.includes(
                                  prodcat.name
                                )}
                                readOnly
                              />
                              {prodcat.name.includes("Technical")
                                ? prodcat.name.slice(0, 17)
                                : prodcat.name}
                            </li>
                          ))
                        : prodcats &&
                          prodcats.map((prodcat) => (
                            <li
                              className="category-link"
                              key={prodcat._id}
                              onClick={() =>
                                handleProdCatCheckboxChange(prodcat.name)
                              }
                            >
                              <input
                                type="checkbox"
                                checked={selectedProdCats.includes(
                                  prodcat.name
                                )}
                                readOnly
                              />
                              {prodcat.name.includes("Technical")
                                ? prodcat.name.slice(0, 17)
                                : prodcat.name}
                            </li>
                          ))}
                    </ul>
                  </form>
                </div>
                {/* ----BRANDS-------*/}
                <div className="medfield" style={{ paddingBottom: "0" }}>
                  <h2
                    className="medfield-heading"
                    style={{ marginTop: "15px" }}
                  >
                    Brands
                  </h2>
                  <form onSubmit={handleSubmit}>
                    <div className="search-field">
                      <SearchIcon />
                      <input
                        className="search-bar"
                        type="text"
                        placeholder="Search brand"
                        value={searchBrand}
                        onChange={(e) => {
                          let term = e.target.value;
                          setSearchBrand(term);
                          const filtered = brands.filter((item) =>
                            item.name
                              .split(" ")
                              .some((word) =>
                                word
                                  .toLowerCase()
                                  .startsWith(term.toLowerCase())
                              )
                          );
                          setFilteredBrand(filtered);
                        }}
                      />
                    </div>
                    <ul className="categoryBox">
                      {searchBrand
                        ? filteredBrand &&
                          filteredBrand.map((brand) => (
                            <li
                              className="category-link"
                              key={brand._id}
                              onClick={() =>
                                handleBrandCheckboxChange(brand.name)
                              }
                            >
                              <input
                                type="checkbox"
                                checked={selectedBrands.includes(brand.name)}
                                readOnly
                              />
                              {brand.name}
                            </li>
                          ))
                        : brands &&
                          brands.map((brand) => (
                            <li
                              className="category-link"
                              key={brand._id}
                              onClick={() =>
                                handleBrandCheckboxChange(brand.name)
                              }
                            >
                              <input
                                type="checkbox"
                                checked={selectedBrands.includes(brand.name)}
                                readOnly
                              />
                              {brand.name}
                            </li>
                          ))}
                    </ul>
                  </form>
                </div>
                {/* ----NEW/PRE-OWNED-------*/}
                <div className="device-status">
                  <div
                    className="f-line2"
                    style={{ marginBottom: "15px" }}
                  ></div>
                  <h2 className="medfield-heading">Condition</h2>
                  <ul className="categoryBox">
                    <li
                      className="category-link"
                      onClick={() => handleStatusCheckboxChange("New")}
                    >
                      <input
                        type="checkbox"
                        checked={selectedStatus.includes("New")}
                        readOnly
                      />
                      New
                    </li>
                    <li
                      className="category-link"
                      onClick={() => handleStatusCheckboxChange("Pre-Owned")}
                    >
                      <input
                        type="checkbox"
                        checked={selectedStatus.includes("Pre-Owned")}
                        readOnly
                      />
                      Pre-Owned
                    </li>
                  </ul>
                  <div className="f-line2" style={{ marginTop: "15px" }}></div>
                </div>
                {/* ----MEDICAL FIELDS-------*/}
                <div className="medfield">
                  <h2 className="medfield-heading">Medical Fields</h2>
                  <form onSubmit={handleSubmit}>
                    <div className="search-field">
                      <SearchIcon />
                      <input
                        className="search-bar"
                        type="text"
                        placeholder="Search fields"
                        value={searchTerm}
                        onChange={(e) => {
                          let term = e.target.value;
                          setSearchTerm(term);
                          const filtered = categories.filter((item) =>
                            item.name
                              .split(" ")
                              .some((word) =>
                                word
                                  .toLowerCase()
                                  .startsWith(term.toLowerCase())
                              )
                          );
                          setFilteredItems(filtered);
                        }}
                      />
                    </div>
                    <ul className="categoryBox">
                      {searchTerm
                        ? filteredItems.map((category) => (
                            <li
                              className="category-link"
                              key={category._id}
                              onClick={() =>
                                handleCategoryCheckboxChange(category.name)
                              }
                            >
                              <input
                                type="checkbox"
                                checked={selectedCategories.includes(
                                  category.name
                                )}
                                readOnly
                              />
                              {category.name}
                            </li>
                          ))
                        : categories &&
                          categories.map((category) => (
                            <li
                              className="category-link"
                              key={category._id}
                              onClick={() =>
                                handleCategoryCheckboxChange(category.name)
                              }
                            >
                              <input
                                type="checkbox"
                                checked={selectedCategories.includes(
                                  category.name
                                )}
                                readOnly
                              />
                              {category.name}
                            </li>
                          ))}
                    </ul>
                  </form>
                </div>
                {showTags.some((item) => item !== null) && (
                  <div className="filteredCount">
                    <p>Show Products({filteredProductsCount})</p>
                  </div>
                )}
              </div>
            </Drawer>

            <div className="products-listing">
              {/* ----FILTER BOX-------*/}
              <div className="filterBox">
                <div className="filters">
                  <FilterIcon />
                  <span className="filter-text">Filters</span>
                </div>
                <div className="f-line"></div>
                {/* ----PRODUCT CATEGORIES-------*/}
                <div className="medfield" style={{ paddingBottom: "0" }}>
                  <h2
                    style={{ marginTop: "15px" }}
                    className="medfield-heading"
                  >
                    Product Categories
                  </h2>
                  <form onSubmit={handleSubmit}>
                    <div className="search-field">
                      <SearchIcon />
                      <input
                        className="search-bar"
                        type="text"
                        placeholder="Search product"
                        value={searchProdCat}
                        onChange={(e) => {
                          let term = e.target.value;
                          setSearchProdCat(term);
                          const filtered = prodcats.filter((item) =>
                            item.name
                              .split(" ")
                              .some((word) =>
                                word
                                  .toLowerCase()
                                  .startsWith(term.toLowerCase())
                              )
                          );
                          setFilteredProdCat(filtered);
                        }}
                      />
                    </div>
                    <ul className="categoryBox">
                      {searchProdCat
                        ? filteredProdCat.map((prodcat) => (
                            <li
                              className="category-link"
                              key={prodcat._id}
                              onClick={() =>
                                handleProdCatCheckboxChange(prodcat.name)
                              }
                            >
                              <input
                                type="checkbox"
                                checked={selectedProdCats.includes(
                                  prodcat.name
                                )}
                                readOnly
                              />
                              {prodcat.name.includes("Technical")
                                ? prodcat.name.slice(0, 17)
                                : prodcat.name}
                            </li>
                          ))
                        : prodcats &&
                          prodcats.map((prodcat) => (
                            <li
                              className="category-link"
                              key={prodcat._id}
                              onClick={() =>
                                handleProdCatCheckboxChange(prodcat.name)
                              }
                            >
                              <input
                                type="checkbox"
                                checked={selectedProdCats.includes(
                                  prodcat.name
                                )}
                                readOnly
                              />
                              {prodcat.name.includes("Technical")
                                ? prodcat.name.slice(0, 17)
                                : prodcat.name}
                            </li>
                          ))}
                    </ul>
                  </form>
                </div>
                {/* ----BRANDS-------*/}
                <div className="medfield" style={{ paddingBottom: "0" }}>
                  <h2
                    className="medfield-heading"
                    style={{ marginTop: "15px" }}
                  >
                    Brands
                  </h2>
                  <form onSubmit={handleSubmit}>
                    <div className="search-field">
                      <SearchIcon />
                      <input
                        className="search-bar"
                        type="text"
                        placeholder="Search brand"
                        value={searchBrand}
                        onChange={(e) => {
                          let term = e.target.value;
                          setSearchBrand(term);
                          const filtered = brands.filter((item) =>
                            item.name
                              .split(" ")
                              .some((word) =>
                                word
                                  .toLowerCase()
                                  .startsWith(term.toLowerCase())
                              )
                          );
                          setFilteredBrand(filtered);
                        }}
                      />
                    </div>
                    <ul className="categoryBox">
                      {searchBrand
                        ? filteredBrand &&
                          filteredBrand.map((brand) => (
                            <li
                              className="category-link"
                              key={brand._id}
                              onClick={() =>
                                handleBrandCheckboxChange(brand.name)
                              }
                            >
                              <input
                                type="checkbox"
                                checked={selectedBrands.includes(brand.name)}
                                readOnly
                              />
                              {brand.name}
                            </li>
                          ))
                        : brands &&
                          brands.map((brand) => (
                            <li
                              className="category-link"
                              key={brand._id}
                              onClick={() =>
                                handleBrandCheckboxChange(brand.name)
                              }
                            >
                              <input
                                type="checkbox"
                                checked={selectedBrands.includes(brand.name)}
                                readOnly
                              />
                              {brand.name}
                            </li>
                          ))}
                    </ul>
                  </form>
                </div>
                {/* ----NEW/PRE-OWNED-------*/}
                <div className="device-status">
                  <div
                    className="f-line2"
                    style={{ marginBottom: "15px" }}
                  ></div>
                  <h2 className="medfield-heading">Condition</h2>
                  <ul className="categoryBox">
                    <li
                      className="category-link"
                      onClick={() => handleStatusCheckboxChange("New")}
                    >
                      <input
                        type="checkbox"
                        checked={selectedStatus.includes("New")}
                        readOnly
                      />
                      New
                    </li>
                    <li
                      className="category-link"
                      onClick={() => handleStatusCheckboxChange("Pre-Owned")}
                    >
                      <input
                        type="checkbox"
                        checked={selectedStatus.includes("Pre-Owned")}
                        readOnly
                      />
                      Pre-Owned
                    </li>
                  </ul>
                  <div className="f-line2" style={{ marginTop: "15px" }}></div>
                </div>
                {/* ----MEDICAL FIELDS-------*/}
                <div className="medfield">
                  <h2 className="medfield-heading">Medical Fields</h2>
                  <form onSubmit={handleSubmit}>
                    <div className="search-field">
                      <SearchIcon />
                      <input
                        className="search-bar"
                        type="text"
                        placeholder="Search fields"
                        value={searchTerm}
                        onChange={(e) => {
                          let term = e.target.value;
                          setSearchTerm(term);
                          const filtered = categories.filter((item) =>
                            item.name
                              .split(" ")
                              .some((word) =>
                                word
                                  .toLowerCase()
                                  .startsWith(term.toLowerCase())
                              )
                          );
                          setFilteredItems(filtered);
                        }}
                      />
                    </div>
                    <ul className="categoryBox">
                      {searchTerm
                        ? filteredItems.map((category) => (
                            <li
                              className="category-link"
                              key={category._id}
                              onClick={() =>
                                handleCategoryCheckboxChange(category.name)
                              }
                            >
                              <input
                                type="checkbox"
                                checked={selectedCategories.includes(
                                  category.name
                                )}
                                readOnly
                              />
                              {category.name}
                            </li>
                          ))
                        : categories &&
                          categories.map((category) => (
                            <li
                              className="category-link"
                              key={category._id}
                              onClick={() =>
                                handleCategoryCheckboxChange(category.name)
                              }
                            >
                              <input
                                type="checkbox"
                                checked={selectedCategories.includes(
                                  category.name
                                )}
                                readOnly
                              />
                              {category.name}
                            </li>
                          ))}
                    </ul>
                  </form>
                </div>
              </div>

              <div className="plp-container">
                {/* ----SEARCH BAR-------*/}
                <div className="plp-container-top">
                  <button className="filter-btn-mobile" onClick={toggleDiv}>
                    <FilterIcon style={{ marginTop: "4%" }} />
                    <span>Filters</span>
                  </button>
                  <div className="search-products-field">
                    <SearchIcon />
                    <input
                      className="search-products"
                      type="text"
                      value={searchProduct}
                      onChange={(e) => {
                        handleValueChange(e.target.value);
                      }}
                      onKeyUp={(e) => {
                        handleEnterValue(searchProduct, e);
                      }}
                    />
                    {!searchProduct && (
                      <div className="animated-placeholder">
                        <Typewriter
                          words={[
                            "Search Products",
                            "Enter the product name",
                            "For example: x-ray machine",
                          ]}
                          loop={0}
                          typeSpeed={90}
                          deleteSpeed={100}
                          delaySpeed={1000}
                          cursor
                        />
                      </div>
                    )}
                    {searchProduct
                      ? showProductList && (
                          <ul className="searchProduct-Results">
                            {filteredProducts.map((item) => (
                              <li
                                className="search-Results"
                                key={item.id}
                                onClick={() => {
                                  handleProductClick(item.name);
                                  setSearchProduct(item.name);
                                }}
                              >
                                {item.name}
                              </li>
                            ))}
                          </ul>
                        )
                      : ""}
                  </div>
                  <button
                    type="submit"
                    className={`view-cart ${
                      cartItems.length === 0
                        ? "disabled-view"
                        : showCart && cartItems.length !== 0
                        ? "cart-open"
                        : ""
                    }`}
                    disabled={cartItems.length === 0}
                    onClick={() => handleViewCart()}
                  >
                    View Cart
                  </button>
                </div>
                {/* ----TAGS-------*/}
                {!showTags.some((item) => item !== null) ? (
                  " "
                ) : (
                  <div className="tags">
                    <ul>
                      {showTags.length === 0 ||
                      (showTags.length === 1 && showTags[0] === null) ? (
                        ""
                      ) : (
                        <li className="clear-all" onClick={handleResetFilters}>
                          CLEAR ALL
                        </li>
                      )}
                      {showTags
                        .filter((tag) => tag !== null) // Filter out null values
                        .map((tag, index) => (
                          <li key={index}>
                            <span>{tag}</span>
                            <span>
                              <i
                                className="closeTag"
                                onClick={() => {
                                  removeTag(index, tag);
                                }}
                              >
                                <CloseIcon
                                  style={{
                                    color: "#868686",
                                    fontSize: 18,
                                    marginTop: "1px",
                                    fontWeight: 700,
                                  }}
                                />
                              </i>
                            </span>
                          </li>
                        ))}
                    </ul>
                  </div>
                )}

                {/* ----PRODUCTS-------*/}
                <div
                  className={`products-section ${
                    showCart && cartItems.length !== 0 ? "with-cart" : ""
                  }`}
                >
                  <div className="products">
                    {products &&
                      products.map((product) => (
                        <ProductCard
                          loading="lazy"
                          key={product._id}
                          product={product}
                          onAddToCart={handleShowCart}
                          setOpenPopup={setOpenPopup}
                          setSendId={setSendId}
                          showCart={showCart}
                          isAuthenticated={isAuthenticated}
                        />
                      ))}
                  </div>
                  {openPopup && (
                    <LoginPopup
                      openPopup={openPopup}
                      setOpenPopup={setOpenPopup}
                      onClose={onClose}
                      sendId={sendId}
                      onAddToCart={handleShowCart}
                    />
                  )}

                  <div className="cart-section">
                    {showCart && <CartPLP setShowCart={setShowCart} />}
                  </div>

                  {showCart && (
                    <div className="mobile-cart-section">
                      <div>
                        <p>
                          <span>{cartItems.length}</span>{" "}
                          {cartItems.length === 1 ? "Product" : "Products"}{" "}
                          added to cart
                        </p>
                        <button
                          onClick={() => {
                            history.push("/cart");
                          }}
                        >
                          {" "}
                          Show Cart
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default Products;
