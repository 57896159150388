import axios from "axios";

import {
  ALL_PRODUCT_FAIL,
  ALL_PRODUCT_REQUEST,
  ALL_PRODUCT_SUCCESS,
  ADMIN_PRODUCT_REQUEST,
  ADMIN_PRODUCT_SUCCESS,
  ADMIN_PRODUCT_FAIL,
  NEW_PRODUCT_REQUEST,
  NEW_PRODUCT_SUCCESS,
  NEW_PRODUCT_FAIL,
  UPDATE_PRODUCT_REQUEST,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAIL,
  DELETE_PRODUCT_REQUEST,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAIL,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_FAIL,
  PRODUCT_DETAILS_SUCCESS,
  ALL_PRODCAT_REQUEST,
  ALL_PRODCAT_SUCCESS,
  ALL_PRODCAT_FAIL,
  CLEAR_ERRORS,
} from "../constants/productConstants";



const createQueryString = (selectedCategories, selectedProdCats, selectedBrands,selectedStatus) => {
    const queryParams = [];
  
    if (Array.isArray(selectedCategories) && selectedCategories.length > 0) {

    //    // Check if the first element is null and remove it
    // if (selectedCategories[0] === null) {
    //   selectedCategories.shift();
    // }

      
    //   queryParams.push(`category=${selectedCategories.join(",")}`);
      // Check if the array contains only null values
    //   if (selectedCategories.every(item => item === null)) {
    //     return queryParams.join("&");
    // }

    // // Remove null values from the array
    // selectedCategories = selectedCategories.filter(item => item !== null);

    // queryParams.push(`category=${selectedCategories.join(",")}`);

    if (selectedCategories.every(item => item === null)) {
      // Do nothing and continue to the next block
  } else {
      // Remove null values from the array
      selectedCategories = selectedCategories.filter(item => item !== null);

      queryParams.push(`category=${selectedCategories.join(",")}`);
  }
    }
  
    if (Array.isArray(selectedProdCats) && selectedProdCats.length > 0) {
      // if(selectedProdCats[0]===null)
      // {
      //   selectedProdCats.shift();
      // }
  
      // queryParams.push(`prodcat=${selectedProdCats.join(",")}`);
      if (selectedProdCats.every(item => item === null)) {
        // Do nothing and continue to the next block
    } else {
        // Remove null values from the array
        selectedProdCats = selectedProdCats.filter(item => item !== null);
  
        queryParams.push(`prodcat=${selectedProdCats.join(",")}`);
    }
    }

    if (Array.isArray(selectedBrands) && selectedBrands.length > 0) {
      queryParams.push(`Brand=${selectedBrands.join(",")}`);
    }

    if (Array.isArray(selectedStatus) && selectedStatus.length > 0) {
      queryParams.push(`Device=${selectedStatus.join(",")}`);
    }
  
    return queryParams.join("&");
  }



  // Get All Products
export const getProduct =
(keyword = "", currentPage = 1, selectedCategories, selectedProdCats,selectedBrands,selectedStatus) =>
async (dispatch) => {
  try {

    if (currentPage === 1) {
      dispatch({ type: ALL_PRODUCT_REQUEST });
    }



    let link = `/api/v1/products?keyword=${keyword}&page=${currentPage}`;

    const queryParams =  createQueryString(selectedCategories, selectedProdCats, selectedBrands,selectedStatus);
    if (queryParams) {
      link += `&${queryParams}`;
    }

    const response = await fetch(link);
    const data = await response.json();
    
    dispatch({
      type: ALL_PRODUCT_SUCCESS,
      payload: data,
    });

  
  } catch (error) {
    dispatch({
      type: ALL_PRODUCT_FAIL,
      payload: error.message || 'An error occurred',
    });

    
  }
};




// Get All Product Categories
export const getAllProdCat = () => async (dispatch) => {
    try {
      dispatch({ type: ALL_PRODCAT_REQUEST });
  
      const response = await fetch("/api/v1/prodcats");
      const data = await response.json();
  
      dispatch({
        type: ALL_PRODCAT_SUCCESS,
        payload: data,
      });
    } catch (error) { 
      dispatch({
        type: ALL_PRODCAT_FAIL,
        payload: error.message || 'An error occurred',
      });
    }
  };

 




// Get All Products For Admin
export const getAdminProduct = () => async (dispatch) => {
  try {
    dispatch({ type: ADMIN_PRODUCT_REQUEST });

    const { data } = await axios.get("/api/v1/admin/products");

    dispatch({
      type: ADMIN_PRODUCT_SUCCESS,
      payload: data.products,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_PRODUCT_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Create Product
export const createProduct = (productData) => async (dispatch) => {
  try {
    dispatch({ type: NEW_PRODUCT_REQUEST });

    const config = {
      headers: { "Content-Type": "application/json" },
    };

    const { data } = await axios.post(
      `/api/v1/admin/product/new`,
      productData,
      config
    );

    dispatch({
      type: NEW_PRODUCT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_PRODUCT_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Update Product
export const updateProduct = (id, productData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PRODUCT_REQUEST });

    const config = {
      headers: { "Content-Type": "application/json" },
    };

    const { data } = await axios.put(
      `/api/v1/admin/product/${id}`,
      productData,
      config
    );

    dispatch({
      type: UPDATE_PRODUCT_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_PRODUCT_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Delete Product
export const deleteProduct = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_PRODUCT_REQUEST });

    const { data } = await axios.delete(`/api/v1/admin/product/${id}`);

    dispatch({
      type: DELETE_PRODUCT_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_PRODUCT_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Get Products Details
export const getProductDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_DETAILS_REQUEST });

    const { data } = await axios.get(`/api/v1/product/${id}`);

    dispatch({
      type: PRODUCT_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_DETAILS_FAIL,
      payload: error.response.data.message,
    });
  }
};


// Clearing Errors
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};