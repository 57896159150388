import React from "react";
import Loader from "../layout/Loader/Loader";

const Reroute3 = () => {
  // Replace 'https://example.com' with your desired link
  const externalLink =
    "https://script.google.com/a/macros/zoplar.com/s/AKfycbwSSURv98aMgZHMOqOUsNxivabJoN3B0_DwXfAoxJd3aMlJOmpho0WbM7312SOiToL_0w/exec";

  // Redirect to the external link
  window.location.replace(externalLink);

  return (
    <div>
      <Loader />
    </div>
  );
};

export default Reroute3;
