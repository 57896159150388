import React from "react";
import { useHistory } from "react-router-dom";

const CategoryCard = ({ category }) => {
  const history = useHistory();
  
  function handleCategoryClick(path) {
    history.push(path);
  }

  return (
    <div className="categoryCard">
      <div>
      <img className="category-icons" src={category.image.url} alt={category.name}/>
        <p className="category-description">{category.name}</p>
      </div>
      <button
        className="show-product-button"
        onClick={() =>
          handleCategoryClick(`/products?categoryFromPage=${category.name}`)
        }
      >
        Show Products
      </button>
    </div>
  );
};

export default CategoryCard;
