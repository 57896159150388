import React, { useRef, useState, useEffect } from "react";
import ReactPlayer from "react-player";
import "./Testimonials.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { ReactComponent as SwipeLeft } from "./../../icons/swipeleftIcon.svg";
import { ReactComponent as SwipeRight } from "./../../icons/swiperightIcon.svg";
import ReactCardCarousel from "react-card-carousel";
import { ReactComponent as Closing } from "./../../icons/close.svg";
import { ReactComponent as Opening } from "./../../icons/open.svg";
import abhishek from "./../../images/testimonial1.png";
import aseem from "./../../images/testimonial2.png";
import saurabh from "./../../images/testimonial3.png";
import yogesh from "./../../images/testimonial4.png";
import vishal from "./../../images/testimonial5.png";
import "@vidstack/react/player/styles/default/theme.css";
import "@vidstack/react/player/styles/default/layouts/video.css";

import {
  MediaPlayer,
  MediaProvider,
  useMediaState,
  useMediaStore,
  MediaPlayerInstance,
} from "@vidstack/react";
import {
  defaultLayoutIcons,
  DefaultVideoLayout,
} from "@vidstack/react/player/layouts/default";
import { Poster } from "@vidstack/react";
import { useSwipeable } from "react-swipeable";

const Testimonials = () => {
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const testimonialSliderRef = useRef(null);
  const cardCarouselRef = useRef(null);
  const [activeCardIndex, setActiveCardIndex] = useState(0);
  const currentVideoRef = useRef(null);
  const currentVideoIndexRef = useRef(null);
  const playerRefs = useRef([]);
  const [userInteracted, setUserInteracted] = useState(false);

  const handleCarouselClickForward = () => {
    if (buttonsDisabled) {
      return;
    }

    setButtonsDisabled(true);

    if (testimonialSliderRef.current) {
      testimonialSliderRef.current.slickNext();
    }

    if (cardCarouselRef.current) {
      cardCarouselRef.current.next();
    }

    setTimeout(
      () => {
        setButtonsDisabled(false);
      },
      { speedCarousel }
    );
  };

  const handleCarouselClickPrevious = () => {
    if (buttonsDisabled) {
      return;
    }

    setButtonsDisabled(true);

    if (testimonialSliderRef.current) {
      testimonialSliderRef.current.slickPrev();
    }

    if (cardCarouselRef.current) {
      cardCarouselRef.current.prev();
    }

    setTimeout(
      () => {
        setButtonsDisabled(false);
      },
      { speedCarousel }
    );
  };

  const SampleNextArrow = (props) => (
    <button {...props} className="testimonial-arrow-t-next-arrow">
      <SwipeRight className="swipe-icons" />
    </button>
  );

  const SamplePrevArrow = (props) => (
    <button {...props} className="testimonial-arrow-t-prev-arrow">
      <SwipeLeft className="swipe-icons" />
    </button>
  );

  var speedCarousel = 2000;
  var settings = {
    dots: true,
    infinite: true,
    speed: { speedCarousel },
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    arrows: false,
    fade: true,
    draggable: false,
    swipe: false,
    swipeToSlide: false,
    touchMove: false,
  };
  var settings2 = {
    dots: true,
    infinite: true,
    speed: { speedCarousel },
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    arrows: false,
    fade: true,
    draggable: true,
    swipe: true,
    swipeToSlide: true,
    touchMove: false,
  };

  const customers_data = [
    {
      id: 0,
      name: "Dr. Kunwara Vishal Singh",
      designation: "Clinical Director",
      title: "Aashlok Hospital",
      link: "https://zoplardatabase.s3.ap-southeast-2.amazonaws.com/pageimages/aashlok.mp4",
      quote:"The CT Scan machine provided by Zoplar is as good as new, they are providing excellent post sales maintenance which enables us to provide the best healthcare facility to our patients.",
      thumbnail:vishal,
    },

    {
      id: 1,
      name: "Mr. Abhishek Kumar",
      designation: "Founder & Owner",
      title: "Lal Ji Kidney Care Centre, Chandigarh.",
      link: "https://zoplardatabase.s3.ap-southeast-2.amazonaws.com/pageimages/%5BHappy+customer+stories!%5D+Lal+Ji+Kidney+Care+Center+_+See+what+they+have+to+say+about+Zoplar.mp4",
      quote:
        "I would recommend Zoplar to everyone for a quality service & excellent after sales services. ",
      thumbnail: abhishek,

      
    },

    {
      id: 2,
      name: "Dr. Saurabh Dixit",
      designation: "Laparoscopic Robotic Surgeon",
      title: "Highland Hospital",
      quote:
        "Zoplar has proven its testimony in taking care of every aspect of the sale as well as the post sales services.I'm recommending everyone amongst my connections to trust Zoplar!",
      thumbnail: saurabh,
      link: "https://zoplardatabase.s3.ap-southeast-2.amazonaws.com/pageimages/video.mp4",
    },

    {
      id: 3,
      name: "Mr. Aseem Garg",
      designation: "Founder",
      title: "DCDC Kidney Care",
      link: "https://zoplardatabase.s3.ap-southeast-2.amazonaws.com/pageimages/%5BHappy+customer+stories!%5D+DCDC+Kidney+Care+_+2nd+Largest+Dialysis+Center+in+India.mp4",
      quote:
        "What is heartening about working with Zoplar is they  have been able to supply products & consumables at far distant locations & well before time. ",
      thumbnail: aseem,
    },
    {
      id: 4,
      name: "Dr.Yogesh Sharma",
      designation: "MBBS , MD(Internal Medicine)",
      title: "Aayu Hospital",
      quote:
        "We recommend them to any new setup or an existing setup, who needs in procuring medical equipment or furnitures.",
      thumbnail: yogesh,
      link: "https://zoplardatabase.s3.ap-southeast-2.amazonaws.com/pageimages/aayu-reduced.mp4",
    },
  ];

  const carouselProps = {
    autoplay: false,
  };

  // const handleCarouselAfterChange = () => {
  //   const currentIndex = cardCarouselRef.current.getCurrentIndex();
  //   setActiveCardIndex(currentIndex);

  //   if (testimonialSliderRef.current) {
  //     testimonialSliderRef.current.slickNext();
  //   }
  //   playerRefs.current.forEach((player, index) => {
  //     if (index !== currentIndex) {
  //       player?.pause();
  //     }
  //   });

  //   if (userInteracted) {
  //     playerRefs.current[currentIndex]?.play();
  //   }
  // };

  const handleCarouselAfterChange = () => {
    const currentIndex = cardCarouselRef.current.getCurrentIndex();
    setActiveCardIndex(currentIndex);

    // Move both sliders to the same index
    if (testimonialSliderRef.current) {
      testimonialSliderRef.current.slickGoTo(currentIndex);
    }

    playerRefs.current.forEach((player, index) => {
      if (index !== currentIndex) {
        player?.pause();
      }
    });

    if (userInteracted) {
      playerRefs.current[currentIndex]?.play();
    }
  };

  const handleUserInteraction = () => {
    setUserInteracted(!userInteracted);
    userInteracted
      ? playerRefs.current[activeCardIndex]?.play().then(
          playerRefs.current.forEach((player, index) => {
            if (index !== activeCardIndex) {
              player?.pause();
            }
          })
        )
      : playerRefs.current[activeCardIndex]?.pause();
  };

  const swipeableContainerRef = useRef(null);

  const handlers = useSwipeable({

    onSwipedLeft: () => {
      if(window.innerWidth <768 ){
        handleCarouselClickForward();
        testimonialSliderRef.current.slickNext(); 
        }
      },
      
    onSwipedRight: () => {
      if(window.innerWidth < 768 ){
        handleCarouselClickPrevious();
      testimonialSliderRef.current.slickPrev();
      } 
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
    
  });
  

  return (
    <div className="videoplayercontainer" id="client_testimonial" style={{ position: "relative"  }} ref={swipeableContainerRef} {...handlers}>
      <p>We love to delight our clients!</p>

      <div >
        <div className="container">
          <SamplePrevArrow
            onClick={
              window.innerWidth > 768 ? handleCarouselClickPrevious : undefined
            }
            onTouchEnd={
              window.innerWidth <= 768 ? handleCarouselClickPrevious : undefined
            }
            disabled={buttonsDisabled}
          />

          <div className="testimonial-video-container" >
            <ReactCardCarousel
              className="cc"
              spread={"narrow"}
              ref={cardCarouselRef}
              props={carouselProps}
              style={{ position: "absolute" }}
              disable_fade_in={true}
              afterChange={handleCarouselAfterChange}
            >
              {customers_data.map((customer, index) => (
                <div
                  key={index}
                  className={`testimonial-video ${
                    index === activeCardIndex ? "" : "blur"
                  }`}
                  onClick={
                    index === activeCardIndex && window.innerWidth > 768
                      ? handleUserInteraction
                      : undefined
                  }
                  onTouchEnd={
                    index === activeCardIndex && window.innerWidth <= 768
                      ? handleUserInteraction
                      : undefined
                  }
                >
                  <MediaPlayer
                    title="Testimonials"
                    src={customer.link}
                    className="react-player"
                    ref={(ref) => {
                      playerRefs.current[index] = ref;
                    }}
                    playsInline="false"
                    style={{
                      borderRadius: "8px",
                      background: "black",
                      width: "fit-content",
                      height: "fit-content",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <MediaProvider
                      style={{
                        borderRadius: "8px",
                        width: "105%",
                        height: "100%",
                      }}
                    >
                      <Poster className="vds-poster" src={customer.thumbnail} />
                    </MediaProvider>

                    <DefaultVideoLayout icons={defaultLayoutIcons} />
                  </MediaPlayer>
                </div>
              ))}
            </ReactCardCarousel>
          </div>
          <Slider
            ref={testimonialSliderRef}
            {...settings}
            className="testimonialslider"
          >
            {customers_data.map((customer, index) => (
              // <div key={customer.id} className='testimonialsection'>
              <div key={index} className="testimoninaltext">
                <p className="tquote">
                  {" "}
                  <Opening className="opening-brace" />
                  {customer.quote} <Closing className="closing-brace" />{" "}
                </p>
                <p className="tname">{customer.name}</p>
                <p className="tdesignation">{customer.designation}</p>
                <p className="thospital">{customer.title}</p>
                {/* <Opening className="opening-brace" />
                <Closing className="closing-brace" /> */}
              </div>
              // </div>
            ))}
          </Slider>

          <SampleNextArrow
            onClick={
              window.innerWidth > 768 ? handleCarouselClickForward : undefined
            }
            onTouchEnd={
              window.innerWidth <= 768 ? handleCarouselClickForward : undefined
            }
            disabled={buttonsDisabled}
          />
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
