import {
    CREATE_CATEGORY_REQUEST,
    CREATE_CATEGORY_SUCCESS,
    CREATE_CATEGORY_FAIL,
    ALL_CATEGORY_REQUEST,
    ALL_CATEGORY_SUCCESS,
    ALL_CATEGORY_FAIL,
    CLEAR_ERRORS,
  } from "../constants/categoryConstants";

  import axios from "axios";

  // Create Category
export const createCategory = (category) => async (dispatch) => {
    try {
      dispatch({ type: CREATE_CATEGORY_REQUEST });
  
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post("/api/v1/admin/category", category, config);
  
      dispatch({ type: CREATE_CATEGORY_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: CREATE_CATEGORY_FAIL,
        payload: error.response.data.message,
      });
    }
  };

  // Get All categories 
export const getCategory = () => async (dispatch) => {
    try {
      dispatch({ type: ALL_CATEGORY_REQUEST });
  
      const { data } = await axios.get("/api/v1/categories");
  
      dispatch({
        type: ALL_CATEGORY_SUCCESS,
        payload: data.categories,
      });
    } catch (error) {
      dispatch({
        type: ALL_CATEGORY_FAIL,
        payload: error.response.data.message,
      });
    }
  };



  // Clearing Errors
export const clearErrors = () => async (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
  };