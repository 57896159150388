import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./CardSlider.css";
import { ReactComponent as LeftSwipe } from "./../../icons/leftSwipeIcon.svg";
import { ReactComponent as RightSwipe } from "./../../icons/rightSwipeIcon.svg";

//import m1 from './../../images/m1.png'
//import m1 from 'https://zoplardatabase.s3.ap-southeast-2.amazonaws.com/productsimages/ZID2FBHROHC0.jpeg'
import m2 from "./../../images/m2.png";
import m3 from "./../../images/m3.png";
import m4 from "./../../images/m4.png";
import m6 from "./../../images/m6.png";
import m7 from "./../../images/m7.png";

const machineData = [
  {
    id: 1,
    image:
      "https://zoplardatabase.s3.ap-southeast-2.amazonaws.com/pageimages/m1.png",
    modelNumber: "GME100",
    location: "High Frequency",
    name: "Portable X-Ray Machine",
    searchName:"X-Ray"
  },
  {
    id: 2,
    image: m2,
    modelNumber: "2017",
    location: "3.5KW",
    name: "C-Arm Machine",
    searchName:"C-arm"
  },

  {
    id: 3,
    image: m3,
    modelNumber: "HD7",
    location: "4 Probe",
    name: "Ultrasound Machine",
    searchName:"Ultrasound"
  },

  {
    id: 4,
    image: m4,
    modelNumber: "ADV",
    location: "1 Vapourizer",
    name: "Anaesthesia Workstation",
    searchName:"Anaesthesia"
  },

  {
    id: 5,
    image: m6,
    modelNumber: "RSMS-1000",
    location: "Tough Abs Body",
    name: "3 in 1 IPL Laser",
    searchName:"Laser"
  },

  {
    id: 6,
    image: m7,
    modelNumber: "HEM X-4000",
    location: "Motorised",
    name: "ICU Bed",
    searchName:"ICU"
  },
];

export default function CarouselComponent() {
  const history = useHistory();

  const [isFirstSlide, setIsFirstSlide] = useState(true);
  const [isLastSlide, setIsLastSlide] = useState(false);

  function handleCategoryClick(keyword) {
    console.log(keyword);
    history.push(`/products/${keyword}`);
  }
  const SampleNextArrow = (props) => (
    <button
      {...props}
      className="custom-arrow next-arrow"
      style={{ right: "-4.5%" }}
    >
      <RightSwipe />
    </button>
  );

  const SamplePrevArrow = (props) => (
    <button
      {...props}
      className="custom-arrow prev-arrow"
      style={{ left: "-4.5%" }}
    >
      <LeftSwipe />
    </button>
  );
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    beforeChange: (current, next) => {
      setIsFirstSlide(next === 0);
      setIsLastSlide(next === machineData.length - 4);
    },
    nextArrow: isLastSlide ? null : <SampleNextArrow />,
    prevArrow: isFirstSlide ? null : <SamplePrevArrow />,
  };
  const machines = machineData.map((item) => (
    <div className="home-card" key={item.id}>
      <img className="home-machine-image" src={item.image} alt={item.name} />
      <p className="home-machine-name"> {item.name}</p>
      <p className="home-machine-model">MODEL : {item.modelNumber}</p>
      <p className="home-machine-location">SPECS : {item.location}</p>
      <button onClick={() => handleCategoryClick(item.searchName)}>
        Request Price
      </button>
    </div>
  ));

  const mobilemachines = machineData.slice(0, 3).map((item) => (
    <div className="mobile-home-card" key={item.id}>
      <img
        className="mobile-home-machine-image"
        src={item.image}
        alt={item.name}
      />
      <p className="mobile-home-machine-name"> {item.name}</p>
      <p className="mobile-home-machine-model">MODEL: {item.modelNumber}</p>
      <p className="mobile-home-machine-location">SPECS:{item.location}</p>
      <button onClick={() => handleCategoryClick(item.searchName)}>
        Request Price
      </button>
    </div>
  ));

  return (
    <div className="home-best-selling-machines">
      <div className="home-selling-machines">
        <h3>Our Best Selling Machines</h3>
        <a className="home-selling-machines-view-all" href="/products">
          VIEW ALL
        </a>
      </div>
      <div className="home-card-carousel">
        <Slider {...settings} className="home-cards-carousel-container">
          {machines}
        </Slider>
      </div>
      <div className="mobile-cards-home">{mobilemachines}</div>
    </div>
  );
}
