import React, { useState } from "react";
import "./ImageCarousel.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useHistory } from "react-router-dom";
import { ReactComponent as IntCard1 } from "./../../icons/intcard1.svg";
import { ReactComponent as IntCard2 } from "./../../icons/intcard2.svg";
import { ReactComponent as IntCard3 } from "./../../icons/intcard3.svg";
import { ReactComponent as IntCard4 } from "./../../icons/intcard4.svg";
import { ReactComponent as CatLink } from "./../../icons/categoryLinkIcon.svg";
import { ReactComponent as SwipeLeft } from "./../../icons/swipeleftIcon.svg";
import { ReactComponent as SwipeRight } from "./../../icons/swiperightIcon.svg";

const ImageCarousel = () => {
  //brand images
  const image8 =
    "https://zoplardatabase.s3.ap-southeast-2.amazonaws.com/pageimages/Johnson.png";
  const image9 =
    "https://zoplardatabase.s3.ap-southeast-2.amazonaws.com/pageimages/GE.png";
  const image10 =
    "https://zoplardatabase.s3.ap-southeast-2.amazonaws.com/pageimages/Samsung.png";
  const image11 =
    "https://zoplardatabase.s3.ap-southeast-2.amazonaws.com/pageimages/Philips-01.png";
  const image12 =
    "https://zoplardatabase.s3.ap-southeast-2.amazonaws.com/pageimages/Seimens-01-1.png";
  const image13 =
    "https://zoplardatabase.s3.ap-southeast-2.amazonaws.com/pageimages/Meril-01.png";
  const image14 =
    "https://zoplardatabase.s3.ap-southeast-2.amazonaws.com/pageimages/Hero-01-1.png";

  const brandData = [
    {
      id: 1,
      imageurl: image8,
    },

    {
      id: 2,
      imageurl: image9,
    },
    {
      id: 3,
      imageurl: image10,
    },
    {
      id: 4,
      imageurl: image11,
    },
    {
      id: 5,
      imageurl: image12,
    },
    {
      id: 6,
      imageurl: image13,
    },
    {
      id: 7,
      imageurl: image14,
    },
  ];

  const images = [
    //main carousel images
    {
      id: "1",
      imageUrl:
        "https://zoplardatabase.s3.ap-southeast-2.amazonaws.com/pageimages/carousel-img2.png",
    },
    {
      id: "2",
      imageUrl:
        "https://zoplardatabase.s3.ap-southeast-2.amazonaws.com/pageimages/carousel-img.png",
    },
    {
      id: "3",
      imageUrl:
        "https://zoplardatabase.s3.ap-southeast-2.amazonaws.com/pageimages/carousel-img3.png",
      imageLink: "/sell",
    },
    {
      id: "4",
      imageUrl:
        "https://zoplardatabase.s3.ap-southeast-2.amazonaws.com/pageimages/carousel-img4.png",
      imageLink: "/service",
    },
  ];

  //image on right of carousel
  const image7 =
    "https://zoplardatabase.s3.ap-southeast-2.amazonaws.com/pageimages/left-img.png";

  const [isFirstSlide, setIsFirstSlide] = useState(true);
  const [isLastSlide, setIsLastSlide] = useState(false);

  const SampleNextArrow = (props) => (
    <button {...props} className="custom-arrow next-arrow">
      <SwipeRight className="swipe-icons" />
    </button>
  );

  const SamplePrevArrow = (props) => (
    <button {...props} className="custom-arrow prev-arrow">
      <SwipeLeft className="swipe-icons" />
    </button>
  );

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };
  const brandsettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 2,
    initialSlide: 0,
    beforeChange: (current, next) => {
      setIsFirstSlide(next === 0);
      setIsLastSlide(next === brandData.length - 5);
    },
    nextArrow: isLastSlide ? null : <SampleNextArrow />,
    prevArrow: isFirstSlide ? null : <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const history = useHistory();

  function handleCategoryClick(path) {
    history.push(path);
  }
  const InteractiveCard = ({
    bgColor,
    cardIcon,
    heading,
    content,
    bottombarColor,
  }) => (
    <div className="icard" style={{ backgroundColor: `${bgColor}` }}>
      <div className="card-icon">{cardIcon}</div>
      <h2 className="card-heading">{heading} </h2>
      <p className="card-paragraph">{content}</p>
      <div
        className="bottom-bar"
        style={{ backgroundColor: `${bottombarColor}` }}
      ></div>
    </div>
  );

  const MobileInteractiveCard = ({ bgColor, cardIcon, heading }) => (
    <div className="mobileicard" style={{ backgroundColor: `${bgColor}` }}>
      <div className="mobilecard-icon">{cardIcon}</div>
      <h2 className="mobilecard-heading">{heading} </h2>
    </div>
  );

  return (
    <div className="homepage">
      <div className="img-carousel-img">
        <div className="top-categories-container">
          <h3>Top Categories</h3>
          <div className="tc-line"></div>
          <ul>
            <li
              onClick={() => {
                handleCategoryClick(
                  `/products?ProductcategoryFromPage=Medical Devices`
                );
              }}
            >
              <span className="sidebar1"></span>Medical Devices
              <CatLink className="cat-link" />
            </li>
            <li
              onClick={() =>
                handleCategoryClick(
                  `/products?ProductcategoryFromPage=Hospital Furniture`
                )
              }
            >
              <span className="sidebar1"></span>Hospital Furnitures
              <CatLink className="cat-link" />
            </li>
            <li
              onClick={() =>
                handleCategoryClick(
                  `/products?ProductcategoryFromPage=Consumables (High-value)`
                )
              }
            >
              <span className="sidebar1"></span>Consumables
              <CatLink className="cat-link" />
            </li>
            <li
              onClick={() =>
                handleCategoryClick(`/products?ProductcategoryFromPage=Implant`)
              }
            >
              <span className="sidebar1"></span>Implants
              <CatLink className="cat-link" />
            </li>
            <li
              onClick={() =>
                handleCategoryClick(
                  `/products?ProductcategoryFromPage=Medical Devices (Pre-Owned)`
                )
              }
            >
              <span className="sidebar1"></span>Pre-Owned Devices
              <CatLink className="cat-link" />
            </li>
            <li
              onClick={() =>
                handleCategoryClick(`/products?ProductcategoryFromPage=OTC`)
              }
            >
              <span className="sidebar1"></span>OT Setup{" "}
              <CatLink className="cat-link" />
            </li>
          </ul>
        </div>

        <div className="phone-top-categories-container">
          <div className="phone-top-categories">
            <button
              onClick={() =>
                handleCategoryClick(
                  "/products?ProductcategoryFromPage=Medical Devices"
                )
              }
            >
              Medical
              <br /> Devices
            </button>
          </div>
          <div className="separator"></div>
          <div className="phone-top-categories">
            <button
              onClick={() =>
                handleCategoryClick(
                  `/products?ProductcategoryFromPage=Hospital Furniture`
                )
              }
            >
              Hospital <br /> Furniture{" "}
            </button>
          </div>
          <div className="separator"></div>
          <div className="phone-top-categories">
            <button
              onClick={() =>
                handleCategoryClick(
                  `/products?ProductcategoryFromPage=Consumables (High-value)`
                )
              }
            >
              Consumables
            </button>
          </div>
          <div className="separator"></div>
          <div className="phone-top-categories">
            <button
              onClick={() =>
                handleCategoryClick(`/products?ProductcategoryFromPage=Implant`)
              }
            >
              Implants{" "}
            </button>
          </div>
          <div className="separator"></div>
          <div className="phone-top-categories">
            <button
              onClick={() =>
                handleCategoryClick(
                  `/products?ProductcategoryFromPage=Medical Devices (Pre-Owned)`
                )
              }
            >
              {" "}
              Pre-owned <br />
              Devices{" "}
            </button>
          </div>
          <div className="separator"></div>
          <div className="phone-top-categories">
            <button
              onClick={() =>
                handleCategoryClick(`/products?ProductcategoryFromPage=OTC`)
              }
            >
              OT <br />
              Setup{" "}
            </button>
          </div>
        </div>

        <div className="first-carousel-container">
          <Slider {...settings} className="first-carousel-slider">
            {images.map((image, index) => (
              <div key={index} className="first-carousel-image">
                <img
                  className={image.imageLink ? "image-clickable" : ""}
                  src={image.imageUrl}
                  alt={`Slide ${index + 1}`}
                  onClick={() => {
                    if (image.imageLink) {
                      history.push(image.imageLink);
                    }
                  }}
                />
              </div>
            ))}
          </Slider>
        </div>

        <div className="image-container-right">
          <img className="right-img" src={image7} alt="rightImg" />
        </div>
      </div>

      {/*BrandCarousel code starts here*/}

      <div className="brand-slider-div">
        <Slider {...brandsettings} className="brand-slider">
          {brandData.map((item) => (
            <div key={item.id} className="brand-image-div">
              <img
                className="brand-image"
                src={item.imageurl}
                alt={`Brand ${item.id}`}
              />
            </div>
          ))}
        </Slider>
      </div>

      {/* why zoplar*/}
      <div className="whyzoplar-cards">
        <div className="whyzoplar">
          <h3>Our Promises</h3>
        </div>
        <div className="interactivecard">
          <InteractiveCard
            bgColor="#CFEEF6"
            cardIcon={<IntCard1 />}
            content="Quality Medical Supplies at Budget-Friendly Rates"
            heading={
              <div style={{ marginLeft: "-13px" }}>
                Best
                <br />
                Prices
              </div>
            }
            bottombarColor="#0081DF"
          />
          <InteractiveCard
            bgColor="#F8EFED"
            cardIcon={<IntCard2 />}
            content="Get reliable financing options"
            heading={
              <div>
                Financing
                <br />
                Support
              </div>
            }
            bottombarColor="#0081DF"
          />
          <InteractiveCard
            bgColor="#EEDEFD"
            cardIcon={<IntCard3 />}
            content="Experience worry-free ownership with our 1-year free service."
            heading={
              <div>
                Free 1 Year
                <br />
                Service
              </div>
            }
            bottombarColor="#0081DF"
          />
          <InteractiveCard
            bgColor="#E1F8E6"
            cardIcon={<IntCard4 />}
            content="24x7 Support. Engineer will visit within 24 hours"
            heading="Prompt breakdown resolution"
            bottombarColor="#0081DF"
          />
        </div>
      </div>

      <div className="mobileinteractivecard">
        <MobileInteractiveCard
          bgColor="white"
          cardIcon={<IntCard1 />}
          heading={
            <div>
              Best
              <br />
              Prices
            </div>
          }
          bottombarColor="#0081DF"
        />
        <MobileInteractiveCard
          bgColor="white"
          cardIcon={<IntCard2 />}
          heading={
            <div>
              Financing
              <br />
              Support
            </div>
          }
        />
        <MobileInteractiveCard
          bgColor="white"
          cardIcon={<IntCard3 />}
          heading={
            <div>
              Free 1 Year
              <br />
              Service
            </div>
          }
        />
        <MobileInteractiveCard
          bgColor="white"
          cardIcon={<IntCard4 />}
          heading={
            <div>
              Prompt breakdown
              <br />
              resolution
            </div>
          }
        />
      </div>
    </div>
  );
};

export default ImageCarousel;
