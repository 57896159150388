import React, { useState, useEffect, Fragment } from "react";
// import "./Infobar.css";
import "./Service.css";
import { ReactComponent as Bar1 } from "../../icons/bar1.svg";
import { ReactComponent as Bar2 } from "../../icons/bar2.svg";
import { ReactComponent as Bar5 } from "../../icons/bar5.svg";
import { ReactComponent as Bar6 } from "../../icons/bar6.svg";
import ServiceImg from "../../images/service.png";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createServiceForm, clearErrors } from "../../actions/formAction";
import { NEW_SERVICEFORM_RESET } from "../../constants/formsConstants";
import Loader from "../layout/Loader/Loader";
import MetaData from "../layout/MetaData";

const Service = () => {
  const [serviceFormData, setServiceFormData] = useState({
    fnameService: "",
    mobileNumberService: "",
    emailService: "",
    companyNameService: "",
    formCityService: "",
    serviceInfo: "",
    // Add more form fields as needed
  });
  const [isServiceFormValid, setIsServiceFormValid] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const pattern = /^[6-9][0-9]{0,20}$/;

  const { loading, error, success } = useSelector((state) => state.service);

  // Function to update form data
  const handleServiceInputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    if (name === "mobileNumberService" && value && !pattern.test(value)) {
      // If the input value does not match the pattern, do not update the state
      e.target.value = "";
      setPhoneError(true);
    } else if (name === "mobileNumberService" && value && pattern.test(value)) {
      // If the input value matches the pattern, update the state
      e.target.value = value;
      setPhoneError(false);
    }
    if (name === "mobileNumberService" && value.length >= 10) {
      const truncatedValueS = value.slice(0, 10);
      e.target.value = truncatedValueS;
      setServiceFormData((prevData) => ({
        ...prevData,
        [name]: truncatedValueS,
      }));
    } else {
      setServiceFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  // Validation function
  const validateServiceForm = () => {
    // const values = Object.values(serviceFormData);
    // return (
    //   values.every((value) => value.trim() !== "") &&
    //   serviceFormData.emailService.includes("@")
    // );
    const { fnameService, mobileNumberService, formCityService } =
      serviceFormData;
    const requiredFieldsService = [
      fnameService,
      mobileNumberService,
      formCityService,
    ];
    return (
      requiredFieldsService.every((field) => field.trim() !== "") &&
      mobileNumberService.length === 10 &&
      !phoneError &&
      pattern.test(serviceFormData.mobileNumberService)
    );
  };

  // Update form validity whenever the form data changes
  useEffect(() => {
    if (error) {
      dispatch(clearErrors());
    }

    if (success) {
      dispatch({ type: NEW_SERVICEFORM_RESET });
    }
    setIsServiceFormValid(validateServiceForm());
  }, [serviceFormData]);

  const handleServiceSubmit = (e) => {
    e.preventDefault();
    if (validateServiceForm) {
      const myForm = new FormData();

      myForm.set("name", serviceFormData.fnameService);
      myForm.set("phone", serviceFormData.mobileNumberService);
      myForm.set("email", serviceFormData.emailService);
      myForm.set("company", serviceFormData.companyNameService);
      myForm.set("city", serviceFormData.formCityService);
      myForm.set("info", serviceFormData.serviceInfo);

      dispatch(createServiceForm(myForm)).then(() => {
        history.push("/formSubmit");
      });
    } else {
      alert("Form not valid");
      //console.log("Form is not valid");
    }
  };

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <MetaData title="Quick & Quality Service - Zoplar" />
          <div className="service">
            <div className="service-img">
              <h3>One stop Servicing platform for Medical Equipment</h3>
              <p style={{marginBottom:'0.75%'}}>
                Facing issues with your precious medical Device? Searching for
                doctors for your medical devices? We aim to provide you with the
                servicing facilities. Our Certified In house Bio Medical
                Engineer will be there for inspection and will provide with the
                required service.
              </p>
              <p style={{fontWeight:'700' }}>For any query you can contact us at : <span>+91 95990 92521</span></p>
              <div>
                <img src={ServiceImg} alt="some img" />
              </div>
            </div>
            <div className="service-text">
              {/* <div className="infobar-service"> */}
              <div className="infobar">
                {/* <div className="infobar-section-service"> */}
                <div className="infobar-section">
                  <Bar1 className="bar-component" />
                  <p>
                    Fill the <br /> form below
                  </p>
                </div>
                <div className="infobar-section">
                  {/* <Bar2 className='bar-component' style={{ marginLeft: "10%" }} /> */}
                  <Bar2 className="bar-component" />
                  <p style={{ marginLeft: "0%" }}>
                    Our Team will <br />
                    contact you
                  </p>
                </div>
                <div className="infobar-section">
                  <Bar5 className="bar-component" />
                  {/* <p style={{ marginTop: "20px" }}> */}
                  <p>
                    In-Person
                    <br /> Inspection & Servicing
                  </p>
                </div>
                <div className="infobar-section" id="custom-bar-div">
                  {/* <Bar6 className='bar-component' style={{ marginBottom: "5px", marginTop: "5px" }} /> */}
                  <Bar6 className="bar-component" id="custom-bar" />
                  <p style={{ marginTop: "15px" }}>
                    Payment on <br /> completion
                  </p>
                </div>
              </div>
              <div className="service-form">
                <form onSubmit={handleServiceSubmit}>
                  <div className="basic-form-service">
                    <div className="input-container name-container">
                      <input
                        className="form-name"
                        type="text"
                        name="fnameService"
                        onChange={handleServiceInputChange}
                        style={{
                          width: "92%",
                          padding: "1.75% 2.9%",
                        }}
                        required
                      ></input>
                      <div className="placeholder">
                        Name<span>*</span>
                      </div>
                    </div>
                    <div className="form-grid">
                      <div className="input-container">
                        <input
                          className="mobile-number"
                          type="number"
                          name="mobileNumberService"
                          // placeholder="Enter your Phone number"
                          onChange={handleServiceInputChange}
                          // style={{ width: "90%" }}
                          required
                        ></input>
                        <div className="placeholder">
                          Phone Number<span>*</span>
                        </div>
                      </div>
                      {phoneError && (
                        <p className="phone-error" style={{ top: "28%" }}>
                          *Number should start from 6,7,8 or 9
                        </p>
                      )}
                      <div className="input-container">
                        <input
                          className="form-name"
                          type="text"
                          name="emailService"
                          placeholder="Email ID"
                          onChange={handleServiceInputChange}
                        ></input>
                        {/* <div className="placeholder">
                          Enter your Email ID
                        </div> */}
                      </div>

                      <div className="input-container">
                        {/* <p>City</p> */}
                        <input
                          className="form-name"
                          type="text"
                          name="formCityService"
                          // placeholder="Enter your City Name"
                          onChange={handleServiceInputChange}
                          required
                        ></input>
                        <div className="placeholder">
                          City Name<span>*</span>
                        </div>
                      </div>
                      <div className="input-container">
                        {/* <p>Company Name</p> */}
                        <input
                          className="form-name"
                          type="text"
                          name="companyNameService"
                          placeholder="Establishment Name"
                          onChange={handleServiceInputChange}
                        ></input>
                        {/* <div className="placeholder">
                          Enter your Company Name<span>*</span>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <textarea
                    className="service-info"
                    type="text"
                    name="serviceInfo"
                    placeholder="List your medical products along with their issues"
                    rows="5"
                    onChange={handleServiceInputChange}
                  ></textarea>
                  <button
                    type="submit"
                    className={
                      isServiceFormValid ? "active-service" : "disabled-service"
                    }
                    disabled={!isServiceFormValid}
                  >
                    Submit
                  </button>
                </form>
              </div>

              <div className="phone-service-form">
                <h4>Get your Medical Products serviced from us!</h4>
                <form onSubmit={handleServiceSubmit}>
                  <div className="basic-form-service-mobile">
                    <div>
                      <input
                        type="text"
                        className="form-name-service"
                        // placeholder="Enter your full Name"
                        name="fnameService"
                        onChange={handleServiceInputChange}
                        required
                      ></input>
                      <span className="mobile-placeholder-name">Name</span>
                    </div>

                    <div>
                      <input
                        className="mobile-number-service"
                        type="number"
                        name="mobileNumberService"
                        // placeholder="Enter your mobile Number"
                        onChange={handleServiceInputChange}
                        required
                      ></input>
                      <span className="mobile-placeholder-mobile">
                        Mobile Number
                      </span>
                    </div>
                    {phoneError && (
                      <p className="phone-error-mob">
                        *Number should start from 6,7,8 or 9
                      </p>
                    )}
                    <div>
                      <input
                        type="text"
                        className="form-city-service"
                        name="formCityService"
                        // placeholder="Enter Your City Name"
                        onChange={handleServiceInputChange}
                        required
                      ></input>
                      <span className="mobile-placeholder-city">City Name</span>
                    </div>
                    <div>
                      <input
                        className="form-email-service"
                        type="text"
                        name="emailService"
                        placeholder="Enter your Email ID"
                        onChange={handleServiceInputChange}
                      ></input>
                    </div>
                    <div>
                      <input
                        className="form-company-service"
                        type="text"
                        name="companyNameService"
                        placeholder="Establishment Name"
                        onChange={handleServiceInputChange}
                      ></input>
                    </div>
                    <textarea
                      className="LYP-info"
                      type="text"
                      name="serviceInfo"
                      placeholder="What Medical Product do you want to sell through us?"
                      rows="5"
                      onChange={handleServiceInputChange}
                    ></textarea>

                    <div>
                      <button
                        type="submit"
                        disabled={!isServiceFormValid}
                        className={
                          isServiceFormValid
                            ? "active-service-mobile"
                            : "disabled-service-mobile"
                        }
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default Service;
