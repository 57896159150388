import React from "react";
import "./SuccessForm.css";
import { Link } from 'react-router-dom';
import { ReactComponent as GreenTick } from "../../icons/greenTick.svg";

const SuccessForm = () => {

const EnquiryCardImg = "https://zoplardatabase.s3.ap-southeast-2.amazonaws.com/pageimages/EnquiryCardImg.png"

  return (
    <div className="wrapper-successCard">
    <div className="success-card">
      <img src={EnquiryCardImg} alt="success" />
      <h3>Congratulations! We have received your form</h3>
      <p>
        You will receive a call from our expert team &<br></br> we will provide your required information
      </p>
      {/* <p className="trackingID">Your tracking ID is: <span>{orderData}</span></p> */}
      {/* {orderID && (
          <p className="trackingID">
            Your tracking ID is: <span>{orderID}</span>
          </p>
        )} */}
      <Link to="/">
      <button className="track">Back to Homepage</button>
    </Link>
    </div>
    
    <div className="mobile-success-form">
      <GreenTick/>
      <p>Thank you for your response! Our team 
          will get back to you shortly.</p>


    </div>
    </div>
  );
};

export default SuccessForm;
