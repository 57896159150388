import React from 'react';
import Loader from "../layout/Loader/Loader";

const Reroute2 = () => {
    // Replace 'https://example.com' with your desired link
    const externalLink = 'https://script.google.com/a/macros/zoplar.com/s/AKfycbyOQKDVid2gmUeopLvLMSK0MKZ_ZuEN92osTIhdLsBQ7X16t6w0dJewDT5i3-oqK1VS/exec';
  
    // Redirect to the external link
    window.location.replace(externalLink);
  
    return (
      <div>
        <Loader/>
      </div>
    );
  };

export default Reroute2   ;