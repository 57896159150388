import produce from 'immer';

import {
    ADD_TO_CART,
    REMOVE_CART_ITEM,
    UPDATE_CART_ITEM_QUANTITY,
    UPDATE_CART_ITEM_BUNDLE_TYPE,
    EMPTY_CART,
  } from "../constants/cartConstants";
  
  export const cartReducer = (
    state = { cartItems: [] },
    action
  ) => {
    switch (action.type) {
      case ADD_TO_CART:
        return produce(state, (draftState) => {
            const newItem = action.payload;
            const existingItem = draftState.cartItems.find(
              (item) => item.product === newItem.product
            );
            
            if (existingItem) {
              // If the item already exists in the cart, update its quantity
              existingItem.quantity += newItem.quantity;
            } else {
              // If the item is not in the cart, add it
              draftState.cartItems.push(newItem);
            }
          });
    
        case UPDATE_CART_ITEM_QUANTITY:
          return produce(state, (draftState) => {
            const { productId, quantity } = action.payload;
    
            const existingItem = draftState.cartItems.find(
              (item) => item.product === productId
            );

            if (existingItem) {
              // Update the quantity of the specified item
              existingItem.quantity = quantity;
            }
          });

        case UPDATE_CART_ITEM_BUNDLE_TYPE:
          return produce(state, (draftState) => {
            const { productId, bundleType } = action.payload;
    
            const existingItem = draftState.cartItems.find(
              (item) => item.product === productId
            );
    
            if (existingItem) {
              // Update the bundle type of the specified item
              existingItem.bundleType = bundleType;
            }
          });  
  
      case REMOVE_CART_ITEM:
        return {
          ...state,
          cartItems: state.cartItems.filter((i) => i.product !== action.payload),
        };

      case EMPTY_CART:
        return {
           ...state,
           cartItems: [],
        };

    
  
     
      default:
        return state;
    }
  };