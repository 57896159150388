import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { newProductReducer, productDetailsReducer, productReducer, productsReducer,prodCatReducer} from "./reducers/productReducer";
import {
  allUsersReducer,
  profileReducer,
    userDetailsReducer,
    userReducer,
  } from "./reducers/userReducer";
  import { cartReducer } from "./reducers/cartReducer";
import { allOrdersReducer, myOrdersReducer, newOrderReducer, orderDetailsReducer, orderReducer } from "./reducers/orderReducer";
import { allCategoryReducer } from "./reducers/categoryReducer";
import {sellFormReducer,listFormReducer,financeFormReducer,serviceFormReducer,contactusFormReducer,referralReducer} from "./reducers/formsReducer";

const reducer = combineReducers({
    products:productsReducer,
    productDetails:productDetailsReducer,
    user: userReducer,
    profile: profileReducer,
    cart:cartReducer ,
    newOrder: newOrderReducer,
    myOrders: myOrdersReducer,
    orderDetails: orderDetailsReducer,
    newProduct: newProductReducer,
    product: productReducer,
    allOrders: allOrdersReducer,
    order: orderReducer,
    allUsers: allUsersReducer,
    userDetails: userDetailsReducer,
    allCategory:allCategoryReducer,
    allprodCats:prodCatReducer,
    sell:sellFormReducer,
    listProduct:listFormReducer,
    finance:financeFormReducer,
    service:serviceFormReducer,
    contact:contactusFormReducer,
    referral:referralReducer,
    
});

let initialState={
  cart: {
    cartItems: sessionStorage.getItem("cartItems")
      ? JSON.parse(sessionStorage.getItem("cartItems"))
      : []
  },
};

const middleware = [thunk];

const store = createStore(
    reducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
  );
  
  export default store;