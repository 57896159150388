import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import "./Pfooter.css";
import { ReactComponent as Home } from "../../../icons/Homef.svg";
import { ReactComponent as Categories } from "../../../icons/Categoriesf.svg";
import { ReactComponent as Search } from "../../../icons/Searchf.svg";
import { ReactComponent as Sell } from "../../../icons/Sellf.svg";
import { ReactComponent as Cart } from "../../../icons/Cartf.svg";

const Pfooter = () => {
  const { cartItems } = useSelector((state) => state.cart);
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  // const [activeIcon, setActiveIcon] = useState("");
  // const changeActiveIcon = (iconName) => {
  //   setActiveIcon(iconName);
  // };

  function handleNavItemClick(path) {
    history.push(path);
  }
  const showTrademarkRoutes = ["/", "/products", "/sell", "/contactus", "/aboutus"];

  return (
    <>
      {showTrademarkRoutes.includes(pathname) && (
        <div style={{ display: window.innerWidth > 768 ? 'none' : 'block', fontSize: '3vw', textAlign: 'center', paddingBottom: "50px" }}>
          <p>
            Zoplar is a registered trademark operated by Savdo Technologies Pvt. Ltd. (U51100HR2022PTC104168)
          </p>
          <br></br>
        </div>
      )}    <div className="bottom-bar">
      <div className="home-footer">
        <div
          name="home-outline"
          onClick={() => {
            // changeActiveIcon("home-outline");
            handleNavItemClick("/");
          }}
          className={`icon ${pathname === "/" ? "active-option" : ""}`}
        >
          <Home />
          <span className={pathname === "/" ? "show-label" : ""}>Home</span>
        </div>
      </div>
      <div className="category-footer">
        <div
          name="catergory-outline"
          onClick={() => {
            // changeActiveIcon("category-outline");
            handleNavItemClick("/categories");
          }}
          className={`icon ${
            pathname === "/categories" ? "active-option" : ""
          }`}
        >
          <Categories />
          <span
            style={{ left: "-1.3vw" }}
            className={pathname === "/categories" ? "show-label" : ""}
          >
            Categories
          </span>
        </div>
      </div>
      <div className="search-footer">
        <div
          name="search-outline"
          onClick={() => {
            // changeActiveIcon("search-outline");
            handleNavItemClick("/products");
          }}
          className={`icon ${pathname === "/products" ? "active-option" : ""}`}
        >
          <Search style={{ height: "20px", width: "20px" }} />
          <span
            style={{ left: "2vw" }}
            className={pathname === "/products" ? "show-label" : ""}
          >
            Search
          </span>
        </div>
      </div>
      <div className="sell-footer">
        <div
          name="sell-outline"
          onClick={() => {
            // changeActiveIcon("sell-outline");
            handleNavItemClick("/sell");
          }}
          className={`icon ${pathname === "/sell" ? "active-option" : ""}`}
        >
          <Sell />
          <span
            style={{ left: "0.4vw" }}
            className={pathname === "/sell" ? "show-label" : ""}
          >
            Sell
          </span>
        </div>
      </div>
      <div className="cart-footer">
        <div
          name="cart-outline"
          onClick={() => {
            // changeActiveIcon("cart-outline");
            handleNavItemClick("/cart");
          }}
          className={`icon ${pathname === "/cart" ? "active-option" : ""}`}
        >
          <Cart />
          {cartItems.length !== 0 ? (
            <div
              className={
                pathname === "/cart"
                  ? "cart-number-footer-1"
                  : "cart-number-footer"
              }
            >
              {cartItems.length}
            </div>
          ) : (
            ""
          )}
          <span
            style={{ left: "1.5vw" }}
            className={pathname === "/cart" ? "show-label" : ""}
          >
            Cart
          </span>
        </div>
      </div>

      {/* <div className="indicator"></div> */}
    </div>
    </>
  );
};

export default Pfooter;
