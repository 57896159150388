import React, { Fragment, useState, useEffect } from "react";
import ProfileBox from "./ProfileBox";
import { useSelector, useDispatch } from "react-redux";
import { clearErrors, myOrders, updateOrder } from "../../actions/orderAction";
import Loader from "../layout/Loader/Loader";
import { useAlert } from "react-alert";
import MetaData from "../layout/MetaData";
import "./TrackingDetails.css";
import Dialog from "@mui/material/Dialog";
import { DialogContent } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
//import ConfirmOrderImg from "../../images/ConfirmOrderImg.png";
import { UPDATE_ORDER_RESET } from "../../constants/orderConstants";
import { ReactComponent as DownloadIcon } from "../../icons/downloadIcon.svg";
import { ReactComponent as GreenTick } from "../../icons/greenTick.svg";

const TrackingDetails = () => {
  const dispatch = useDispatch();
  const alert = useAlert();

  const { loading, error, orders } = useSelector((state) => state.myOrders);
  const { error: updateError, isUpdated } = useSelector((state) => state.order);

  const [selectedTab, setSelectedTab] = useState("all");
  const [expandedItemIds, setExpandedItemIds] = useState([]);
  const [openConfirmPopup, setOpenConfirmPopup] = useState(false);

  const ConfirmOrderImg =
    "https://zoplardatabase.s3.ap-southeast-2.amazonaws.com/pageimages/EnquiryCardImg.png";

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (updateError) {
      alert.error(updateError);
      dispatch(clearErrors());
    }
    if (isUpdated) {
      //alert.success("Order Updated Successfully");
      if (selectedTab === "available" || selectedTab === "all") {
        setOpenConfirmPopup(true);
      }
      setSelectedTab("confirmed");

      dispatch({ type: UPDATE_ORDER_RESET });
    }

    dispatch(myOrders());
  }, [dispatch, alert, error, isUpdated, updateError]);

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
    setExpandedItemIds([]);
  };
  const filteredData =
    selectedTab === "all"
      ? orders
      : orders.filter((item) => item.orderStatus === selectedTab);

  const handleClickView = (itemId) => {
    setExpandedItemIds([...expandedItemIds, itemId]); // Expand the item
  };

  const handleClickViewLess = (itemId) => {
    setExpandedItemIds(expandedItemIds.filter((_id) => _id !== itemId));
  };
  const handleConfirmOrder = (itemID) => {
    const myForm = new FormData();

    myForm.set("status", "confirmed");
    // console.log(myForm.status)
    dispatch(updateOrder(itemID, myForm));
    // if (isUpdated) {
    //   // if (selectedTab === "available" || selectedTab == "all") {
    //   //   setOpenConfirmPopup(true);
    //   // }
    //   // sendConfData(itemID);
    //  // sendConfData(itemID);
    // }
  };

  

  const formatDateAndTime = (timestamp) => {
    const dateObject = new Date(timestamp);
    const formattedDate = `${dateObject.getFullYear()}-${
      dateObject.getMonth() + 1
    }-${dateObject.getDate()}`;
    const formattedTime = `${dateObject.getHours()}:${dateObject.getMinutes()}:${dateObject.getSeconds()}`;
    return { formattedDate, formattedTime };
  };

  // useEffect(() => {
  //   // Get the list item you want to hover by default and trigger hover effect
  //   const defaultHoveredItem = document.getElementById('default-hovered-item');
  //   if (defaultHoveredItem) {
  //     defaultHoveredItem.classList.add('hovered','profileBox-menu');
  //   }
  // }, []);
  // const classes = useStyles();
  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <MetaData title={"My Quotations - Zoplar"} />
          <div className="wrapper-qt">
            <div className="left-section">
              <ProfileBox />
            </div>
            <div className="tracking-details">
              <h4>Tracking Details</h4>
              <div className="f-line"></div>
              <div className="tab-headings">
                <p
                  className={selectedTab === "all" ? "selected" : ""}
                  onClick={() => handleTabClick("all")}
                >
                  All Queries
                  <span
                    className="tab-num"
                    style={{
                      color: orders.length > 0 ? "black" : "#a59f9f",
                    }}
                  >
                    {orders.length}
                  </span>
                </p>
                <p
                  className={selectedTab === "Pending" ? "selected" : ""}
                  onClick={() => handleTabClick("Pending")}
                  style={{
                    color: orders.some((item) => item.orderStatus === "Pending")
                      ? "#E87F7F"
                      : "black",
                  }}
                >
                  Pending Enquiries
                  <span
                    className="tab-num"
                    style={{
                      color: orders.some(
                        (item) => item.orderStatus === "Pending"
                      )
                        ? "#E87F7F"
                        : "#a59f9f",
                    }}
                  >
                    {
                      orders.filter((item) => item.orderStatus === "Pending")
                        .length
                    }
                  </span>
                </p>
                <p
                  className={selectedTab === "available" ? "selected" : ""}
                  onClick={() => handleTabClick("available")}
                  style={{
                    color: orders.some(
                      (item) => item.orderStatus === "available"
                    )
                      ? "#0081DF"
                      : "black",
                  }}
                >
                  Quotes Available
                  <span
                    className="tab-num"
                    style={{
                      color: orders.some(
                        (item) => item.orderStatus === "available"
                      )
                        ? "#0081DF"
                        : "#a59f9f",
                    }}
                  >
                    {
                      orders.filter((item) => item.orderStatus === "available")
                        .length
                    }
                  </span>
                </p>
                <p
                  className={selectedTab === "confirmed" ? "selected" : ""}
                  onClick={() => handleTabClick("confirmed")}
                  style={{
                    color: orders.some(
                      (item) => item.orderStatus === "confirmed"
                    )
                      ? "#259F07"
                      : "black",
                  }}
                >
                  Orders Confirmed
                  <span
                    className="tab-num"
                    style={{
                      color: orders.some(
                        (item) => item.orderStatus === "confirmed"
                      )
                        ? "#259F07"
                        : "#a59f9f",
                    }}
                  >
                    {
                      orders.filter((item) => item.orderStatus === "confirmed")
                        .length
                    }
                  </span>
                </p>
              </div>
              
            

            <div className="enquiry-status-cards">
                {selectedTab === "all" ? (
                  <h4>All Queries</h4>
                ) : selectedTab === "confirmed" ? (
                  <h4>Orders Confirmed</h4>
                ) : selectedTab === "Pending" ? (
                  <h4>Pending Enquiries</h4>
                ) : (
                  <h4>Quotes Available</h4>
                )}

                <div className="enquiry-cards-container">
                  {filteredData.map((item) => (
                    <div key={item._id}>
                      {/* Conditionally render the expanded card or card content */}
                      {expandedItemIds.includes(item._id) ? ( // Expanded card content
                        <div
                          className={`expanded-card ${
                            item.orderStatus === "Pending"
                              ? "expEnqCard-pending"
                              : item.orderStatus === "confirmed"
                              ? "expEnqCard-confirmed"
                              : "expEnqCard-available"
                          }`}
                        >
                          <div className="sidebar-tab"></div>
                          <div className="view-less">
                            <button
                              className="view-less-btn"
                              onClick={() => {
                                handleClickViewLess(item._id);
                              }}
                            >
                              <span className="view-text">Show Less</span>
                            </button>
                          </div>
                          <div className="enquiry-details">
                            <p>
                              Tracking ID:{" "}
                              <span className="enq-id">{item._id}</span>
                              <span className="enq-status">
                                {item.orderStatus}
                              </span>
                            </p>
                            <p>
                              Enquire Date & Time:{" "}
                              <span className="date">
                                {
                                  formatDateAndTime(item.createdAt)
                                    .formattedDate
                                }
                              </span>{" "}
                              <span style={{ marginLeft: "10px" }}>
                                <span className="time">
                                  {
                                    formatDateAndTime(item.createdAt)
                                      .formattedTime
                                  }
                                </span>
                              </span>
                            </p>
                          </div>
                          <div className="item-summary">
                            <p id="item-summ">Item Summary</p>
                          </div>
                          <div className="item-summ-table">
                          <div className="entry">
                                <p className="entry-name" style={{color:"black",fontWeight:"600"}}>Product</p>
                                <div className="entry-model">
                                  <p style={{color:"black",fontWeight:"600"}}>Brand</p>
                                  <p style={{color:"black",fontWeight:"600"}}>Size</p>
                                </div>
                                <p className="entry-quantity" style={{color:"black",fontWeight:"600"}}>
                                  Quantity
                                </p>
                                
                              </div>
                              
                            {item.orderItems.map((itemp, index) => (
                              <div className="entry" key={index}>
                                <p className="entry-name">{itemp.name}</p>
                                <div className="entry-model">
                                  <p>{itemp.Brand} </p>
                                  <p>{itemp.Size}</p>
                                </div>
                                <p className="entry-quantity">
                                  
                                  <span>
                                    {itemp.quantity} {itemp.bundleType}
                                  </span>
                                </p>
                              </div>
                            ))}
                          </div>

                          {item.orderStatus !== "Pending" && (
                            <div className="confirm-order">
                              <a
                                style={{ textDecoration: "none" }}
                                href={`${item.pdfLink}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <button
                                  className={`download-quot ${
                                    item.orderStatus === "confirmed"
                                      ? "download-quot-conf"
                                      : ""
                                  }`}
                                >
                                  Download Quotation
                                  <span>
                                    <DownloadIcon
                                      style={{ width: "17px", height: "14px" }}
                                    />
                                  </span>
                                </button>
                              </a>
                              {item.orderStatus === "available" ? (
                                <button
                                  className="confirm-order-btn"
                                  onClick={() => handleConfirmOrder(item._id)}
                                >
                                  Order Now!
                                </button>
                              ) : (
                                ""
                              )}
                            </div>
                          )}
                          <Dialog open={openConfirmPopup} maxWidth="sm">
                            <DialogContent sx={{padding:0}}>
                              <div className="confirm-dialog">
                              <button
                                className="close-button-confirm"
                                onClick={() => setOpenConfirmPopup(false)}
                              >
                                <CloseIcon style={{ color: "#FFFFFF" }} />
                              </button>
                              <div className="confirm-order-card">
                                <img src={ConfirmOrderImg} alt="some img" />
                                <h3>
                                  Congratulations your order has been confirmed!
                                </h3>
                                <p>
                                  In case of any query or clarification kindly
                                  <br /> contact our expert team.
                                </p>
                              </div>

                                  <div className="confirm-order-card-mobile">                       
                                    <GreenTick/>
                                    <p>Thank you for your response! Our team 
                                      will get back to you shortly.</p>
                                  </div>
                              </div>
                            </DialogContent>
                          </Dialog>
                        </div>
                      ) : (
                        <>
                          <div
                            className={`tab-card ${
                              item.orderStatus === "Pending"
                                ? "enqCard-pending"
                                : item.orderStatus === "confirmed"
                                ? "enqCard-confirmed"
                                : "enqCard-available"
                            }`}
                          >
                            <div className="sidebar-tab"></div>
                            <div className="enquiry-details">
                              <p>
                                Tracking ID:{" "}
                                <span className="enq-id">{item._id}</span>
                                <span className="enq-status">
                                  {item.orderStatus}
                                </span>
                              </p>
                              <p>
                                {/* Enquire Date & Time:{" "} */}
                                {/* <span className="date">{item.createdAt}</span>{" "} */}
                                {/* <span className="time">{item.time}</span> */}
                                {/* <span className="date">{formatDateAndTime(item.createdAt).formattedDate}</span>{" "} */}
                                Enquire Date & Time:{" "}
                                <span className="date">
                                  {
                                    formatDateAndTime(item.createdAt)
                                      .formattedDate
                                  }
                                </span>{" "}
                                <span style={{ marginLeft: "2%" }}>
                                  <span className="time">
                                    {
                                      formatDateAndTime(item.createdAt)
                                        .formattedTime
                                    }
                                  </span>
                                </span>
                              </p>

                              <p className="enquired-products-mobile">
                              {/* Products: X-ray, USG, Stapler, Hemorrhoids, Mesh */}
                              {/* {item.orderItems.map((value,index) => (<div key={index}>{value.name}</div>))} */}
                              {item.orderItems.length > 3 ? (
                                <>
                                  {item.orderItems
                                    .slice(0, 3)
                                    .map((value, index) => (
                                      <div key={index}>{value.name}</div>
                                    ))}
                                  <div>...</div>
                                </>
                              ) : (
                                item.orderItems.map((value, index) => (
                                  <div key={index}>{value.name}</div>
                                ))
                              )}
                            </p>
                            </div>
                             
                            

                            <p className="enquired-products">
                              {/* Products: X-ray, USG, Stapler, Hemorrhoids, Mesh */}
                              {/* {item.orderItems.map((value,index) => (<div key={index}>{value.name}</div>))} */}
                              {item.orderItems.length > 3 ? (
                                <>
                                  {item.orderItems
                                    .slice(0, 3)
                                    .map((value, index) => (
                                      <div key={index}>{value.name}</div>
                                    ))}
                                  <div>...</div>
                                </>
                              ) : (
                                item.orderItems.map((value, index) => (
                                  <div key={index}>{value.name}</div>
                                ))
                              )}
                            </p>
                            
                            <div className="view-details">
                              <button
                                className="view-details-btn"
                                onClick={() => {
                                  handleClickView(item._id);
                                }}
                              >
                                View Details
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  ))}
                </div>
                </div>
              </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default TrackingDetails;