export const ADD_TO_CART = "ADD_TO_CART";

export const REMOVE_CART_ITEM = "REMOVE_CART_ITEM";


export const UPDATE_CART_ITEM_QUANTITY = "UPDATE_CART_ITEM_QUANTITY";

export const UPDATE_CART_ITEM_BUNDLE_TYPE = 'UPDATE_CART_ITEM_BUNDLE_TYPE';

export const EMPTY_CART = "EMPTY_CART";

