import React from 'react';
import Loader from "../layout/Loader/Loader";

const Reroute = () => {
    // Replace 'https://example.com' with your desired link
    const externalLink = 'https://script.google.com/a/macros/zoplar.com/s/AKfycbwuQ719kUrHWYEw79pQSkrXz3gKltJrbOX-q0GEJo9Yg1_R4wFXrXjUuHf-_LrZSiTK1Q/exec';
  
    // Redirect to the external link
    window.location.replace(externalLink);
  
    return (
      <div>
        <Loader/>
      </div>
    );
  };

export default Reroute   ;