import React from "react";
import RefurbImg from "../../../images/refurb.png";
import { useHistory } from "react-router-dom";
import "./Refurb.css";
const Refurb = () => {
  const history = useHistory();
  const handleRefClick = () => {
    history.push("/sell");
  };

  // const RefurbImg = "https://zoplardatabase.s3.ap-southeast-2.amazonaws.com/pageimages/refurb.png"
  return (
    <div className="refurb-container">
      <div className="alltext">
        <div className="refurb-heading">
          Selling your pre owned USG Machine? <br></br> We’re buying!
          
        </div>
        <div className="refurb-text">
          <p>
            Get up to ₹10 lakh when you sell your<br></br> preowned machine to us.
          </p>
          <br></br>
        </div>
        <button className="refurb-button" onClick={() => handleRefClick()}>
          Know more
        </button>
      </div>
      <div className="machineImg">
        <img src={RefurbImg} alt="RefurbAd" />
      </div>
    </div>
  );
};

export default Refurb;
