import React, { useState,useEffect } from "react";

const DynamicImage = ({ imgs = [{ src: "" }] }) => {

  const [mainImage, setMainImage] = useState(imgs[0]);

  useEffect(() => {
    setMainImage(imgs[0]);
  }, [imgs]);

  return (
    <>
      <div className="image-section">
        {imgs.image && imgs.image.length > 1 && (
          <div className="image-column">
            {imgs.image.map((item, index) => {
              return (
                <img
                  src={item}
                  alt={`${index} Slide`}
                  className="single-img"
                  key={index}
                  onClick={() => setMainImage(item)}
                />
              );
            })}
          </div>
        )}
        {/* 2nd column  */}
        <div className="main-image-container">
          {mainImage && (
            <img
              src={mainImage}
              alt={mainImage.alt}
              className="main-image"
            />
          )}
        </div>
      </div>
    </>
  );
};

export default DynamicImage;
