import {
    NEW_SELLFORM_REQUEST,
    NEW_SELLFORM_SUCCESS,
    NEW_SELLFORM_FAIL,
    NEW_LISTFORM_REQUEST,
    NEW_LISTFORM_SUCCESS,
    NEW_LISTFORM_FAIL,
    NEW_FINANCEFORM_REQUEST,
    NEW_FINANCEFORM_SUCCESS,
    NEW_FINANCEFORM_FAIL,
    NEW_SERVICEFORM_REQUEST,
    NEW_SERVICEFORM_SUCCESS,
    NEW_SERVICEFORM_FAIL,
    NEW_CONTACTUS_REQUEST,
    NEW_CONTACTUS_SUCCESS,
    NEW_CONTACTUS_FAIL,
    NEW_REFERRAL_REQUEST,
    NEW_REFERRAL_SUCCESS,
    NEW_REFERRAL_FAIL,
    CLEAR_ERRORS,
  } from "../constants/formsConstants";

  import axios from "axios";

  // Create Product
export const createSellForm = (sellData) => async (dispatch) => {
    try {
      dispatch({ type: NEW_SELLFORM_REQUEST });
  
      const config = {
        headers: { "Content-Type": "multipart/form-data" },
      };
  
      const { data } = await axios.post(
        `/api/v1/sell`,
        sellData,
        config
      );
  
      dispatch({
        type: NEW_SELLFORM_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: NEW_SELLFORM_FAIL,
        payload: error.response.data.message,
      });
    }
  };

  // Create Product
export const createListForm = (listData) => async (dispatch) => {
    try {
      dispatch({ type: NEW_LISTFORM_REQUEST });
  
      const config = {
        headers: { "Content-Type": "multipart/form-data" },
      };
  
      const { data } = await axios.post(
        `/api/v1/listProducts`,
        listData,
        config
      );
  
      dispatch({
        type: NEW_LISTFORM_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: NEW_LISTFORM_FAIL,
        payload: error.response.data.message,
      });
    }
  };

  // Create Product
export const createFinanceForm = (financeData) => async (dispatch) => {
    try {
      dispatch({ type: NEW_FINANCEFORM_REQUEST });
  
      const config = {
        headers: { "Content-Type": "multipart/form-data" },
      };
  
      const { data } = await axios.post(
        `/api/v1/finance`,
        financeData,
        config
      );
  
      dispatch({
        type: NEW_FINANCEFORM_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: NEW_FINANCEFORM_FAIL,
        payload: error.response.data.message,
      });
    }
  };

  // Create Product
export const createServiceForm = (serviceData) => async (dispatch) => {
    try {
      dispatch({ type: NEW_SERVICEFORM_REQUEST });
  
      const config = {
        headers: { "Content-Type": "multipart/form-data" },
      };
  
      const { data } = await axios.post(
        `/api/v1/service`,
        serviceData,
        config
      );
  
      dispatch({
        type: NEW_SERVICEFORM_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: NEW_SERVICEFORM_FAIL,
        payload: error.response.data.message,
      });
    }
  };


   // Create Product
export const createContactUsForm = (contactusData) => async (dispatch) => {
  try {
    dispatch({ type: NEW_CONTACTUS_REQUEST });

    const config = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    const { data } = await axios.post(
      `/api/v1/ContactUs`,
      contactusData,
      config
    );

    dispatch({
      type: NEW_CONTACTUS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_CONTACTUS_FAIL,
      payload: error.response.data.message,
    });
  }
};


export const createReferral = (referralData) => async (dispatch) => {
  try {
    dispatch({ type: NEW_REFERRAL_REQUEST });

    const config = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    const { data } = await axios.post(
      `/api/v1/referral`,
      referralData,
      config
    );

    dispatch({
      type: NEW_REFERRAL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_REFERRAL_FAIL,
      payload: error.response.data.message,
    });
  }
};

    // Clearing Errors
export const clearErrors = () => async (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
  };