import React from "react";
import "./Loader.css";

const Slideshow = () => {
  const Loader1 =
    "https://zoplardatabase.s3.ap-southeast-2.amazonaws.com/pageimages/loader1.png";
  const Loader2 =
    "https://zoplardatabase.s3.ap-southeast-2.amazonaws.com/pageimages/loader2.png";
  const Loader3 =
    "https://zoplardatabase.s3.ap-southeast-2.amazonaws.com/pageimages/loader3.png";
  // const Loader4 =
  //   "https://zoplardatabase.s3.ap-southeast-2.amazonaws.com/pageimages/loader4.png";

  return (
    <div className="wrapper-loader">
      <div className="loader-container">
        <div className="loader-slide">
          <div>
            <div className="loader-image-container-1">
              <img src={Loader1} alt="loader" className="loader-image" />
            </div>
            <p style={{ marginRight: "20px" }}>Hold Tight!</p>
          </div>

          <div className="ldr2">
            <div className="loader-image-container-2">
              <img src={Loader2} alt="loader" className="loader-image" />
            </div>
            <p>Redirecting you to your page!</p>
          </div>

          <div className="loader-image-container-3">
            <div>
              <img src={Loader3} alt="loader" className="loader-image" />
            </div>
            <p>Almost there!</p>
          </div>

          {/* <div className="ldr4">
            <div className="loader-image-container-4">
              <img src={Loader4} className="loader-image" />
            </div>
            <p>Redirecting you to your page!</p>
          </div> */}
          
        </div>
      </div>
    </div>
  );
};

export default Slideshow;
