import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { logout } from "../../../actions/userAction";
import "./Header.css";
import LoginPopup from "../../User/LoginPopup";
import { emptyCart } from "../../../actions/cartAction";
import { ReactComponent as CartIcon } from "../../../icons/cartIcon.svg";
import { ReactComponent as ProfileIcon } from "../../../icons/profileIconWhite.svg";
import { ReactComponent as ProfileBubble } from "../../../icons/chatBubble.svg";
import { ReactComponent as CatLink } from "../../../icons/categoryLinkIcon.svg";

const Header = () => {
  const { isAuthenticated,user } = useSelector((state) => state.user);
  const { cartItems } = useSelector((state) => state.cart);
  

  // const [openPopup, setOpenPopup] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const alert = useAlert();
  const dispatch = useDispatch();
  const { pathname } = location;

  const Logo = "https://zoplardatabase.s3.ap-southeast-2.amazonaws.com/pageimages/logo.png";

  const [openPopup, setOpenPopup] = useState(false);
  const [openProfileBubble, setOpenProfileBubble] = useState(false);
  const onClose = () => {
    setOpenPopup(false);
  };

  function handleNavItemClick(path) {
    history.push(path);
  }

  function handleProfileClick() {
    if (isAuthenticated) {
      // history.push("/orders"); 
      setOpenProfileBubble(!openProfileBubble);
    } else {
      setOpenPopup(true);
    }
  }
  function logoutUser() {
    dispatch(logout());
    dispatch(emptyCart());
    alert.success("Logout Successfully");
  }
  const isProductPage = pathname.includes("/product");
  return (
    <div className="main-nav">
      <div className="Logo">
        <a href="/">
          <img src={Logo} alt="Zoplar" className="logoImage" />
        </a>
      </div>
      <div className="menu-link">
        <ul>
          <li
            onClick={() => handleNavItemClick("/products")}
            className={
              pathname === "/products" || isProductPage ? "active" : ""
            }
          >
            BUY
          </li>
          <li
            onClick={() => handleNavItemClick("/sell")}
            className={pathname === "/sell" ? "active" : ""}
          >
            SELL
          </li>
          <li
            onClick={() => handleNavItemClick("/finance")}
            className={pathname === "/finance" ? "active" : ""}
          >
            FINANCE
          </li>
          <li
            onClick={() => handleNavItemClick("/service")}
            className={pathname === "/service" ? "active" : ""}
          >
            SERVICES
          </li>
          <li
            onClick={() => handleNavItemClick("/listProducts")}
            className={pathname === "/listProducts" ? "active" : ""}
          >
            LIST YOUR PRODUCTS
          </li>
          <li
            onClick={() => handleNavItemClick("/blogs")}
            className={pathname === "/blogs" ? "active" : ""}
          >
            BLOGS
          </li>
          <li
            onClick={() => handleNavItemClick("/AboutUs")}
            className={pathname === "/AboutUs" ? "active" : ""}
          >
            ABOUT US
          </li>
        </ul>
      </div>
      <div className="cart-profile">
        <ul>
          <li
            onClick={() => handleNavItemClick("/cart")}
            className={`nav-item ${
              pathname === "/Cart" || pathname === "/cart" ? "active" : ""
            }`}
          >
            <div className="cart-icon">
              <CartIcon className="custom-icon" />
              {cartItems.length !== 0 ? (
                <div className="cart-number">{cartItems.length}</div>
              ) : (
                ""
              )}
            </div>
          </li>
        </ul>
        <button
          className={`login-button ${isAuthenticated ? "name-visible" : ""}`}
          onClick={() => handleProfileClick()}
        >
          <ProfileIcon />
          {isAuthenticated
            ? user.name.length > 8
              ? user.name.slice(0, 8) + "..."
              : user.name
            : "Login"}
          {openProfileBubble && (
            <div className="profile-bubble">
              <ProfileBubble className="bubble" />
              <div>
                <p className="pb-name">
                  {user.name.length > 16
                    ? user.name.slice(0, 15) + ".."
                    : user.name}
                </p>
                <p className="pb-num">{user.phoneNumber}</p>
                <div className="pb-buttons">
                  <button
                    className="pb-track"
                    onClick={() => {
                      history.push("/orders");
                    }}
                  >
                    Track Quotations{" "}
                    <CatLink
                      style={{
                        width: "6px",
                        height: "8px",
                        marginRight: "8px",
                      }}
                    />
                  </button>
                  <button className="pb-logout" onClick={logoutUser}>
                    Logout
                  </button>
                </div>
              </div>
            </div>
          )}
        </button>
      </div>
      {openPopup && (
        <LoginPopup
          openPopup={openPopup} // Custom prop
          setOpenPopup={setOpenPopup} // Custom prop
          onClose={onClose} // Pass the onClose function
        />
      )}
    </div>
  );
};

export default Header;
