import React, { useState, useEffect, Fragment } from "react";
import "./Infobar.css";
import "./Finance.css";
import { useHistory } from "react-router-dom";
import FinanceImg from "../../images/Finance.jpg";
import { useDispatch, useSelector } from "react-redux";
import { createFinanceForm, clearErrors } from "../../actions/formAction";
import { NEW_FINANCEFORM_RESET } from "../../constants/formsConstants";
import { ReactComponent as DropdownIcon } from "../../icons/dropdownMenuIcon.svg";
import Loader from "../layout/Loader/Loader";
import MetaData from "../layout/MetaData";

const Finance = () => {
  const [financeFormData, setFinanceFormData] = useState({
    fnameFinance: "",
    mobileNumberFinance: "",
    emailFinance: "",
    companyNameFinance: "",
    formCityFinance: "",
    Type: "",
    productRequirement: "",
    FinReq: "",
    financeInfo: "",
    profileType: "",
    // Add more form fields as needed
  });
  const [openTypeDropDown, setOpenTypeDropDown] = useState(false);
  const [openFRDropDown, setOpenFRDropDown] = useState(false);
  const [openPTDropDown, setOpenPTDropDown] = useState(false);
  const pattern = /^[6-9][0-9]{0,20}$/;

  const [selectedOptionType, setSelectedOptionType] =
    useState("Incorporation Type");
  const [selectedOptionFR, setSelectedOptionFR] =
    useState("Credit Requirement");

  const [selectedOptionPT, setSelectedOptionPT] = useState("Profile Type");

  const [isFinanceFormValid, setIsFinanceFormValid] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const { loading, error, success } = useSelector((state) => state.finance);

  // Function to update form data
  const handleFinanceInputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    if (name === "mobileNumberFinance" && value && !pattern.test(value)) {
      // If the input value does not match the pattern, do not update the state
      e.target.value = "";
      setPhoneError(true);
    } else if (name === "mobileNumberFinance" && value && pattern.test(value)) {
      // If the input value matches the pattern, update the state
      e.target.value = value;
      setPhoneError(false);
    }

    if (name === "mobileNumberFinance" && value.length >= 10) {
      const truncatedValueF = value.slice(0, 10);
      e.target.value = truncatedValueF;
      setFinanceFormData((prevData) => ({
        ...prevData,
        [name]: truncatedValueF,
      }));
    } else {
      setFinanceFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  const selectType = (valueType) => {
    setSelectedOptionType(valueType);
    setOpenTypeDropDown(false);
    setFinanceFormData((prevData) => ({
      ...prevData,
      Type: valueType,
    }));
  };
  const selectFR = (value) => {
    setSelectedOptionFR(value);
    setOpenFRDropDown(false);
    setFinanceFormData((prevData) => ({
      ...prevData,
      FinReq: value,
    }));
  };

  const selectPT = (value) => {
    setSelectedOptionPT(value);
    setOpenPTDropDown(false);
    setFinanceFormData((prevData) => ({
      ...prevData,
      profileType: value,
    }));
  };
  // Validation function
  const validateFinanceForm = () => {
    const { fnameFinance, mobileNumberFinance, formCityFinance } =
      financeFormData;
    const requiredFieldsFinance = [
      fnameFinance,
      mobileNumberFinance,
      formCityFinance,
    ];

    return (
      requiredFieldsFinance.every((field) => field.trim() !== "") &&
      mobileNumberFinance.length === 10 &&
      !phoneError &&
      pattern.test(financeFormData.mobileNumberFinance)
    );
  };

  // Update form validity whenever the form data changes
  useEffect(() => {
    if (error) {
      dispatch(clearErrors());
    }

    if (success) {
      dispatch({ type: NEW_FINANCEFORM_RESET });
    }

    setIsFinanceFormValid(validateFinanceForm());
  }, [financeFormData]);

  const handleFinanceSubmit = (e) => {
    e.preventDefault();
    if (validateFinanceForm) {
      const myForm = new FormData();

      myForm.set("name", financeFormData.fnameFinance);
      myForm.set("phone", financeFormData.mobileNumberFinance);
      myForm.set("email", financeFormData.emailFinance);
      myForm.set("company", financeFormData.companyNameFinance);
      myForm.set("city", financeFormData.formCityFinance);
      myForm.set("info", financeFormData.financeInfo);
      myForm.set("type", financeFormData.Type);
      myForm.set("productRequirement", financeFormData.productRequirement);
      myForm.set("financingRequirement", financeFormData.FinReq);
      myForm.set("profileType", financeFormData.profileType);
      // Perform form submission logic
      // alert("Form submitted");
      // console.log("Form submitted:", financeFormData);
      dispatch(createFinanceForm(myForm)).then(() => {
        history.push("/formSubmit");
      });
    } else {
      // console.log("Form is not valid");
      alert("Form not valid");
    }
  };

  console.log(financeFormData);

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <MetaData title="Great Financing Options - Zoplar" />
          <div className="Finance">
            <div className="Finance-img">
              <div>
                <img src={FinanceImg} alt="some img" />
              </div>
              <div className="finance-cards-container">
                <div className="finance-card">
                  <p>
                    Minimal <br />
                    Documentation
                  </p>
                  <div className="finance-card-bottom"></div>
                </div>
                <div className="finance-card">
                  <p>Loan Amount Range INR (50k - 50Cr)</p>
                  <div className="finance-card-bottom"></div>
                </div>
                <div className="finance-card">
                  <p id="web-finance-card">
                    Tenure Range from 6 months to 36 months
                  </p>
                  <p id="mobile-finance-card">
                    Tenure Range from 6 months to 36 months
                  </p>
                  <div className="finance-card-bottom"></div>
                </div>
                <div className="finance-card">
                  <p>
                    Instant Funds <br />
                    to support <br />
                    Medical Needs
                  </p>
                  <div className="finance-card-bottom"></div>
                </div>
              </div>
            </div>
            <div className="Finance-text">
              <h3>Get the best financing options!</h3>
              <p>
                Seeking financial assistance? We've got your back!
                <br /> Explore our range of flexible financing options,
                including hassle-free <br className="fin-br" />
                No-Cost EMI, convenient Term Loans, and versatile Line of
                Credit solutions.
              </p>
              <div className="Finance-form">
                <form onSubmit={handleFinanceSubmit}>
                  <div className="basic-form-Finance">
                    <div className="input-container name-container">
                      <input
                        className="form-name"
                        type="text"
                        name="fnameFinance"
                        onChange={handleFinanceInputChange}
                        style={{
                          width: "92%",
                          padding: "1.75% 2.9%",
                        }}
                        required
                      ></input>
                      <div className="placeholder">
                        Name<span>*</span>
                      </div>
                    </div>
                    <div className="form-grid">
                      <div className="input-container">
                        <input
                          className="mobile-number"
                          type="number"
                          name="mobileNumberFinance"
                          onChange={handleFinanceInputChange}
                          required
                        ></input>
                        <div className="placeholder">
                          Phone Number<span>*</span>
                        </div>
                      </div>
                      {phoneError && (
                        <p className="phone-error" style={{ top: "21.7%" }}>
                          *Number should start from 6,7,8 or 9
                        </p>
                      )}
                      <div className="input-container">
                        <input
                          className="form-name"
                          type="text"
                          name="emailFinance"
                          placeholder="Email ID"
                          onChange={handleFinanceInputChange}
                        ></input>
                        {/* <div className="placeholder">
                          Enter your Email ID<span>*</span>
                        </div> */}
                      </div>

                      <div className="input-container">
                        <input
                          className="form-name"
                          type="text"
                          name="formCityFinance"
                          // placeholder="Enter your City Name"
                          onChange={handleFinanceInputChange}
                          required
                        ></input>
                        <div className="placeholder">
                          City Name<span>*</span>
                        </div>
                      </div>
                      <div className="input-container">
                        <input
                          className="form-name"
                          type="text"
                          name="companyNameFinance"
                          placeholder="Establishment Name"
                          onChange={handleFinanceInputChange}
                        ></input>
                        {/* <div className="placeholder">
                          Enter your Company Name<span>*</span>
                        </div> */}
                      </div>
                      <div
                        className="dropdown-type"
                        onClick={() => {
                          setOpenTypeDropDown(!openTypeDropDown);
                        }}
                      >
                        <div>
                          <p
                            className={
                              selectedOptionType === "Incorporation Type"
                                ? "selected-type"
                                : ""
                            }
                          >
                            {selectedOptionType}
                          </p>
                          {openTypeDropDown && (
                            <ul>
                              <li
                                onClick={() => {
                                  selectType("Individual");
                                }}
                              >
                                Individual
                              </li>
                              <li
                                onClick={() => {
                                  selectType("Proprietor");
                                }}
                              >
                                Proprietor
                              </li>
                              <li
                                onClick={() => {
                                  selectType("Partnership");
                                }}
                              >
                                Partnership
                              </li>
                              <li
                                onClick={() => {
                                  selectType("Pvt Ltd");
                                }}
                              >
                                Pvt Ltd
                              </li>
                            </ul>
                          )}
                          <DropdownIcon
                            className="dropdown-icon"
                            onClick={() =>
                              setOpenTypeDropDown(!openTypeDropDown)
                            }
                          />
                        </div>
                      </div>
                      <div className="input-container">
                        <input
                          className="form-name"
                          type="text"
                          name="productRequirement"
                          placeholder="Product Requirement"
                          onChange={handleFinanceInputChange}
                        ></input>
                      </div>
                      <div
                        className="dropdown-type"
                        onClick={() => {
                          setOpenFRDropDown(!openFRDropDown);
                        }}
                      >
                        <div>
                          <p
                            className={
                              selectedOptionFR === "Credit Requirement"
                                ? "selected-type"
                                : ""
                            }
                          >
                            {selectedOptionFR}
                          </p>
                          {openFRDropDown && (
                            <ul>
                              <li
                                onClick={() => {
                                  selectFR("EMI");
                                }}
                              >
                                EMI
                              </li>
                              <li
                                onClick={() => {
                                  selectFR("Credit Line");
                                }}
                              >
                                Credit Line
                              </li>
                            </ul>
                          )}
                          <DropdownIcon
                            className="dropdown-icon"
                            onClick={() => setOpenFRDropDown(!openFRDropDown)}
                          />
                        </div>
                      </div>

                      <div
                        className="dropdown-type"
                        onClick={() => {
                          setOpenPTDropDown(!openPTDropDown);
                        }}
                      >
                        <div>
                          <p
                            className={
                              selectedOptionPT === "Profile Type"
                                ? "selected-type"
                                : ""
                            }
                          >
                            {selectedOptionPT}
                          </p>
                          {openPTDropDown && (
                            <ul>
                              <li
                                onClick={() => {
                                  selectPT("MBBS");
                                }}
                              >
                                MBBS
                              </li>
                              <li
                                onClick={() => {
                                  selectPT("Non - MBBS");
                                }}
                              >
                                Non-MBBS
                              </li>
                              <li
                                onClick={() => {
                                  selectPT("Non - Medical");
                                }}
                              >
                                Non-Medical
                              </li>
                            </ul>
                          )}
                          <DropdownIcon
                            className="dropdown-icon"
                            onClick={() => setOpenPTDropDown(!openPTDropDown)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <textarea
                    className="finance-info"
                    type="text"
                    name="financeInfo"
                    placeholder="What kind of Financing Options do you want?"
                    rows="5"
                    onChange={handleFinanceInputChange}
                  ></textarea>
                  <button
                    type="submit"
                    className={
                      isFinanceFormValid ? "active-finance" : "disabled-finance"
                    }
                    disabled={!isFinanceFormValid}
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>

            <div className="phone-Finance-form">
              <p className="phone-Finance-form-heading">
                Know more about the financing options
              </p>
              <form onSubmit={handleFinanceSubmit}>
                <div className="basic-form-Finance-mobile">
                  <div>
                    <input
                      className="form-name-finance"
                      type="text"
                      name="fnameFinance"
                      // placeholder="Enter Your Name"
                      onChange={handleFinanceInputChange}
                      required
                    ></input>
                    <span className="mobile-placeholder-name-finance">
                      Name
                    </span>
                  </div>

                  <div>
                    <input
                      className="mobile-number-finance"
                      type="number"
                      name="mobileNumberFinance"
                      // placeholder="Enter Your Mobile Number"
                      onChange={handleFinanceInputChange}
                      required
                    ></input>
                    <span className="mobile-placeholder-mobile-finance">
                      Mobile Number
                    </span>
                  </div>
                  {phoneError && (
                    <p className="phone-error-mob" style={{ marginLeft: "0%" }}>
                      *Number should start from 6,7,8 or 9
                    </p>
                  )}
                  <div>
                    <input
                      className="form-city-finance"
                      type="text"
                      name="formCityFinance"
                      // placeholder="City"
                      onChange={handleFinanceInputChange}
                      required
                    ></input>
                    <span className="mobile-placeholder-city-finance">
                      City Name
                    </span>
                  </div>

                  <div>
                    <input
                      className="form-company-finance"
                      type="text"
                      name="companyNameFinance"
                      placeholder="Establishment Name"
                      onChange={handleFinanceInputChange}
                    ></input>
                    {/* <span className="mobile-placeholder-company-finance">
                      Enter your Company Name
                    </span> */}
                  </div>

                  <div>
                    <input
                      className="form-email-finance"
                      type="text"
                      name="emailFinance"
                      placeholder="Email ID"
                      onChange={handleFinanceInputChange}
                    ></input>
                  </div>
                  <div className="fin-dropdowns">
                    <div
                      style={{
                        width: "50%",
                      }}
                      className="dropdown-type"
                      onClick={() => {
                        setOpenFRDropDown(!openFRDropDown);
                      }}
                    >
                      <div style={{ height: "6vw" }}>
                        <p
                          className={
                            selectedOptionFR === "Credit Requirement"
                              ? "selected-type"
                              : ""
                          }
                        >
                          {selectedOptionFR}
                        </p>
                        {openFRDropDown && (
                          <ul>
                            <li
                              onClick={() => {
                                selectFR("EMI");
                              }}
                            >
                              EMI
                            </li>
                            <li
                              onClick={() => {
                                selectFR("Credit Line");
                              }}
                            >
                              Credit Line
                            </li>
                          </ul>
                        )}
                        <DropdownIcon
                          className="dropdown-icon"
                          onClick={() => setOpenFRDropDown(!openFRDropDown)}
                        />
                      </div>
                    </div>

                    <div
                      style={{
                        width: "50%",
                      }}
                      className="dropdown-type"
                      onClick={() => {
                        setOpenTypeDropDown(!openTypeDropDown);
                      }}
                    >
                      <div style={{ height: "6vw" }}>
                        <p
                          className={
                            selectedOptionType === "Incorporation Type"
                              ? "selected-type"
                              : ""
                          }
                        >
                          {selectedOptionType}
                        </p>
                        {openTypeDropDown && (
                          <ul>
                            <li
                              onClick={() => {
                                selectType("Individual");
                              }}
                            >
                              Individual
                            </li>
                            <li
                              onClick={() => {
                                selectType("Proprietor");
                              }}
                            >
                              Proprietor
                            </li>
                            <li
                              onClick={() => {
                                selectType("Partnership");
                              }}
                            >
                              Partnership
                            </li>
                            <li
                              onClick={() => {
                                selectType("Pvt Ltd");
                              }}
                            >
                              Pvt Ltd
                            </li>
                          </ul>
                        )}
                        <DropdownIcon
                          className="dropdown-icon"
                          onClick={() => setOpenTypeDropDown(!openTypeDropDown)}
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      width: "100%",
                    }}
                    className="dropdown-type"
                    onClick={() => {
                      setOpenPTDropDown(!openPTDropDown);
                    }}
                  >
                    <div style={{ height: "12px", marginTop: "18px" }}>
                      <p
                        className={
                          selectedOptionPT === "Profile Type"
                            ? "selected-typePT"
                            : ""
                        }
                      >
                        {selectedOptionPT}
                      </p>
                      {openPTDropDown && (
                        <ul>
                          <li
                            onClick={() => {
                              selectPT("MBBS");
                            }}
                          >
                            MBBS
                          </li>
                          <li
                            onClick={() => {
                              selectPT("Non - MBBS");
                            }}
                          >
                            Non-MBBS
                          </li>
                          <li
                            onClick={() => {
                              selectPT("Non - Medical");
                            }}
                          >
                            Non-Medical
                          </li>
                        </ul>
                      )}
                      <DropdownIcon
                        className="dropdown-iconPT"
                        onClick={() => setOpenPTDropDown(!openPTDropDown)}
                      />
                    </div>
                  </div>

                  <div>
                    <input
                      className="form-product-finance"
                      type="text"
                      name="productRequirement"
                      placeholder="Product Requirement"
                      onChange={handleFinanceInputChange}
                    ></input>
                  </div>

                  <textarea
                    className="finance-info-mobile"
                    type="text"
                    name="financeInfo"
                    placeholder="What kind of Financing Options do you want?"
                    rows="5"
                    onChange={handleFinanceInputChange}
                  ></textarea>
                  <button
                    type="submit"
                    className={
                      isFinanceFormValid
                        ? "active-finance-mobile"
                        : "disabled-finance-mobile"
                    }
                    disabled={!isFinanceFormValid}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default Finance;
