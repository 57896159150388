import React from 'react'
import "./ReferralSubmit.css"
import { Link } from 'react-router-dom';
import rsubmit from '../../images/referralsubmit.png'
import rsubmitmobile from '../../images/greentick.png'

const ReferralSubmit =() =>{

    return(
        <div className='rfSubmit'>
            <img className='rsubmitpic' src={rsubmit}></img>
            <img className='rsubmitpicmobile' src={rsubmitmobile}></img>
            <h3>You earn INR 5,000 as soon as your friend <br></br>orders from us!</h3>
            <p>Your referral code has been sent you & your friend on WhatsApp.</p>
            <div>
                <h5>Earn up to INR 1,00,000 per month</h5>
                <Link to ='/referral'>
                    <button>Refer more Friends</button>
                </Link>
                
            </div>


        </div>

    );
};

export default ReferralSubmit;