import React ,{useRef} from "react" ;
import "./Privacy.css"

const Privacy =() =>{

    const currentDate = new Date().toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric'
      });
      


      const terms = useRef(null);
      const returnPolicy = useRef(null);
      const cancellationPolicy=useRef(null);
      const opting=useRef(null);
      const security=useRef(null);
      const contact=useRef(null);
      const updates=useRef(null);
      const record=useRef(null);
      // Add refs for other sections as needed
    
      const scrollToSection = (ref) => {
        if (ref && ref.current) {
          const rect = ref.current.getBoundingClientRect();
          const offset = rect.top + window.scrollY; // Calculate the absolute position

          const adjustedOffset = offset - 80
    
          window.scrollTo({
            top: adjustedOffset,
            behavior: "smooth",
          });
        }
      };

    return(
        <div className="privacypage">
            <div className="privacybanner">
                <h1>Terms & Conditions</h1>
            </div>
            <div className="privacyterms">
                <div ref={terms}>
                    <h5> 1. Terms</h5>  
                    <p> <ul>
                            <li>The order will be placed under the official name of Savdo Technologies Private Limited.</li>
                            <li>The validity of the quotation is contingent upon the availability of stocks at the time of confirmation.</li>
                            <li>The delivery of products may take 1-60 days post order confirmation depending on the size of the order and the availability of the requested products.</li>
                            <li>Please note that the prices quoted on the website (if any) do not include additional charges such as Insurance, Packaging, and Forwarding (P&F) Charges. These charges may vary depending on the specific product and shipping address. </li>
                            <li>Any applicable taxes, duties, delivery charges, levies, transportation fees, or similar charges will be considered extra and will be the sole responsibility of the purchaser.  </li>
                            <li>Post-warranty service for medical equipment or machinery traded under this invoice will be provided at the sole discretion of Savdo Technologies Private Limited or its authorised affiliates and will incur separate costs.</li>
                            <li>In the event of any disputes arising from this quotation, all matters will be resolved through sole arbitration conducted by Savdo Technologies Private Limited.</li>
                            <li>Please be aware that the terms and conditions outlined in this document are subject to the jurisdiction and laws of Gurugram, ensuring adherence to the legal framework governing the region. </li>
                            <li>We hereby confirm that we have obtained and maintained all the necessary licence(s) and registration(s) as required by the applicable laws, which are currently valid and in force as of the present date.</li>
                        </ul> 
                        
                    </p> 
                </div>
                 
                <div ref={returnPolicy}>
                    <h5>2. Return Policy</h5>
                    <p>Once the goods are sold, they cannot be returned unless otherwise stated or specified explicitly in the invoice. Any exceptions made will be on a case-to-case basis at the sole discretion of Savdo Technologies Private Limited. In case of exceptions,the amount will be refunded to the customer.</p>
                </div>
                
                <div ref={cancellationPolicy}>
                    <h5>3. Cancellation Policy</h5>
                    <p>Orders once confirmed can not be cancelled. Any exceptions made will be on a case-to-case basis at the sole discretion of Savdo Technologies Private Limited.</p>
                </div>
                 
                {/* <div ref={record }>
                    <h5>Record Keeping and Quality Assurance:</h5>
                    <p>All calls and messages initiated by Zoplar are recorded for internal quality assurance and training purposes. This information is strictly used within the organization and is not shared with external parties.</p>
                </div>
                
                <div ref={updates }>
                    <h5>Updates to the Privacy Policy:</h5>
                    <p>Zoplar reserves the right to update this Privacy Policy as necessary. Users will be notified of any material changes.</p>             
                </div> */}
                
                <div ref={contact}>
                    <h5>Contact Information:</h5>
                    <p>For any questions, concerns, or requests related to privacy, please contact our Privacy Officer at <a className="privacysupport" href="mailto:support@zoplar.com">support@zoplar.com</a>.</p>
                    <p>By using the Zoplar website, you acknowledge that you have read and understood this Privacy Policy and agree to its terms.</p>
                    <h7>Effective Date:<span className="privacydate">{currentDate}</span> </h7>
                </div>
                

            </div>
            
            <div className="privacypolicy">
                <h5>Terms & Conditions</h5>
                <h6>Effective Date : <span> {currentDate}</span></h6>
                <div className="privacybottomline"></div>
                <div className="privacytable">
                    <h5>Table of Contents</h5>
                    <p><span onClick={() => scrollToSection(terms)}>1. Terms</span></p>
                    <p><span onClick={() => scrollToSection(returnPolicy)}>2. Return Policy</span></p>
                    <p> <span onClick={() => scrollToSection(cancellationPolicy)} >3. Cancellation Policy</span></p>
                    {/* <p><span onClick={() => scrollToSection(record)} >Record Keeping and Quality Assurance:</span></p>
                    <p><span onClick={() => scrollToSection(updates)} >Updates to the Privacy Policy:</span></p> */}
                    <p><span onClick={() => scrollToSection(contact)} >Contact Information:</span></p>

                </div>
            </div>

        </div>

    );
};

export default Privacy

